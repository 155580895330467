/**
 * A list of all the locations in Portugal, with `zone`, `district` and
 * `county` information. Used in the location dropdowns to make sure that
 * users only enter valid combinations
 */
const LOCATION_DATA: LocationItem[] = [
  {
    id: 1401,
    zone: 'Centro',
    district: 'Santarém',
    county: 'Abrantes',
  },
  {
    id: 101,
    zone: 'Centro',
    district: 'Aveiro',
    county: 'Águeda',
  },
  {
    id: 901,
    zone: 'Centro',
    district: 'Guarda',
    county: 'Aguiar da Beira',
  },
  {
    id: 701,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Alandroal',
  },
  {
    id: 102,
    zone: 'Centro',
    district: 'Aveiro',
    county: 'Albergaria-A-Velha',
  },
  {
    id: 801,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Albufeira',
  },
  {
    id: 1501,
    zone: 'Alentejo',
    district: 'Setúbal',
    county: 'Alcácer do Sal',
  },
  {
    id: 1402,
    zone: 'Centro',
    district: 'Santarém',
    county: 'Alcanena',
  },
  {
    id: 1001,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Alcobaça',
  },
  {
    id: 1502,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Setúbal',
    county: 'Alcochete',
  },
  {
    id: 802,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Alcoutim',
  },
  {
    id: 1101,
    zone: 'Centro',
    district: 'Lisboa',
    county: 'Alenquer',
  },
  {
    id: 1601,
    zone: 'Norte',
    district: 'Viana do Castelo',
    county: 'Arcos de Valdevez',
  },
  {
    id: 1602,
    zone: 'Norte',
    district: 'Viana do Castelo',
    county: 'Caminha',
  },
  {
    id: 803,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Aljezur',
  },
  {
    id: 201,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Aljustrel',
  },
  {
    id: 1503,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Setúbal',
    county: 'Almada',
  },
  {
    id: 902,
    zone: 'Centro',
    district: 'Guarda',
    county: 'Almeida',
  },
  {
    id: 1403,
    zone: 'Alentejo',
    district: 'Santarém',
    county: 'Almeirim',
  },
  {
    id: 202,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Almodôvar',
  },
  {
    id: 1404,
    zone: 'Alentejo',
    district: 'Santarém',
    county: 'Alpiarça',
  },
  {
    id: 1201,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Alter do Chão',
  },
  {
    id: 1002,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Alvaiázere',
  },
  {
    id: 203,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Alvito',
  },
  {
    id: 1115,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Lisboa',
    county: 'Amadora',
  },
  {
    id: 1603,
    zone: 'Norte',
    district: 'Viana do Castelo',
    county: 'Melgaço',
  },
  {
    id: 1604,
    zone: 'Norte',
    district: 'Viana do Castelo',
    county: 'Monção',
  },
  {
    id: 103,
    zone: 'Centro',
    district: 'Aveiro',
    county: 'Anadia',
  },
  {
    id: 1003,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Ansião',
  },
  {
    id: 1605,
    zone: 'Norte',
    district: 'Viana do Castelo',
    county: 'Paredes de Coura',
  },
  {
    id: 601,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Arganil',
  },
  {
    id: 1606,
    zone: 'Norte',
    district: 'Viana do Castelo',
    county: 'Ponte da Barca',
  },
  {
    id: 1607,
    zone: 'Norte',
    district: 'Viana do Castelo',
    county: 'Ponte de Lima',
  },
  {
    id: 702,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Arraiolos',
  },
  {
    id: 1202,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Arronches',
  },
  {
    id: 1102,
    zone: 'Centro',
    district: 'Lisboa',
    county: 'Arruda dos Vinhos',
  },
  {
    id: 105,
    zone: 'Centro',
    district: 'Aveiro',
    county: 'Aveiro',
  },
  {
    id: 1203,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Avis',
  },
  {
    id: 1103,
    zone: 'Alentejo',
    district: 'Lisboa',
    county: 'Azambuja',
  },
  {
    id: 1608,
    zone: 'Norte',
    district: 'Viana do Castelo',
    county: 'Valença',
  },
  {
    id: 1609,
    zone: 'Norte',
    district: 'Viana do Castelo',
    county: 'Viana do Castelo',
  },
  {
    id: 204,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Barrancos',
  },
  {
    id: 1504,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Setúbal',
    county: 'Barreiro',
  },
  {
    id: 1004,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Batalha',
  },
  {
    id: 205,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Beja',
  },
  {
    id: 501,
    zone: 'Centro',
    district: 'Castelo Branco',
    county: 'Belmonte',
  },
  {
    id: 1405,
    zone: 'Alentejo',
    district: 'Santarém',
    county: 'Benavente',
  },
  {
    id: 1005,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Bombarral',
  },
  {
    id: 703,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Borba',
  },
  {
    id: 1610,
    zone: 'Norte',
    district: 'Viana do Castelo',
    county: 'Vila Nova de Cerveira',
  },
  {
    id: 1702,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Boticas',
  },
  {
    id: 1703,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Chaves',
  },
  {
    id: 1706,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Montalegre',
  },
  {
    id: 1104,
    zone: 'Centro',
    district: 'Lisboa',
    county: 'Cadaval',
  },
  {
    id: 1006,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Caldas da Rainha',
  },
  {
    id: 1709,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Ribeira de Pena',
  },
  {
    id: 1204,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Campo Maior',
  },
  {
    id: 602,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Cantanhede',
  },
  {
    id: 1712,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Valpaços',
  },
  {
    id: 1802,
    zone: 'Centro',
    district: 'Viseu',
    county: 'Carregal do Sal',
  },
  {
    id: 1406,
    zone: 'Alentejo',
    district: 'Santarém',
    county: 'Cartaxo',
  },
  {
    id: 1105,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Lisboa',
    county: 'Cascais',
  },
  {
    id: 1007,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Castanheira de Pêra',
  },
  {
    id: 502,
    zone: 'Centro',
    district: 'Castelo Branco',
    county: 'Castelo Branco',
  },
  {
    id: 1713,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Vila Pouca de Aguiar',
  },
  {
    id: 1205,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Castelo de Vide',
  },
  {
    id: 1803,
    zone: 'Centro',
    district: 'Viseu',
    county: 'Castro Daire',
  },
  {
    id: 804,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Castro Marim',
  },
  {
    id: 206,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Castro Verde',
  },
  {
    id: 903,
    zone: 'Centro',
    district: 'Guarda',
    county: 'Celorico da Beira',
  },
  {
    id: 104,
    zone: 'Norte',
    district: 'Aveiro',
    county: 'Arouca',
  },
  {
    id: 1407,
    zone: 'Alentejo',
    district: 'Santarém',
    county: 'Chamusca',
  },
  {
    id: 107,
    zone: 'Norte',
    district: 'Aveiro',
    county: 'Espinho',
  },
  {
    id: 1304,
    zone: 'Norte',
    district: 'Porto',
    county: 'Gondomar',
  },
  {
    id: 603,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Coimbra',
  },
  {
    id: 604,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Condeixa-A-Nova',
  },
  {
    id: 1408,
    zone: 'Centro',
    district: 'Santarém',
    county: 'Constância',
  },
  {
    id: 1409,
    zone: 'Alentejo',
    district: 'Santarém',
    county: 'Coruche',
  },
  {
    id: 503,
    zone: 'Centro',
    district: 'Castelo Branco',
    county: 'Covilhã',
  },
  {
    id: 1206,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Crato',
  },
  {
    id: 207,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Cuba',
  },
  {
    id: 1207,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Elvas',
  },
  {
    id: 1410,
    zone: 'Centro',
    district: 'Santarém',
    county: 'Entroncamento',
  },
  {
    id: 1306,
    zone: 'Norte',
    district: 'Porto',
    county: 'Maia',
  },
  {
    id: 1308,
    zone: 'Norte',
    district: 'Porto',
    county: 'Matosinhos',
  },
  {
    id: 108,
    zone: 'Centro',
    district: 'Aveiro',
    county: 'Estarreja',
  },
  {
    id: 704,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Estremoz',
  },
  {
    id: 705,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Évora',
  },
  {
    id: 113,
    zone: 'Norte',
    district: 'Aveiro',
    county: 'Oliveira de Azeméis',
  },
  {
    id: 805,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Faro',
  },
  {
    id: 1310,
    zone: 'Norte',
    district: 'Porto',
    county: 'Paredes',
  },
  {
    id: 208,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Ferreira do Alentejo',
  },
  {
    id: 1411,
    zone: 'Centro',
    district: 'Santarém',
    county: 'Ferreira do Zêzere',
  },
  {
    id: 605,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Figueira da Foz',
  },
  {
    id: 904,
    zone: 'Centro',
    district: 'Guarda',
    county: 'Figueira de Castelo Rodrigo',
  },
  {
    id: 1008,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Figueiró dos Vinhos',
  },
  {
    id: 905,
    zone: 'Centro',
    district: 'Guarda',
    county: 'Fornos de Algodres',
  },
  {
    id: 1312,
    zone: 'Norte',
    district: 'Porto',
    county: 'Porto',
  },
  {
    id: 1208,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Fronteira',
  },
  {
    id: 504,
    zone: 'Centro',
    district: 'Castelo Branco',
    county: 'Fundão',
  },
  {
    id: 1209,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Gavião',
  },
  {
    id: 606,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Góis',
  },
  {
    id: 1412,
    zone: 'Alentejo',
    district: 'Santarém',
    county: 'Golegã',
  },
  {
    id: 1313,
    zone: 'Norte',
    district: 'Porto',
    county: 'Póvoa de Varzim',
  },
  {
    id: 906,
    zone: 'Centro',
    district: 'Guarda',
    county: 'Gouveia',
  },
  {
    id: 1505,
    zone: 'Alentejo',
    district: 'Setúbal',
    county: 'Grândola',
  },
  {
    id: 907,
    zone: 'Centro',
    district: 'Guarda',
    county: 'Guarda',
  },
  {
    id: 109,
    zone: 'Norte',
    district: 'Aveiro',
    county: 'Santa Maria da Feira',
  },
  {
    id: 505,
    zone: 'Centro',
    district: 'Castelo Branco',
    county: 'Idanha-A-Nova',
  },
  {
    id: 110,
    zone: 'Centro',
    district: 'Aveiro',
    county: 'Ílhavo',
  },
  {
    id: 806,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Lagoa',
  },
  {
    id: 807,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Lagos',
  },
  {
    id: 1314,
    zone: 'Norte',
    district: 'Porto',
    county: 'Santo Tirso',
  },
  {
    id: 1009,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Leiria',
  },
  {
    id: 1106,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Lisboa',
    county: 'Lisboa',
  },
  {
    id: 808,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Loulé',
  },
  {
    id: 1107,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Lisboa',
    county: 'Loures',
  },
  {
    id: 1108,
    zone: 'Centro',
    district: 'Lisboa',
    county: 'Lourinhã',
  },
  {
    id: 607,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Lousã',
  },
  {
    id: 116,
    zone: 'Norte',
    district: 'Aveiro',
    county: 'São João da Madeira',
  },
  {
    id: 1413,
    zone: 'Centro',
    district: 'Santarém',
    county: 'Mação',
  },
  {
    id: 1318,
    zone: 'Norte',
    district: 'Porto',
    county: 'Trofa',
  },
  {
    id: 1109,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Lisboa',
    county: 'Mafra',
  },
  {
    id: 119,
    zone: 'Norte',
    district: 'Aveiro',
    county: 'Vale de Cambra',
  },
  {
    id: 1806,
    zone: 'Centro',
    district: 'Viseu',
    county: 'Mangualde',
  },
  {
    id: 908,
    zone: 'Centro',
    district: 'Guarda',
    county: 'Manteigas',
  },
  {
    id: 1315,
    zone: 'Norte',
    district: 'Porto',
    county: 'Valongo',
  },
  {
    id: 1010,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Marinha Grande',
  },
  {
    id: 1210,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Marvão',
  },
  {
    id: 1316,
    zone: 'Norte',
    district: 'Porto',
    county: 'Vila do Conde',
  },
  {
    id: 111,
    zone: 'Centro',
    district: 'Aveiro',
    county: 'Mealhada',
  },
  {
    id: 909,
    zone: 'Centro',
    district: 'Guarda',
    county: 'Mêda',
  },
  {
    id: 1317,
    zone: 'Norte',
    district: 'Porto',
    county: 'Vila Nova de Gaia',
  },
  {
    id: 209,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Mértola',
  },
  {
    id: 304,
    zone: 'Norte',
    district: 'Braga',
    county: 'Cabeceiras de Basto',
  },
  {
    id: 608,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Mira',
  },
  {
    id: 609,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Miranda do Corvo',
  },
  {
    id: 307,
    zone: 'Norte',
    district: 'Braga',
    county: 'Fafe',
  },
  {
    id: 308,
    zone: 'Norte',
    district: 'Braga',
    county: 'Guimarães',
  },
  {
    id: 1705,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Mondim de Basto',
  },
  {
    id: 309,
    zone: 'Norte',
    district: 'Braga',
    county: 'Póvoa de Lanhoso',
  },
  {
    id: 1506,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Setúbal',
    county: 'Moita',
  },
  {
    id: 311,
    zone: 'Norte',
    district: 'Braga',
    county: 'Vieira do Minho',
  },
  {
    id: 809,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Monchique',
  },
  {
    id: 312,
    zone: 'Norte',
    district: 'Braga',
    county: 'Vila Nova de Famalicão',
  },
  {
    id: 1211,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Monforte',
  },
  {
    id: 314,
    zone: 'Norte',
    district: 'Braga',
    county: 'Vizela',
  },
  {
    id: 706,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Montemor-O-Novo',
  },
  {
    id: 610,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Montemor-O-Velho',
  },
  {
    id: 1507,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Setúbal',
    county: 'Montijo',
  },
  {
    id: 707,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Mora',
  },
  {
    id: 1808,
    zone: 'Centro',
    district: 'Viseu',
    county: 'Mortágua',
  },
  {
    id: 210,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Moura',
  },
  {
    id: 708,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Mourão',
  },
  {
    id: 301,
    zone: 'Norte',
    district: 'Braga',
    county: 'Amares',
  },
  {
    id: 112,
    zone: 'Centro',
    district: 'Aveiro',
    county: 'Murtosa',
  },
  {
    id: 1011,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Nazaré',
  },
  {
    id: 1809,
    zone: 'Centro',
    district: 'Viseu',
    county: 'Nelas',
  },
  {
    id: 1212,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Nisa',
  },
  {
    id: 1012,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Óbidos',
  },
  {
    id: 211,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Odemira',
  },
  {
    id: 1116,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Lisboa',
    county: 'Odivelas',
  },
  {
    id: 1110,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Lisboa',
    county: 'Oeiras',
  },
  {
    id: 506,
    zone: 'Centro',
    district: 'Castelo Branco',
    county: 'Oleiros',
  },
  {
    id: 810,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Olhão',
  },
  {
    id: 302,
    zone: 'Norte',
    district: 'Braga',
    county: 'Barcelos',
  },
  {
    id: 1810,
    zone: 'Centro',
    district: 'Viseu',
    county: 'Oliveira de Frades',
  },
  {
    id: 114,
    zone: 'Centro',
    district: 'Aveiro',
    county: 'Oliveira do Bairro',
  },
  {
    id: 611,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Oliveira do Hospital',
  },
  {
    id: 1421,
    zone: 'Centro',
    district: 'Santarém',
    county: 'Ourém',
  },
  {
    id: 212,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Ourique',
  },
  {
    id: 115,
    zone: 'Centro',
    district: 'Aveiro',
    county: 'Ovar',
  },
  {
    id: 303,
    zone: 'Norte',
    district: 'Braga',
    county: 'Braga',
  },
  {
    id: 1508,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Setúbal',
    county: 'Palmela',
  },
  {
    id: 612,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Pampilhosa da Serra',
  },
  {
    id: 306,
    zone: 'Norte',
    district: 'Braga',
    county: 'Esposende',
  },
  {
    id: 310,
    zone: 'Norte',
    district: 'Braga',
    county: 'Terras de Bouro',
  },
  {
    id: 1013,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Pedrógão Grande',
  },
  {
    id: 613,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Penacova',
  },
  {
    id: 313,
    zone: 'Norte',
    district: 'Braga',
    county: 'Vila Verde',
  },
  {
    id: 1811,
    zone: 'Centro',
    district: 'Viseu',
    county: 'Penalva do Castelo',
  },
  {
    id: 507,
    zone: 'Centro',
    district: 'Castelo Branco',
    county: 'Penamacor',
  },
  {
    id: 1701,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Alijó',
  },
  {
    id: 614,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Penela',
  },
  {
    id: 1014,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Peniche',
  },
  {
    id: 1801,
    zone: 'Norte',
    district: 'Viseu',
    county: 'Armamar',
  },
  {
    id: 910,
    zone: 'Centro',
    district: 'Guarda',
    county: 'Pinhel',
  },
  {
    id: 1015,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Pombal',
  },
  {
    id: 403,
    zone: 'Norte',
    district: 'Bragança',
    county: 'Carrazeda de Ansiães',
  },
  {
    id: 404,
    zone: 'Norte',
    district: 'Bragança',
    county: 'Freixo de Espada À Cinta',
  },
  {
    id: 1213,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Ponte de Sor',
  },
  {
    id: 1214,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Portalegre',
  },
  {
    id: 709,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Portel',
  },
  {
    id: 811,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Portimão',
  },
  {
    id: 1805,
    zone: 'Norte',
    district: 'Viseu',
    county: 'Lamego',
  },
  {
    id: 1016,
    zone: 'Centro',
    district: 'Leiria',
    county: 'Porto de Mós',
  },
  {
    id: 1704,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Mesão Frio',
  },
  {
    id: 1807,
    zone: 'Norte',
    district: 'Viseu',
    county: 'Moimenta da Beira',
  },
  {
    id: 508,
    zone: 'Centro',
    district: 'Castelo Branco',
    county: 'Proença-A-Nova',
  },
  {
    id: 710,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Redondo',
  },
  {
    id: 711,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Reguengos de Monsaraz',
  },
  {
    id: 1707,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Murça',
  },
  {
    id: 1812,
    zone: 'Norte',
    district: 'Viseu',
    county: 'Penedono',
  },
  {
    id: 1414,
    zone: 'Alentejo',
    district: 'Santarém',
    county: 'Rio Maior',
  },
  {
    id: 1708,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Peso da Régua',
  },
  {
    id: 911,
    zone: 'Centro',
    district: 'Guarda',
    county: 'Sabugal',
  },
  {
    id: 1415,
    zone: 'Alentejo',
    district: 'Santarém',
    county: 'Salvaterra de Magos',
  },
  {
    id: 1814,
    zone: 'Centro',
    district: 'Viseu',
    county: 'Santa Comba Dão',
  },
  {
    id: 1710,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Sabrosa',
  },
  {
    id: 1711,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Santa Marta de Penaguião',
  },
  {
    id: 1416,
    zone: 'Alentejo',
    district: 'Santarém',
    county: 'Santarém',
  },
  {
    id: 1509,
    zone: 'Alentejo',
    district: 'Setúbal',
    county: 'Santiago do Cacém',
  },
  {
    id: 1815,
    zone: 'Norte',
    district: 'Viseu',
    county: 'São João da Pesqueira',
  },
  {
    id: 812,
    zone: 'Algarve',
    district: 'Faro',
    county: 'São Brás de Alportel',
  },
  {
    id: 1818,
    zone: 'Norte',
    district: 'Viseu',
    county: 'Sernancelhe',
  },
  {
    id: 1819,
    zone: 'Norte',
    district: 'Viseu',
    county: 'Tabuaço',
  },
  {
    id: 1816,
    zone: 'Centro',
    district: 'Viseu',
    county: 'São Pedro do Sul',
  },
  {
    id: 1417,
    zone: 'Centro',
    district: 'Santarém',
    county: 'Sardoal',
  },
  {
    id: 1817,
    zone: 'Centro',
    district: 'Viseu',
    county: 'Sátão',
  },
  {
    id: 912,
    zone: 'Centro',
    district: 'Guarda',
    county: 'Seia',
  },
  {
    id: 1510,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Setúbal',
    county: 'Seixal',
  },
  {
    id: 1820,
    zone: 'Norte',
    district: 'Viseu',
    county: 'Tarouca',
  },
  {
    id: 213,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Serpa',
  },
  {
    id: 509,
    zone: 'Centro',
    district: 'Castelo Branco',
    county: 'Sertã',
  },
  {
    id: 1511,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Setúbal',
    county: 'Sesimbra',
  },
  {
    id: 1512,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Setúbal',
    county: 'Setúbal',
  },
  {
    id: 117,
    zone: 'Centro',
    district: 'Aveiro',
    county: 'Sever do Vouga',
  },
  {
    id: 813,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Silves',
  },
  {
    id: 1513,
    zone: 'Alentejo',
    district: 'Setúbal',
    county: 'Sines',
  },
  {
    id: 1111,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Lisboa',
    county: 'Sintra',
  },
  {
    id: 1112,
    zone: 'Centro',
    district: 'Lisboa',
    county: 'Sobral de Monte Agraço',
  },
  {
    id: 615,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Soure',
  },
  {
    id: 1215,
    zone: 'Alentejo',
    district: 'Portalegre',
    county: 'Sousel',
  },
  {
    id: 616,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Tábua',
  },
  {
    id: 409,
    zone: 'Norte',
    district: 'Bragança',
    county: 'Torre de Moncorvo',
  },
  {
    id: 914,
    zone: 'Norte',
    district: 'Guarda',
    county: 'Vila Nova de Foz Côa',
  },
  {
    id: 814,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Tavira',
  },
  {
    id: 1714,
    zone: 'Norte',
    district: 'Vila Real',
    county: 'Vila Real',
  },
  {
    id: 1418,
    zone: 'Centro',
    district: 'Santarém',
    county: 'Tomar',
  },
  {
    id: 1821,
    zone: 'Centro',
    district: 'Viseu',
    county: 'Tondela',
  },
  {
    id: 1301,
    zone: 'Norte',
    district: 'Porto',
    county: 'Amarante',
  },
  {
    id: 1419,
    zone: 'Centro',
    district: 'Santarém',
    county: 'Torres Novas',
  },
  {
    id: 1113,
    zone: 'Centro',
    district: 'Lisboa',
    county: 'Torres Vedras',
  },
  {
    id: 913,
    zone: 'Centro',
    district: 'Guarda',
    county: 'Trancoso',
  },
  {
    id: 1302,
    zone: 'Norte',
    district: 'Porto',
    county: 'Baião',
  },
  {
    id: 118,
    zone: 'Centro',
    district: 'Aveiro',
    county: 'Vagos',
  },
  {
    id: 106,
    zone: 'Norte',
    district: 'Aveiro',
    county: 'Castelo de Paiva',
  },
  {
    id: 305,
    zone: 'Norte',
    district: 'Braga',
    county: 'Celorico de Basto',
  },
  {
    id: 1804,
    zone: 'Norte',
    district: 'Viseu',
    county: 'Cinfães',
  },
  {
    id: 1303,
    zone: 'Norte',
    district: 'Porto',
    county: 'Felgueiras',
  },
  {
    id: 712,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Vendas Novas',
  },
  {
    id: 713,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Viana do Alentejo',
  },
  {
    id: 1305,
    zone: 'Norte',
    district: 'Porto',
    county: 'Lousada',
  },
  {
    id: 214,
    zone: 'Alentejo',
    district: 'Beja',
    county: 'Vidigueira',
  },
  {
    id: 1307,
    zone: 'Norte',
    district: 'Porto',
    county: 'Marco de Canaveses',
  },
  {
    id: 510,
    zone: 'Centro',
    district: 'Castelo Branco',
    county: 'Vila de Rei',
  },
  {
    id: 815,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Vila do Bispo',
  },
  {
    id: 1309,
    zone: 'Norte',
    district: 'Porto',
    county: 'Paços de Ferreira',
  },
  {
    id: 1311,
    zone: 'Norte',
    district: 'Porto',
    county: 'Penafiel',
  },
  {
    id: 1114,
    zone: 'Área Metropolitana de Lisboa',
    district: 'Lisboa',
    county: 'Vila Franca de Xira',
  },
  {
    id: 1420,
    zone: 'Centro',
    district: 'Santarém',
    county: 'Vila Nova da Barquinha',
  },
  {
    id: 1813,
    zone: 'Norte',
    district: 'Viseu',
    county: 'Resende',
  },
  {
    id: 401,
    zone: 'Norte',
    district: 'Bragança',
    county: 'Alfândega da Fé',
  },
  {
    id: 402,
    zone: 'Norte',
    district: 'Bragança',
    county: 'Bragança',
  },
  {
    id: 405,
    zone: 'Norte',
    district: 'Bragança',
    county: 'Macedo de Cavaleiros',
  },
  {
    id: 1822,
    zone: 'Centro',
    district: 'Viseu',
    county: 'Vila Nova de Paiva',
  },
  {
    id: 617,
    zone: 'Centro',
    district: 'Coimbra',
    county: 'Vila Nova de Poiares',
  },
  {
    id: 406,
    zone: 'Norte',
    district: 'Bragança',
    county: 'Miranda do Douro',
  },
  {
    id: 407,
    zone: 'Norte',
    district: 'Bragança',
    county: 'Mirandela',
  },
  {
    id: 816,
    zone: 'Algarve',
    district: 'Faro',
    county: 'Vila Real de Santo António',
  },
  {
    id: 511,
    zone: 'Centro',
    district: 'Castelo Branco',
    county: 'Vila Velha de Ródão',
  },
  {
    id: 408,
    zone: 'Norte',
    district: 'Bragança',
    county: 'Mogadouro',
  },
  {
    id: 714,
    zone: 'Alentejo',
    district: 'Évora',
    county: 'Vila Viçosa',
  },
  {
    id: 410,
    zone: 'Norte',
    district: 'Bragança',
    county: 'Vila Flor',
  },
  {
    id: 411,
    zone: 'Norte',
    district: 'Bragança',
    county: 'Vimioso',
  },
  {
    id: 1823,
    zone: 'Centro',
    district: 'Viseu',
    county: 'Viseu',
  },
  {
    id: 412,
    zone: 'Norte',
    district: 'Bragança',
    county: 'Vinhais',
  },
  {
    id: 1824,
    zone: 'Centro',
    district: 'Viseu',
    county: 'Vouzela',
  },
  {
    zone: 'Região Autónoma da Madeira',
    district: 'Região Autónoma da Madeira',
    county: 'Calheta',
  },
  {
    zone: 'Região Autónoma da Madeira',
    district: 'Região Autónoma da Madeira',
    county: 'Câmara de Lobos',
  },
  {
    zone: 'Região Autónoma da Madeira',
    district: 'Região Autónoma da Madeira',
    county: 'Funchal',
  },
  {
    zone: 'Região Autónoma da Madeira',
    district: 'Região Autónoma da Madeira',
    county: 'Machico',
  },
  {
    zone: 'Região Autónoma da Madeira',
    district: 'Região Autónoma da Madeira',
    county: 'Ponta do Sol',
  },
  {
    zone: 'Região Autónoma da Madeira',
    district: 'Região Autónoma da Madeira',
    county: 'Porto Moniz',
  },
  {
    zone: 'Região Autónoma da Madeira',
    district: 'Região Autónoma da Madeira',
    county: 'Porto Santo',
  },
  {
    zone: 'Região Autónoma da Madeira',
    district: 'Região Autónoma da Madeira',
    county: 'Ribeira Brava',
  },
  {
    zone: 'Região Autónoma da Madeira',
    district: 'Região Autónoma da Madeira',
    county: 'Santa Cruz',
  },
  {
    zone: 'Região Autónoma da Madeira',
    district: 'Região Autónoma da Madeira',
    county: 'Santana',
  },
  {
    zone: 'Região Autónoma da Madeira',
    district: 'Região Autónoma da Madeira',
    county: 'São Vicente',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Lages das Flores',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Santa Cruz das Flores',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Corvo',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Angra do Heroísmo',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Calheta de S. Jorge',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Horta',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Lages do Pico',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Madalena',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Praia da Vitória',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Santa Cruz da Graciosa',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'São Roque do Pico',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Velas',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Lagoa',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Nordeste',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Ponta Delgada',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Povoação',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Ribeira Grande',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Vila do Porto',
  },
  {
    zone: 'Região Autónoma dos Açores',
    district: 'Região Autónoma dos Açores',
    county: 'Vila Franca do Campo',
  },
]

export default LOCATION_DATA
