export const ENTITY_TYPE_OPTIONS: DropdownOption<EntityType>[] = [
  {
    label: 'entity.types.bookstore',
    value: 'bookstore',
  },
  {
    label: 'entity.types.library',
    value: 'library',
  },
  {
    label: 'entity.types.literary_house',
    value: 'literary_house',
  },
]
