import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'
import { getEntityUrl } from './utils'
import updateEntityImage from './updateEntityImage'

interface UpdateEntityArgs {
  id: number
  type: EntityType
  data: Record<string, any>
}

/**
 * Sends updated entity data to the backend to be saved in the database.
 * Returns a fresh copy of the entity
 */
async function updateEntityById<T = Entity>({
  id,
  type,
  data,
}: UpdateEntityArgs) {
  const url = `${getEntityUrl(type)}${id}/`
  const { image, ...entity } = data

  if (data.image && typeof data.image !== 'string') {
    await updateEntityImage({ id, type, image })
  }

  return axios.patch<T>(url, entity)
}

export default wrapApiCall(updateEntityById)
