import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

interface LoginArgs {
  email: string
  password: string
}

interface LoginResponse {
  token: string
}

/**
 * Calls the backend with the user's email and password to log them in.
 * If successful, returns a token which is used to authenticate the user
 * on future API calls
 */
const loginUser = ({ email, password }: LoginArgs) => {
  return axios.post<LoginResponse>('/auth/login', { email, password })
}

export default wrapApiCall(loginUser, { noAuth: true })
