import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Search } from 'react-feather'
import { Box, Table, TextInput } from 'grommet'
import _ from 'lodash'
import NoResultsText from 'components/NoResultsText'
import Body from './Body'
import Header from './Header'
import Pagination from './Pagination'

const PER_PAGE = 15

function CustomTable<P>({
  actions = [],
  checkOption,
  columns,
  getRowKey,
  items,
  itemRenderFunctions,
  searchableColumns,
}: CustomTableProps<P>) {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [pagedItems, setPagedItems] = useState(() => _.chunk(items, PER_PAGE))

  useEffect(() => {
    let cancel = false

    if (!searchableColumns || !searchText.trim()) {
      setPage(0)
      setPagedItems(_.chunk(items, PER_PAGE))
      return
    }

    setTimeout(() => {
      if (cancel) return

      const textFilter = _.deburr(searchText)
        .trim()
        .toLowerCase()
      const words = textFilter.split(' ').filter(Boolean)

      const filtered = items.filter((item) => {
        const infoString = searchableColumns
          .map((field) => item[field])
          .join('"')
          .toLowerCase()
        const tidiedString = `"${_.deburr(infoString)}"`

        return words.every((word) => {
          return [word, textFilter].some((str) => tidiedString.includes(str))
        })
      })

      setPage(0)
      setPagedItems(_.chunk(filtered, PER_PAGE))
    }, 250)

    return () => {
      cancel = true
    }
  }, [searchText, searchableColumns, items])

  const actionTypes = actions.map(({ action }) => action)
  const headers = columns.map(({ label }) => label)
  const propKeys = columns.map(({ propKey }) => propKey)

  return (
    <>
      {searchableColumns && (
        <Box
          direction="row"
          justify="center"
          align="center"
          margin="1rem auto"
          width="medium"
          round={{ corner: 'top', size: 'xsmall' }}
          border="bottom"
          background="light-2"
          pad={{ left: '11px' }}
        >
          <Search />
          <TextInput
            placeholder={t('common.search')}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            plain
          />
        </Box>
      )}
      <Box overflow="auto">
        <Table margin="0 auto">
          <Header
            actionTypes={actionTypes}
            checkOption={checkOption}
            columns={headers}
          />
          <Body
            actions={actions}
            checkOption={checkOption}
            getRowKey={getRowKey}
            items={pagedItems[page] || []}
            itemRenderFunctions={itemRenderFunctions}
            propKeys={propKeys}
          />
        </Table>
      </Box>

      {!pagedItems.length && <NoResultsText />}

      <Pagination
        page={page}
        setPage={setPage}
        lastPage={pagedItems.length - 1}
      />
    </>
  )
}

export default CustomTable
