import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Send } from 'react-feather'
import { Box, Heading } from 'grommet'
import { useUsersList } from 'context/UsersContext'
import Modal from 'components/Modal'
import Form from 'components/Form'
import Input from 'components/Input'
import Dropdown from 'components/Dropdown'
import { toast } from 'react-toastify'
import { useEntitiesList } from 'context/EntitiesContext'
import {
  validateEmail,
  validateEntityId,
  validateProfile,
} from 'utils/validation/users'
import { PROFILE_OPTIONS } from './constants'

interface NewUserProps {
  closeModal: () => void
}

const NewUser: React.FC<NewUserProps> = ({ closeModal }) => {
  const { t } = useTranslation()
  const { sendUserInvite } = useUsersList()
  const { entitiesOptions } = useEntitiesList()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [entity, setEntity] = useState<Entity>()
  const [profile, setProfile] = useState<ProfileType>()

  const handleClose = () => {
    // Don't allow the user to close the modal while it's saving details
    if (loading) return
    closeModal()
  }

  const handleSubmit = async () => {
    const userData: UserInviteData = {
      name,
      email,
      phone: phone || undefined,
      entity_id: entity && entity.id,
      is_superuser: !entity,
    }

    const error =
      validateEmail(email) ||
      validateProfile(profile) ||
      validateEntityId(profile, userData.entity_id)

    if (error) {
      toast.error(error)
      return
    }

    setLoading(true)
    const success = await sendUserInvite(userData)
    setLoading(false)

    if (success) closeModal()
  }

  return (
    <Modal onClick={handleClose}>
      <Box width="medium">
        <Heading level="3">{t('users.newUser.title')}</Heading>

        <Form
          onSubmit={handleSubmit}
          submitButtonIcon={<Send size={16} />}
          submitButtonLabel={t('users.invites.sendInvite')}
          disabled={loading}
        >
          <Input
            label="common.name"
            value={name}
            onChange={setName}
            required
            autoFocus
          />

          <Input
            label="common.email"
            value={email}
            onChange={setEmail}
            type="email"
            required
            trimValue
          />

          <Input
            label="common.phone"
            value={phone}
            onChange={(value) => setPhone(value.replace(/\D/g, ''))}
            type="tel"
            maxLength={10}
          />

          <Dropdown<ProfileType>
            label="common.profile"
            value={PROFILE_OPTIONS.find(({ value }) => value === profile)}
            options={PROFILE_OPTIONS}
            onChange={(value) => {
              if (entity) setEntity(undefined)
              setProfile(value)
            }}
          />

          {profile === 'entity' && (
            <Dropdown<Entity>
              label="common.entity"
              value={
                entity &&
                entitiesOptions.find(({ value }) => value.id === entity.id)
              }
              options={entitiesOptions}
              onChange={setEntity}
              translateLabels={false}
            />
          )}
        </Form>
      </Box>
    </Modal>
  )
}

export default NewUser
