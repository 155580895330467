import React, { useState } from 'react'
import { RouteComponentProps, Redirect } from '@reach/router'
import { Book } from 'react-feather'
import { Box, Heading } from 'grommet'
import { useEntitiesList } from 'context/EntitiesContext'
import { HighlightsProvider } from 'context/HighlightsContext'
import PageHeading from 'components/PageHeading'
import HighlightsList from './HighlightsList'
import NewHighlight from './NewHighlight'
import LoadingSpinner from 'components/LoadingSpinner'

type HighlightsComponent = React.FC<RouteComponentProps<{ id: string }>>

const HighlightsIndex: HighlightsComponent = ({ id }) => {
  const [isNewHighlightModalOpen, setNewHighlightModal] = useState(false)
  const { entities, myEntity } = useEntitiesList()

  let entityId = 0
  if (id) entityId = parseFloat(id)
  if (myEntity) entityId = myEntity.id

  if (!entityId) return <Redirect path="/" />

  const selectedEntity = myEntity || entities.find((e) => e.id === entityId)

  if (!selectedEntity) return <LoadingSpinner />

  return (
    <HighlightsProvider entityId={entityId}>
      <PageHeading
        title="highlights.list.title"
        button={{
          icon: <Book color="white" size="20px" />,
          label: 'highlights.newHighlight.create',
          onClick: () => setNewHighlightModal(true),
        }}
      />
      {selectedEntity && (
        <Box align="center">
          <Heading textAlign="center" level="2" margin="0">
            {selectedEntity.name}
          </Heading>
        </Box>
      )}

      <HighlightsList />
      {selectedEntity && isNewHighlightModalOpen && (
        <NewHighlight closeModal={() => setNewHighlightModal(false)} />
      )}
    </HighlightsProvider>
  )
}

export default HighlightsIndex
