import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

/**
 * Sends updated user data to the backend to be saved in the database.
 * Returns a fresh copy of the user
 */
const updateUser = (user: UserUpdateData) => {
  return axios.patch<User>(`/users/${user.id}/`, user)
}

export default wrapApiCall(updateUser)
