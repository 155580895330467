import styled from 'styled-components'

export const NumberButton = styled.button<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ active, theme }) => {
    const { black, brand, white } = theme.global.colors
    const [background, border, color] = active
      ? [brand, brand, white]
      : [white, black, black]

    return `
      background-color: ${background};
      border: 1px solid ${border};
      color: ${color};
    `
  }};

  font-size: 1rem;
  font-weight: 600;
  height: 30px;
  width: 30px;
  margin: 0;
  padding: 0;
`
