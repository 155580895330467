import React, { useState } from 'react'
import { Text } from 'grommet'
import _ from 'lodash'
import { useInfoList } from 'context/InfoContext'
import Table from 'components/Table'
import EditInfo from './EditInfo'

const InfoList = () => {
  const { infos } = useInfoList()
  const [infoToEdit, setInfoToEdit] = useState<Info>()

  return (
    <>
      <Table<Info>
        actions={[{ action: 'edit', onClick: setInfoToEdit }]}
        columns={[
          {
            label: 'info.table.title',
            propKey: 'title',
          },
          {
            label: 'info.table.description',
            propKey: 'description',
          },
        ]}
        getRowKey={({ title }) => `${title}`}
        items={infos}
        itemRenderFunctions={{
          description: ({ description }) => {
            return (
              <Text size="xsmall">
                {description ? _.truncate(description, { length: 50 }) : '---'}
              </Text>
            )
          },
        }}
      />

      {infoToEdit && (
        <EditInfo
          info={infoToEdit}
          closeModal={() => setInfoToEdit(undefined)}
        />
      )}
    </>
  )
}

export default InfoList
