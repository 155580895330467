import React from 'react'

const rawHTML = `
<div>
    <label>Descrição *</label>
    <p>Tags disponíveis</p>
  <ul>
    <li><img src="https://i.imgur.com/Zai7AoP.png" alt="" width="14" height="14" />: {{icon-info}}</li>
    <li><img src="https://i.imgur.com/IRUJNWb.png" alt="" width="14" height="14" />: {{icon-bibliotecas}}</li>
    <li><img src="https://i.imgur.com/MXG1rvV.png" alt="" width="14" height="14" />: {{icon-livrarias}}</li>
    <li><img src="https://i.imgur.com/w6CrpWV.png" alt="" width="14" height="14" />: {{icon-festivais}}</li>
    <li><img src="https://i.imgur.com/wosGtmy.png" alt="" width="14" height="14" />: {{icon-casas}}</li>
    <li><img src="https://i.imgur.com/s2j1vj4.png" alt="" width="14" height="14" />: {{icon-autores}}</li>
  </ul>
</div>
`

const EditorHelp = () => (
  <div>{<div dangerouslySetInnerHTML={{ __html: rawHTML }} />}</div>
)

export default EditorHelp
