import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormField, Box, CheckBox } from 'grommet'

interface MultiSelectProps<T> {
  label: string
  selected: T[]
  options: DropdownOption<T>[]
  onChange: (value: T) => void
  required?: boolean
}

function MultiSelect<T>({
  label,
  options,
  onChange,
  selected,
  required,
}: MultiSelectProps<T>) {
  const { t } = useTranslation()
  const labelText = `${t(label)}${required ? ' *' : ''}`

  return (
    <FormField label={labelText}>
      <Box gap="xsmall" margin={{ bottom: 'small' }}>
        {options.map(({ value, label }) => {
          return (
            <CheckBox
              key={label}
              label={t(label)}
              checked={selected.includes(value)}
              onChange={() => onChange(value)}
            />
          )
        })}
      </Box>
    </FormField>
  )
}

export default MultiSelect
