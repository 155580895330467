import * as React from 'react'
import styled from 'styled-components'
import { getThemeColor } from 'utils/helpers'

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`

const Spinner = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  border: 3px solid transparent;
  border-top-color: ${getThemeColor('brand')};
  border-radius: 50px;
  height: 70px;
  width: 70px;
  animation: spin 0.6s linear infinite;

  &.small {
    height: 35px;
    width: 35px;
    margin: 5px;
    border-width: 2px;
    animation: spin 0.5s linear infinite;
  }
`

const LoadingSpinner = (props: { className?: string }) => (
  <Background>
    <Spinner {...props} />
  </Background>
)

export default LoadingSpinner
