import styled from 'styled-components'
import { getThemeColor } from 'utils/helpers'

export const Label = styled.label<{ fileExists: boolean }>`
  position: relative;
  width: 100%;

  background-color: ${({ color = 'brand', fileExists, theme }) => {
    if (fileExists) {
      return theme.global.colors['status-ok']
    }
    return theme.global.colors[color]
  }};
  border-radius: 4px 4px 0 0;
  color: ${getThemeColor('white')};

  &:hover {
    cursor: pointer;
  }
`

export const Input = styled.input`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 1px;
  height: 1px;
  opacity: 0;
  overflow: hidden;
`
