import React, { useCallback, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import getFestivalsList from 'api/festivals/getFestivalsList'
import createFestival from 'api/festivals/createFestival'
import deleteFestivalById from 'api/festivals/deleteFestivalById'
import updateFestivalData from 'api/festivals/updateFestival'
import EntitiesContext from './EntitiesContext'
import getFestivalById from 'api/festivals/getFestivalById'

interface FestivalsContextValue {
  festivals: Festival[]
  getFestivals: () => void
  addFestival: (festival: NewFestivalData) => Promise<boolean>
  updateFestival: (festival: FestivalUpdateData) => Promise<boolean>
  deleteFestival: (festival: Festival) => Promise<boolean>
  festivalsOptions: DropdownOption<Festival>[]
}

const FestivalsContext = React.createContext<FestivalsContextValue>({
  festivals: [],
  getFestivals: () => {},
  addFestival: async (_: NewFestivalData) => false,
  updateFestival: async (_: FestivalUpdateData) => false,
  deleteFestival: async (_: Festival) => false,
  festivalsOptions: [],
})

export const FestivalsProvider: React.FC = ({ children }) => {
  const { festivals: f, updateSocialMedia } = useContext(EntitiesContext)
  const [festivals, setFestivals] = useState<Festival[]>(f)
  const [festivalsOptions, setFestivalsOptions] = useState<
    DropdownOption<Festival>[]
  >([])

  const setAllFestivals = (festivalList: Festival[]) => {
    const options = festivalList.map((festival) => ({
      label: festival.name,
      value: festival,
    }))
    setFestivals(festivalList)
    setFestivalsOptions(options)
  }

  const getFestivals = useCallback(async () => {
    const { data, error } = await getFestivalsList()

    if (data) {
      setAllFestivals(data.results)
      return
    }

    if (error) toast.error(error)
  }, [])

  const updateFestival = async (f: FestivalUpdateData) => {
    await updateSocialMedia(f.id, f.social)
    const { data: updatedData, error } = await updateFestivalData(f)

    if (error) toast.error(error)
    if (updatedData) {
      const { data: freshData, error: getError } = await getFestivalById(f.id)

      if (getError) toast.error(getError)
      if (!freshData) return false

      const newList = festivals.map((festival) =>
        festival.id === freshData.id ? freshData : festival,
      )
      setAllFestivals(newList)
      getFestivals()
    }

    return !!updatedData && !error
  }

  const addFestival = async (f: NewFestivalData) => {
    const { error, data } = await createFestival(f)

    if (data) {
      await updateSocialMedia(data.id, f.social)
      if (f.image) {
        await updateFestival({ ...data, image: f.image })
      } else {
        const newList = [data, ...festivals]
        setAllFestivals(newList)
      }
    }

    if (error) toast.error(error)
    return !!data && !error
  }

  const deleteFestival = async (f: Festival) => {
    const { error } = await deleteFestivalById(f.id)

    if (!error) {
      const newList = festivals.filter((festival) => festival.id !== f.id)
      setAllFestivals(newList)
    } else {
      toast.error(error)
    }

    return !error
  }

  return (
    <FestivalsContext.Provider
      value={{
        addFestival,
        festivals,
        getFestivals,
        deleteFestival,
        updateFestival,
        festivalsOptions,
      }}
    >
      {children}
    </FestivalsContext.Provider>
  )
}

export const useFestivalsList = () => useContext(FestivalsContext)

export default FestivalsContext
