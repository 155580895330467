import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Save } from 'react-feather'
import { Box, Heading } from 'grommet'
import { validateStartAndEndDate, validateURL } from 'utils/validation/common'
import { useLiteraryEventsList } from 'context/LiteraryEventsContext'
import Modal from 'components/Modal'
import Form from 'components/Form'
import Input from 'components/Input'
import Dropdown from 'components/Dropdown'
import TextArea from 'components/TextArea'
import DateInput from 'components/DateInput'
import ImageFileInput from 'components/FileInput/ImageFileInput'
import { AUDIENCE_OPTIONS } from './constants'

interface NewLiteraryEventProps {
  closeModal: () => void
}

const NewLiteraryEvent: React.FC<NewLiteraryEventProps> = ({ closeModal }) => {
  const { t } = useTranslation()
  const { addLiteraryEvent, literaryEvents } = useLiteraryEventsList()
  const [loading, setLoading] = useState(false)

  // Form-field data
  const [image, setImage] = useState<File | null>(null)
  const [name, setName] = useState('')
  const [moreInfo, setMoreInfo] = useState('')
  const [targetAudience, setAudience] = useState<LiteraryEventAudience>()
  const [place, setPlace] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [programming, setProgramming] = useState('')
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (literaryEvents.length >= 3) {
      closeModal()
      toast.error(t('literaryEvents.newLiteraryEvent.maxReached', { count: 3 }))
    }
  }, [closeModal, literaryEvents, t])

  const handleClose = () => {
    // Don't allow the user to close the modal while it's saving details
    if (loading) return
    closeModal()
  }

  const handleSubmit = async () => {
    const error =
      validateStartAndEndDate(startDate, endDate) ||
      validateURL(url, 'literaryEvent.url')

    if (error) {
      toast.error(error)
      return
    }

    const literaryEventData: NewLiteraryEventData = {
      name,
      start_date: startDate,
      end_date: endDate,
      programming,
      image: image || undefined,
      target_audience: targetAudience as LiteraryEventAudience,
      place,
      more_info: moreInfo,
      url,
    }

    setLoading(true)

    const success = await addLiteraryEvent(literaryEventData)
    setLoading(false)

    if (success) {
      toast.success(t('literaryEvents.newLiteraryEvent.created'))
      closeModal()
    }
  }

  return (
    <Modal onClick={handleClose}>
      <Box width="medium">
        <Heading level="3">
          {t('literaryEvents.newLiteraryEvent.title')}
        </Heading>

        <Form
          onCancel={handleClose}
          onSubmit={handleSubmit}
          submitButtonIcon={<Save size={16} />}
          submitButtonLabel={t('common.save')}
          disabled={loading}
        >
          <ImageFileInput file={image} onChange={setImage} />

          <Input
            label="common.name"
            value={name}
            onChange={setName}
            maxLength={50}
            required
          />

          <TextArea
            label="literaryEvent.more_info"
            value={moreInfo}
            onChange={setMoreInfo}
            maxLength={300}
          />

          <Dropdown<LiteraryEventAudience>
            label="literaryEvent.target_audience"
            value={AUDIENCE_OPTIONS.find(
              ({ value }) => value === targetAudience,
            )}
            options={AUDIENCE_OPTIONS}
            onChange={setAudience}
          />

          <Input
            label="literaryEvent.place"
            value={place}
            onChange={setPlace}
            maxLength={100}
            required
          />

          <DateInput
            label="literaryEvent.start_date"
            value={startDate}
            onChange={setStartDate}
            required
          />

          <DateInput
            label="literaryEvent.end_date"
            value={endDate}
            onChange={setEndDate}
            required
          />

          <TextArea
            label="literaryEvent.programming"
            value={programming}
            onChange={setProgramming}
            maxLength={300}
            required
          />

          <Input
            label="literaryEvent.url"
            value={url}
            onChange={setUrl}
            maxLength={220}
            trimValue
          />
        </Form>
      </Box>
    </Modal>
  )
}

export default NewLiteraryEvent
