import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useHighlightsList } from 'context/HighlightsContext'
import HighlightForm from './HighlightForm'

interface NewHighlightProps {
  closeModal: () => void
}

const NewHighlight: React.FC<NewHighlightProps> = ({ closeModal }) => {
  const { t } = useTranslation()
  const { addHighlight, entityId, highlights } = useHighlightsList()

  useEffect(() => {
    if (highlights.length >= 10) {
      closeModal()
      toast.error(t('highlights.newHighlight.maxReached', { count: 10 }))
    }
  }, [closeModal, highlights, t])

  const handleSubmit = async (highlightData: HighlightFormData) => {
    const success = await addHighlight(highlightData)

    if (success) {
      toast.success(t('highlights.newHighlight.created'))
      closeModal()
    }
  }

  return (
    <HighlightForm
      entityId={entityId}
      onSubmit={handleSubmit}
      closeModal={closeModal}
    />
  )
}

export default NewHighlight
