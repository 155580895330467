import React, { useState } from 'react'
import { Redirect, RouteComponentProps } from '@reach/router'
import { Calendar } from 'react-feather'
import { Box, Heading } from 'grommet'
import { useEntitiesList } from 'context/EntitiesContext'
import { LiteraryEventsProvider } from 'context/LiteraryEventsContext'
import PageHeading from 'components/PageHeading'
import LiteraryEventsList from './LiteraryEventsList'
import NewLiteraryEvent from './NewLiteraryEvent'
import LoadingSpinner from 'components/LoadingSpinner'

type EventsIndexComponent = React.FC<RouteComponentProps<{ id: string }>>

const LiteraryEventsIndex: EventsIndexComponent = ({ id }) => {
  const [isEventModalOpen, setNewLiteraryEventModal] = useState(false)
  const { entities, myEntity } = useEntitiesList()

  let entityId = 0
  if (id) entityId = parseFloat(id)
  if (myEntity) entityId = myEntity.id

  if (!entityId) return <Redirect path="/entities" />

  const selectedEntity = myEntity || entities.find((e) => e.id === entityId)
  if (!selectedEntity) return <LoadingSpinner />

  return (
    <LiteraryEventsProvider entityId={entityId}>
      <PageHeading
        title="literaryEvents.list.title"
        button={{
          icon: <Calendar color="white" size="20px" />,
          label: 'literaryEvents.newLiteraryEvent.create',
          onClick: () => setNewLiteraryEventModal(true),
        }}
      />

      {selectedEntity && (
        <Box align="center">
          <Heading textAlign="center" level="2" margin="0">
            {selectedEntity.name}
          </Heading>
        </Box>
      )}

      <LiteraryEventsList />
      {isEventModalOpen && (
        <NewLiteraryEvent closeModal={() => setNewLiteraryEventModal(false)} />
      )}
    </LiteraryEventsProvider>
  )
}

export default LiteraryEventsIndex
