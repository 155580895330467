import React, { useMemo } from 'react'
import { ThemeContext } from 'grommet'
import { useEntitiesList } from 'context/EntitiesContext'
import { colorsByUserType, createThemeColors } from 'style/theme'

const ThemeByUserType: React.FC = ({ children }) => {
  const { myEntity } = useEntitiesList()

  const colorScheme = useMemo(() => {
    let color = colorsByUserType.default
    if (myEntity) {
      color = colorsByUserType[myEntity.entity_type]
    }

    return createThemeColors(color)
  }, [myEntity])

  return (
    <ThemeContext.Extend value={{ global: { colors: colorScheme } }}>
      {children}
    </ThemeContext.Extend>
  )
}

export default ThemeByUserType
