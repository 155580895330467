import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, Link, navigate, Redirect } from '@reach/router'
import { toast } from 'react-toastify'
import { Box, Heading, Text } from 'grommet'
import Input from 'components/Input'
import Form from 'components/Form'
import LoggedOutLayout from 'components/LoggedOutLayout'
import {
  validatePassword,
  validatePasswordConfirmation,
} from 'utils/validation/users'
import passwordResetConfirm from 'api/auth/passwordResetConfirm'

type PageComponent = React.FC<RouteComponentProps<{ token: string }>>

const ResetPasswordPage: PageComponent = ({ token }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConf, setPasswordConfirmation] = useState('')

  if (!token) return <Redirect path="/" />

  const handleSubmit = async () => {
    const validationError =
      validatePassword(password) ||
      validatePasswordConfirmation(password, passwordConf)

    if (validationError) {
      toast.error(validationError)
      return
    }

    setLoading(true)
    toast(t('auth.resettingPassword'), { autoClose: false })

    const { error } = await passwordResetConfirm({ password, token })
    setLoading(false)
    toast.dismiss()

    if (error) {
      toast.error(error)
      return
    }

    navigate('/')
    toast.success(t('auth.passwordReset'))
  }

  return (
    <LoggedOutLayout>
      <Heading level="2">{t(`auth.title.resetPassword`)}</Heading>
      <Text size="small" margin={{ bottom: 'small' }}>
        {t('auth.validation.passwordMustBeComplicated')}
      </Text>

      <Form
        submitButtonLabel={t(`common.save`)}
        onSubmit={handleSubmit}
        disabled={loading}
      >
        <Input
          value={password}
          id="password"
          label="auth.password"
          onChange={setPassword}
          type="password"
          required
          autoFocus
        />

        <Input
          value={passwordConf}
          id="passwordConf"
          label="auth.passwordConf"
          onChange={setPasswordConfirmation}
          type="password"
          required
        />
      </Form>

      <Box gap="0.5rem" margin={{ top: 'medium' }} align="start">
        <Link to="/">
          <Text size="small">{t(`auth.option.login`)}</Text>
        </Link>
      </Box>
    </LoggedOutLayout>
  )
}

export default ResetPasswordPage
