import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { PenTool } from 'react-feather'
import { AuthorsProvider } from 'context/AuthorsContext'
import AuthorsList from './AuthorsList'
import NewAuthor from './NewAuthor'
import PageHeading from 'components/PageHeading'

const AuthorsIndex: React.FC<RouteComponentProps> = () => {
  const [isNewAuthorModalOpen, setNewAuthorModal] = useState(false)

  return (
    <AuthorsProvider>
      <PageHeading
        title="authors.list.title"
        button={{
          icon: <PenTool color="white" size="20px" />,
          label: 'authors.newAuthor.create',
          onClick: () => setNewAuthorModal(true),
        }}
      />

      <AuthorsList />
      {isNewAuthorModalOpen && (
        <NewAuthor closeModal={() => setNewAuthorModal(false)} />
      )}
    </AuthorsProvider>
  )
}

export default AuthorsIndex
