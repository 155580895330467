import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

interface ActivateSignUpArgs {
  uuid: string
  token: string
  password: string
}

/**
 * Sends a new user's password, token and uuid to the backend to activate
 * their account
 */
const activateUser = ({ uuid, token, password }: ActivateSignUpArgs) => {
  return axios.post(`/auth/activate/${uuid}/${token}/`, { password })
}

export default wrapApiCall(activateUser, { noAuth: true })
