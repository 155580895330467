import React from 'react'
import styled from 'styled-components'
import { Box } from 'grommet'

const Background = styled(Box)`
  background-color: ${({ theme }) => theme.global.colors.brandFaded};
  background-image: linear-gradient(
      178deg,
      transparent 40%,
      ${({ theme }) => theme.global.colors.brandDark} 40.1%
    ),
    linear-gradient(
      to right,
      ${({ theme }) => theme.global.colors.brandLight},
      ${({ theme }) => theme.global.colors.brand}
    );
`

const LoggedOutLayout: React.FC = ({ children }) => {
  return (
    <Background fill justify="center" align="center" pad="medium">
      <Box
        elevation="medium"
        background="white"
        pad="large"
        width="medium"
        animation="fadeIn"
        round="xsmall"
        overflow="auto"
      >
        {children}
      </Box>
    </Background>
  )
}

export default LoggedOutLayout
