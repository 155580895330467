import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Save } from 'react-feather'
import { toast } from 'react-toastify'
import { Box, Text, Heading } from 'grommet'
import { validateURL } from 'utils/validation/common'
import Modal from 'components/Modal'
import Form from 'components/Form'
import Input from 'components/Input'
import ImageFileInput from 'components/FileInput/ImageFileInput'

interface HighlightFormProps {
  highlight?: Highlight
  onSubmit: (_: HighlightFormData) => Promise<void>
  closeModal: () => void
  entityId: number
}

function HighlightForm({
  onSubmit,
  closeModal,
  highlight,
  entityId,
}: HighlightFormProps) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState<string>(highlight ? highlight.url : '')
  const [name, setName] = useState<string>(highlight ? highlight.name : '')
  const [image, setImage] = useState<File | null>(null)

  const handleClose = () => {
    // Don't allow the user to close the modal while it's saving details
    if (loading) return
    closeModal()
  }

  const handleSubmit = async () => {
    // Validate
    const error =
      (typeof entityId !== 'number' && t(`validation.common.entityMissing`)) ||
      validateURL(url, 'common.url')

    if (error) {
      toast.error(error)
      return
    }

    setLoading(true)
    const highlightData: HighlightFormData = {
      name,
      url,
      image,
    }
    await onSubmit(highlightData)
    setLoading(false)
  }

  return (
    <Modal onClick={handleClose}>
      <Box width="medium">
        <Heading level="3">
          {t(`highlights.${highlight ? 'edit' : 'new'}Highlight.title`)}
        </Heading>

        <Form
          onCancel={handleClose}
          onSubmit={handleSubmit}
          submitButtonIcon={<Save size={16} />}
          submitButtonLabel={t('common.save')}
          disabled={loading}
        >
          <ImageFileInput
            file={image}
            onChange={setImage}
            help={
              highlight &&
              typeof highlight.image === 'string' && (
                <Text size="small">
                  {`${t('forms.fileInput.current')}: ${highlight.image}`}
                </Text>
              )
            }
            required={!highlight}
          />
          <Input
            label="common.name"
            value={name}
            onChange={setName}
            maxLength={40}
            required
          />
          <Input
            label="common.url"
            value={url}
            onChange={setUrl}
            maxLength={200}
            required
            trimValue
          />
        </Form>
      </Box>
    </Modal>
  )
}

export default HighlightForm
