import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { InfoProvider } from 'context/InfoContext'
import InfoList from './InfoList'
import PageHeading from 'components/PageHeading'

const InfoIndex: React.FC<RouteComponentProps> = () => {
  return (
    <InfoProvider>
      <PageHeading title="nav.info" />

      <InfoList />
    </InfoProvider>
  )
}

export default InfoIndex
