import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

/**
 * Calls the backend to get a list of entities
 */
const getEntitiesList = () => {
  return axios.get<EntitiesListApiResponse>(`/entities`)
}

export default wrapApiCall(getEntitiesList, { showLoadingMessage: true })
