import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ProfileProvider } from 'context/ProfileContext'
import LoadingSpinner from 'components/LoadingSpinner'
import Toast from 'components/Toast'
import { Grommet } from 'grommet'
import theme from 'style/theme'
import GlobalStyle from 'style/GlobalStyle'
import Routes from './Routes'
import './config/axios'
import './config/sentry'
import './i18n'

const App = () => {
  return (
    <ProfileProvider>
      <Grommet full theme={theme}>
        <GlobalStyle />
        <Suspense fallback={<LoadingSpinner />}>
          <Routes />
        </Suspense>
        <Toast />
      </Grommet>
    </ProfileProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
