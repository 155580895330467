import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Box, Button, Text } from 'grommet'
import { UserCheck, CheckCircle, HelpCircle, Award } from 'react-feather'
import { useUsersList } from 'context/UsersContext'
import Modal from 'components/Modal'
import { Tabs, Tab } from 'components/Tabs'
import Table from 'components/Table'
import EditUser from './EditUser'

type UserTable = CustomTableProps<User>

const InvitesList = () => {
  const { t } = useTranslation()
  const { users, deleteUser } = useUsersList()
  const [deleteList, setDeleteList] = useState<User[]>([])
  const [invitesToDelete, setDeleteInvites] = useState<User[]>()
  const [userToEdit, setUserToEdit] = useState<User>()
  const { true: activeUsers = [], false: pendingInvites = [] } = useMemo(
    () => _.groupBy(users, 'is_active'),
    [users],
  )

  const activeCount = activeUsers.length
  const inactiveCount = pendingInvites.length

  const addToDeleteList = (user: User) => {
    if (deleteList.includes(user)) {
      setDeleteList(deleteList.filter((u) => u !== user))
    } else {
      setDeleteList([...deleteList, user])
    }
  }

  const handleMultiDelete = async () => {
    if (!invitesToDelete) return

    await Promise.all(invitesToDelete.map(async (u) => deleteUser(u)))
    setDeleteList([])
    setDeleteInvites(undefined)
  }

  const tableColumns: UserTable['columns'] = [
    { label: 'common.name', propKey: 'name' },
    { label: 'common.email', propKey: 'email' },
    { label: 'common.phone', propKey: 'phone' },
    { label: 'common.entity', propKey: 'entity' },
    { label: 'users.activated', propKey: 'is_active' },
  ]

  const searchableColumns: UserTable['searchableColumns'] = [
    'name',
    'email',
    'phone',
  ]

  const itemRenderFunctions: UserTable['itemRenderFunctions'] = {
    entity: ({ entity }) => {
      if (!entity) return null
      return (
        <Box>
          <Text size="small">{entity.name}</Text>
          <Text size="xsmall">{t(`entity.types.${entity.entity_type}`)}</Text>
        </Box>
      )
    },
    is_active: ({ is_active: active, is_superuser: isSuper }) => {
      const [color, Icon] = active
        ? ['status-ok', UserCheck]
        : ['status-warning', HelpCircle]
      return (
        <Box direction="row" align="center" gap="xsmall">
          <Text color={color}>
            <Icon size={20} />
          </Text>
          {isSuper && (
            <Text color="neutral-3">
              <Award size={20} />
            </Text>
          )}
        </Box>
      )
    },
  }

  return (
    <>
      <Tabs>
        <Tab
          title={
            <Box direction="row" gap="0.5rem">
              <Text color="status-ok">
                <CheckCircle size={20} />
              </Text>
              <Text>{t('users.invites.tabs.activated')}</Text>
              <Text>{`(${activeCount})`}</Text>
            </Box>
          }
        >
          <Table<User>
            key="active"
            actions={[
              {
                action: 'edit',
                onClick: setUserToEdit,
              },
            ]}
            columns={tableColumns}
            getRowKey={({ id }) => `${id}`}
            items={activeUsers}
            itemRenderFunctions={itemRenderFunctions}
            searchableColumns={searchableColumns}
          />
        </Tab>

        <Tab
          title={
            <Box direction="row" gap="0.5rem">
              <Text color="status-warning">
                <HelpCircle size={20} />
              </Text>
              <Text>{t('users.invites.tabs.pending')}</Text>
              <Text>{`(${inactiveCount})`}</Text>
            </Box>
          }
        >
          <Table<User>
            key="inactive"
            actions={[
              {
                action: 'edit',
                onClick: setUserToEdit,
              },
              {
                action: 'delete',
                onClick: (u) => setDeleteInvites([u]),
              },
            ]}
            checkOption={{
              onClick: addToDeleteList,
              isChecked: (u) => deleteList.includes(u),
              header: (
                <Button
                  onClick={() => {
                    if (deleteList.length) setDeleteInvites(deleteList)
                  }}
                  color="status-error"
                  primary
                >
                  <Box pad={{ horizontal: 'small' }}>
                    <Text size="small">{t('common.delete')}</Text>
                  </Box>
                </Button>
              ),
            }}
            columns={tableColumns}
            getRowKey={({ id }) => `${id}`}
            items={pendingInvites}
            itemRenderFunctions={itemRenderFunctions}
            searchableColumns={searchableColumns}
          />

          {invitesToDelete && (
            <Modal onClick={() => setDeleteInvites(undefined)}>
              <Box align="center" gap="1rem">
                <Text textAlign="center">
                  {t('users.invites.confirmDelete', {
                    count: invitesToDelete.length,
                  })}
                </Text>

                {invitesToDelete.map((invite) => (
                  <Box key={invite.email}>
                    <Text textAlign="center" size="small" weight={600}>
                      {invite.name}
                    </Text>
                    <Text textAlign="center" size="small">
                      {invite.email}
                    </Text>
                  </Box>
                ))}

                <Button
                  onClick={handleMultiDelete}
                  color="status-error"
                  label={t('common.delete')}
                  primary
                />
              </Box>
            </Modal>
          )}
        </Tab>
      </Tabs>

      {userToEdit && (
        <EditUser
          user={userToEdit}
          closeModal={() => setUserToEdit(undefined)}
        />
      )}
    </>
  )
}

export default InvitesList
