import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, Link, navigate } from '@reach/router'
import { toast } from 'react-toastify'
import { Box, Heading, Text } from 'grommet'
import Input from 'components/Input'
import Form from 'components/Form'
import LoggedOutLayout from 'components/LoggedOutLayout'
import requestPasswordReset from 'api/auth/passwordReset'

const RequestPasswordResetPage = (_: RouteComponentProps) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  return (
    <LoggedOutLayout>
      <Heading level="2">{t(`auth.title.forgot`)}</Heading>

      <Form
        submitButtonLabel={t(`auth.forgot`)}
        onSubmit={async () => {
          setLoading(true)
          toast(t('auth.requestingPasswordReset'), { autoClose: false })

          const { error } = await requestPasswordReset({ email })
          setLoading(false)
          toast.dismiss()

          if (error) {
            toast.error(error)
            return
          }

          navigate('/')
          toast.success(t('auth.sentPasswordReset'))
        }}
        disabled={loading}
      >
        <Input
          value={email}
          id="email"
          label="common.email"
          onChange={setEmail}
          type="email"
          autoFocus
          required
        />
      </Form>

      <Box gap="0.5rem" margin={{ top: 'medium' }} align="start">
        <Link to="/">
          <Text size="small">{t(`auth.option.login`)}</Text>
        </Link>
      </Box>
    </LoggedOutLayout>
  )
}

export default RequestPasswordResetPage
