import React from 'react'
import { Link as ReachLink, LinkProps as ReachLinkProps } from '@reach/router'
import ExternalLink from './ExternalLink'

const Link: React.FC<LinkProps> = (props) => {
  const { to, children, className, onClick } = props

  if (/^(https?|mailto):/.test(to)) {
    return (
      <ExternalLink className={className} href={to}>
        {children}
      </ExternalLink>
    )
  }

  return (
    <ReachLink className={className} to={to} onClick={onClick}>
      {children}
    </ReachLink>
  )
}

interface LinkProps extends ReachLinkProps<undefined> {
  to: string
}

export default Link
