import React from 'react'
import { toast, ToastContainer, Slide } from 'react-toastify'
import { X as Close } from 'react-feather'

const Toast = () => (
  <ToastContainer
    draggable={false}
    position={toast.POSITION.TOP_RIGHT}
    transition={Slide}
    autoClose={3000}
    closeButton={<Close color="white" size="20px" />}
  />
)

export default Toast
