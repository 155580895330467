import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

interface UpsertSocialArgs {
  id: number
  social: SocialMediaLinkData
}

/**
 * Handles creating, updating and deleting social media info related to an
 * entity. If the `social` data does not contain an `id`, new social media
 * info is created. If `social.url` is an empty string, the info is deleted.
 * Otherwise, the info is updated
 */
const upsertEntitySocialNetwork = ({ id, social }: UpsertSocialArgs) => {
  if (!social.id) {
    return axios.post<SocialMediaLinkData>(`/entities/${id}/social/`, social)
  }

  const url = `/entities/${id}/social/${social.id}/`

  return !social.url
    ? axios.delete<undefined>(url)
    : axios.put<SocialMediaLinkData>(url, social)
}

export default wrapApiCall(upsertEntitySocialNetwork)
