import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Save } from 'react-feather'
import { Box, Heading, Text } from 'grommet'
import { useInfoList } from 'context/InfoContext'
import Modal from 'components/Modal'
import Form from 'components/Form'
import Input from 'components/Input'
import EditorHelp from 'components/EditorHelp'
import ImageFileInput from 'components/FileInput/ImageFileInput'
import { Editor } from 'react-draft-wysiwyg'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

interface EditInfoProps {
  info: Info
  closeModal: () => void
}

const EditInfo: React.FC<EditInfoProps> = ({ info, closeModal }) => {
  const rawHtml = (description: string) => {
    const contentBlock = htmlToDraft(description)
    return ContentState.createFromBlockArray(contentBlock.contentBlocks)
  }

  const { t } = useTranslation()
  const { updateInfo } = useInfoList()
  const [loading, setLoading] = useState(false)

  // Form-field data
  const [title, setTitle] = useState(info.title)
  const [description, setDescription] = useState(info.description)
  const version = info.version + 1
  const [image, setImage] = useState<File | null>(null)

  const [editorState, setState] = useState(
    EditorState.createWithContent(rawHtml(description)),
  )

  const handleClose = () => {
    // Don't allow the user to close the modal while it's saving details
    if (loading) return
    closeModal()
  }

  const handleSubmit = async () => {
    const infoData: InfoUpdateData = {
      ...info,
      title,
      description,
      version,
      image: image || undefined,
      id: 1, //info is always 1
    }

    infoData.description = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    )

    setLoading(true)

    const success = await updateInfo(infoData)
    setLoading(false)

    if (!success) return
    toast.success(t('common.saved'))
    closeModal()
  }

  return (
    <Modal onClick={handleClose}>
      <Box width="xlarge">
        <Heading level="3">{t('info.editInfo.title')}</Heading>

        <Form
          onCancel={handleClose}
          onSubmit={handleSubmit}
          submitButtonIcon={<Save size={16} />}
          submitButtonLabel={t('common.save')}
          disabled={loading}
        >
          <Input
            label="info.title"
            value={title}
            onChange={setTitle}
            required
            autoFocus
          />
          <EditorHelp />
          <Editor
            editorState={editorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onEditorStateChange={setState}
            ariaLabel="aASDSADSADASDAS"
          />
          <ImageFileInput
            help={
              info.image && (
                <Text size="small">
                  {`${t('forms.fileInput.current')}: ${info.image}`}
                </Text>
              )
            }
            file={image}
            onChange={setImage}
          />
        </Form>
      </Box>
    </Modal>
  )
}

export default EditInfo
