import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { Box, Button, Text } from 'grommet'
import { formatDate } from 'utils/helpers'
import { useLiteraryEventsList } from 'context/LiteraryEventsContext'
import Modal from 'components/Modal'
import Table from 'components/Table'
import EditLiteraryEvent from './EditLiteraryEvent'

const LiteraryEventsList = () => {
  const { t } = useTranslation()
  const {
    literaryEvents,
    getLiteraryEvents,
    deleteLiteraryEvent,
  } = useLiteraryEventsList()
  const [entityToEdit, setLiteraryEventToEdit] = useState<LiteraryEvent>()
  const [entityToDelete, setLiteraryEventToDelete] = useState<LiteraryEvent>()

  useEffect(() => {
    getLiteraryEvents()
  }, [getLiteraryEvents])

  const handleDeleteClick = async () => {
    if (!entityToDelete) return

    const success = await deleteLiteraryEvent(entityToDelete)

    if (success) {
      setLiteraryEventToDelete(undefined)
      toast.success(t('literaryEvents.delete.success'))
    }
  }

  return (
    <>
      <Table<LiteraryEvent>
        actions={[
          { action: 'edit', onClick: setLiteraryEventToEdit },
          { action: 'delete', onClick: setLiteraryEventToDelete },
        ]}
        columns={[
          {
            label: 'common.name',
            propKey: 'name',
          },
          {
            label: 'literaryEvent.period',
            propKey: 'start_date',
          },
          {
            label: 'literaryEvent.target_audience',
            propKey: 'target_audience',
          },
          {
            label: 'literaryEvent.place',
            propKey: 'place',
          },
          {
            label: 'literaryEvent.programming',
            propKey: 'programming',
          },
        ]}
        getRowKey={({ id }) => `${id}`}
        items={literaryEvents}
        itemRenderFunctions={{
          name: ({ name, more_info: info }) => {
            return (
              <>
                <Text size="small" weight={600}>
                  {name}
                </Text>
                <Text size="xsmall">
                  {info ? _.truncate(info, { length: 50 }) : '---'}
                </Text>
              </>
            )
          },
          target_audience: ({ target_audience: audience }) => {
            return (
              <Text size="small">
                {audience ? t(`literaryEvents.audiences.${audience}`) : '---'}
              </Text>
            )
          },
          start_date: ({ start_date: startDate, end_date: endDate }) => {
            const [start, end] = [startDate, endDate].map((d) => formatDate(d))
            return (
              <>
                <Text size="xsmall">{`${t('common.start')}: ${start}`}</Text>
                <Text size="xsmall">{`${t('common.end')}: ${end}`}</Text>
              </>
            )
          },
        }}
        searchableColumns={['name']}
      />

      {entityToEdit && (
        <EditLiteraryEvent
          literaryEvent={entityToEdit}
          closeModal={() => setLiteraryEventToEdit(undefined)}
        />
      )}

      {entityToDelete && (
        <Modal onClick={() => setLiteraryEventToDelete(undefined)}>
          <Box align="center" gap="1rem">
            <Text textAlign="center">{t('literaryEvents.delete.confirm')}</Text>
            <Text textAlign="center" size="small" weight={600}>
              {entityToDelete.name}
            </Text>

            <Button
              onClick={handleDeleteClick}
              color="status-error"
              label={t('common.delete')}
              primary
            />
          </Box>
        </Modal>
      )}
    </>
  )
}

export default LiteraryEventsList
