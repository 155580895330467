import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Input from 'components/Input'
import { useProfileContext } from 'context/ProfileContext'
import { AlertTriangle } from 'react-feather'
import { Heading, Box, Button } from 'grommet'
import Modal from 'components/Modal'

const DeactivateProfile = () => {
  const { t } = useTranslation()
  const { deactivateProfile } = useProfileContext()

  const [showModal, setModal] = useState(false)
  const [reason, setReason] = useState('')

  useEffect(() => {
    if (!showModal) setReason('')
  }, [showModal])

  const handleSubmit = async () => {
    const { error } = await deactivateProfile(reason)
    if (error) toast.error(error)
  }

  return (
    <Box
      width="medium"
      pad="medium"
      elevation="small"
      round="xsmall"
      margin="2rem auto"
      background="white"
    >
      <Heading level="3">{t('users.deactivateProfile.title')}</Heading>
      <Button
        icon={<AlertTriangle />}
        label={t('users.deactivateProfile.button')}
        onClick={() => setModal(true)}
        color="status-error"
        primary
      />

      {showModal && (
        <Modal onClick={() => setModal(false)}>
          <Heading level="4">{t('users.deactivateProfile.title')}</Heading>
          <Input
            label="users.deactivateProfile.reason"
            value={reason}
            onChange={setReason}
            maxLength={100}
          />
          <Button
            icon={<AlertTriangle />}
            label={t('users.deactivateProfile.button')}
            onClick={handleSubmit}
            color="status-error"
            primary
          />
        </Modal>
      )}
    </Box>
  )
}

export default DeactivateProfile
