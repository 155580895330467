import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, navigate, Redirect } from '@reach/router'
import { toast } from 'react-toastify'
import { Heading, Text } from 'grommet'
import activateUser from 'api/auth/activate'
import Input from 'components/Input'
import Form from 'components/Form'
import LoggedOutLayout from 'components/LoggedOutLayout'
import {
  validatePassword,
  validatePasswordConfirmation,
} from 'utils/validation/users'

type PageComponent = React.FC<
  RouteComponentProps<{ token: string; uuid: string }>
>

const ActivateUserPage: PageComponent = ({ token, uuid }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConf, setPasswordConfirmation] = useState('')

  if (!token || !uuid) return <Redirect path="/" />

  const handleSubmit = async () => {
    const validationError =
      validatePassword(password) ||
      validatePasswordConfirmation(password, passwordConf)

    if (validationError) {
      toast.error(validationError)
      return
    }

    setLoading(true)
    toast(t('auth.activatingUser'), { autoClose: false })

    const { error } = await activateUser({ password, token, uuid })
    setLoading(false)
    toast.dismiss()

    if (error) {
      toast.error(error)
      return
    }

    toast.success(t('auth.userActivated'))
    navigate('/')
  }

  return (
    <LoggedOutLayout>
      <Heading level="2">{t(`auth.title.activateUser`)}</Heading>
      <Text size="small" margin={{ bottom: 'small' }}>
        {t('auth.validation.passwordMustBeComplicated')}
      </Text>

      <Form
        submitButtonLabel={t(`common.save`)}
        onSubmit={handleSubmit}
        disabled={loading}
      >
        <Input
          value={password}
          id="password"
          label="auth.password"
          onChange={setPassword}
          type="password"
          required
        />

        <Input
          value={passwordConf}
          id="passwordConf"
          label="auth.passwordConf"
          onChange={setPasswordConfirmation}
          type="password"
          required
        />
      </Form>
    </LoggedOutLayout>
  )
}

export default ActivateUserPage
