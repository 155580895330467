import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import LOCATION_DATA from 'utils/constants/locationData'
import Dropdown from './Dropdown'

function toOptionsArray(
  key: 'zone' | 'district' | 'county',
  items?: LocationItem[],
): DropdownOption<string>[] {
  if (!items) return []

  const uniqueOptions = _.uniqBy(items, key).map((item) => item[key])
  return _.sortBy(uniqueOptions, _.deburr).map((item) => ({
    label: item,
    value: item,
  }))
}

const zoneOptions = toOptionsArray('zone', LOCATION_DATA)

interface LocationData {
  district?: string
  county?: string
  zone?: string
}

interface LocationDropdownsProps {
  zone?: string
  district?: string
  county?: string
  onChange: (_: LocationData) => void
  required?: boolean
}

const LocationDropdowns: React.FC<LocationDropdownsProps> = ({
  onChange,
  required = true,
  ...data
}) => {
  const [zone, setZone] = useState(data.zone)
  const [district, setDistrict] = useState(data.district)
  const [county, setCounty] = useState(data.county)

  const districtOptions = useMemo(() => {
    return toOptionsArray('district', _.filter(LOCATION_DATA, { zone }))
  }, [zone])

  const countyOptions = useMemo(() => {
    return toOptionsArray('county', _.filter(LOCATION_DATA, { zone, district }))
  }, [zone, district])

  // When local state changes, call the onChange prop
  useEffect(() => {
    onChange({ zone, district, county })
  }, [zone, district, county, onChange])

  // If only one district is available, select it immediately
  useEffect(() => {
    if (districtOptions.length === 1 && districtOptions[0].value !== district) {
      setDistrict(districtOptions[0].value)
    }
  }, [district, districtOptions])

  // If only one county is available, select it immediately
  useEffect(() => {
    if (countyOptions.length === 1 && countyOptions[0].value !== county) {
      setCounty(countyOptions[0].value)
    }
  }, [county, countyOptions])

  return (
    <>
      <Dropdown<string>
        translateLabels={false}
        label="common.zone"
        value={zoneOptions.find(({ value }) => value === zone)}
        options={zoneOptions}
        onChange={(value) => {
          if (value === zone) return

          setZone(value)
          setDistrict(undefined)
          setCounty(undefined)
        }}
        required={required}
      />

      <Dropdown<string>
        label="common.district"
        value={districtOptions.find(({ value }) => value === district)}
        options={districtOptions}
        onChange={(value) => {
          if (value === district) return

          setDistrict(value)
          setCounty(undefined)
        }}
        help={zone ? undefined : 'common.selectZoneFirst'}
        translateLabels={false}
        required={required}
      />

      <Dropdown<string>
        label="common.county"
        value={countyOptions.find(({ value }) => value === county)}
        options={countyOptions}
        onChange={setCounty}
        help={district ? undefined : 'common.selectDistrictFirst'}
        translateLabels={false}
        required={required}
      />
    </>
  )
}

export default LocationDropdowns
