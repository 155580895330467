import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Save } from 'react-feather'
import { Box, Heading, Text } from 'grommet'
import {
  validateStartAndEndDate,
  validateCoordinates,
  validateURL,
} from 'utils/validation/common'
import { useFestivalsList } from 'context/FestivalsContext'
import Modal from 'components/Modal'
import Form from 'components/Form'
import Input from 'components/Input'
import DateInput from 'components/DateInput'
import TextArea from 'components/TextArea'
import LocationDropdowns from 'components/LocationDropdowns'
import SocialMediaFields from 'components/SocialMediaFields'
import ImageFileInput from 'components/FileInput/ImageFileInput'

interface EditFestivalProps {
  festival: Festival
  closeModal: () => void
}

const EditFestival: React.FC<EditFestivalProps> = ({
  festival,
  closeModal,
}) => {
  const { t } = useTranslation()
  const { updateFestival } = useFestivalsList()
  const [loading, setLoading] = useState(false)

  // Form-field data
  const [name, setName] = useState(festival.name || '')
  const [startDate, setStartDate] = useState(festival.start_date || '')
  const [endDate, setEndDate] = useState(festival.end_date || '')
  const [district, setDistrict] = useState(festival.district || '')
  const [county, setCounty] = useState(festival.county || '')
  const [zone, setZone] = useState(festival.zone || '')
  const [latitude, setLatitude] = useState(`${festival.latitude}` || '')
  const [longitude, setLongitude] = useState(`${festival.longitude}` || '')
  const [programming, setProgramming] = useState(festival.programming || '')
  const [description, setDescription] = useState(festival.description || '')
  const [address, setAddress] = useState(festival.address || '')
  const [addressNumber, setAddressNumber] = useState(
    festival.address_number || '',
  )
  const [postalCode, setPostalCode] = useState(festival.postal_code || '')
  const [image, setImage] = useState<File>()
  const [social, setSocial] = useState<SocialMediaLinkData[]>(
    festival.social || [],
  )

  const handleClose = () => {
    // Don't allow the user to close the modal while it's saving details
    if (loading) return
    closeModal()
  }

  const handleSubmit = async () => {
    const error =
      validateStartAndEndDate(startDate, endDate) ||
      validateCoordinates(latitude, longitude) ||
      validateURL(programming, 'festival.programming') ||
      social
        .map((s) => validateURL(s.url, `social.${s.name}`))
        .filter(Boolean)[0]

    if (error) {
      toast.error(error)
      return
    }

    const entityData: FestivalUpdateData = {
      ...festival,
      name,
      description: description || undefined,
      start_date: startDate,
      end_date: endDate,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      programming: programming,
      address,
      address_number: addressNumber,
      postal_code: postalCode,
      district,
      county,
      zone,
      image: image || undefined,
      social,
    }

    if (error) {
      toast.error(error)
      return
    }

    setLoading(true)
    const success = await updateFestival(entityData)
    setLoading(false)

    if (success) {
      toast.success(t('festivals.editFestival.updated'))
      closeModal()
    }
  }

  return (
    <Modal onClick={handleClose}>
      <Box width="medium">
        <Heading level="3">{t('festivals.editFestival.title')}</Heading>

        <Form
          onCancel={handleClose}
          onSubmit={handleSubmit}
          submitButtonIcon={<Save size={16} />}
          submitButtonLabel={t('common.save')}
          disabled={loading}
        >
          <Input
            label="common.name"
            value={name}
            onChange={setName}
            maxLength={40}
            required
            autoFocus
          />

          <TextArea
            label="common.description"
            value={description}
            onChange={setDescription}
            maxLength={300}
          />

          <DateInput
            label="festival.start_date"
            value={startDate}
            onChange={setStartDate}
            required
            autoFocus
          />

          <DateInput
            label="festival.end_date"
            value={endDate}
            onChange={setEndDate}
            required
            autoFocus
          />

          <Input
            label="festival.programming"
            value={programming}
            onChange={setProgramming}
            required
            trimValue
          />

          <Input
            label="common.address"
            value={address}
            onChange={setAddress}
            required
          />

          <Input
            label="common.address_number"
            value={addressNumber}
            onChange={setAddressNumber}
            required
          />

          <Input
            label="common.postal_code"
            value={postalCode}
            onChange={setPostalCode}
            required
          />

          <LocationDropdowns
            zone={zone}
            district={district}
            county={county}
            onChange={(data) => {
              setZone(data.zone || '')
              setDistrict(data.district || '')
              setCounty(data.county || '')
            }}
          />

          <Input
            label="common.latitude"
            value={latitude}
            onChange={setLatitude}
            required
            trimValue
            type="tel"
          />

          <Input
            label="common.longitude"
            value={longitude}
            onChange={setLongitude}
            required
            trimValue
            type="tel"
          />

          <ImageFileInput
            file={image}
            onChange={setImage}
            help={
              festival.image && (
                <Text size="small">
                  {`${t('forms.fileInput.current')}: ${festival.image}`}
                </Text>
              )
            }
          />

          <SocialMediaFields linkData={social} onChange={setSocial} />
        </Form>
      </Box>
    </Modal>
  )
}

export default EditFestival
