import * as Sentry from '@sentry/browser'
import { IS_LOCALHOST, IS_PRODUCTION } from 'utils/constants'

if (!IS_LOCALHOST) {
  // Sentry config
  Sentry.init({
    dsn: 'https://d832b2c0d057498990e746219639bdd0@sentry.io/1479170',
    environment: IS_PRODUCTION ? 'production' : 'dev',
    ignoreErrors: [
      'ResizeObserver',
      "Object doesn't support property or method 'find'",
      // Errors caused by deploying the site while people are using it
      "Unexpected token '<'", // Safari
      'Unexpected token <',
      "expected expression, got '<'",
    ],
  })
}

export function reportErrorToSentry(e: any) {
  if (IS_LOCALHOST) return
  Sentry.captureException(e)
}
