import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

interface UpdateEventArgs {
  event: LiteraryEventUpdateData
  entityId: number
}

/**
 * Sends updated event data to the backend to be saved in the database.
 * Returns a fresh copy of the event
 */
const updateLiteraryEvent = async ({ event, entityId }: UpdateEventArgs) => {
  const { id, image, ...data } = event
  const url = `/entities/${entityId}/events/${id}/`

  if (image && typeof image !== 'string') {
    const body = new FormData()
    body.set('image', image)

    await axios.patch(url, body)
  }

  return axios.patch<LiteraryEvent>(url, data)
}

export default wrapApiCall(updateLiteraryEvent)
