import { format } from 'date-fns'
import _ from 'lodash'
import { toast, Zoom } from 'react-toastify'
import i18n from 'i18n'

/**
 * Returns a function that gets a color from the Grommet theme object.
 * Only for use in styled components
 */
export const getThemeColor = (path: string) => (props: any) => {
  return _.get(props.theme.global.colors, path) as string | undefined
}

/**
 * Creates a toast to tell the user something is loading.
 * Returns the dismiss function so it can be cleared after
 * loading has finished
 * @param message Optional loading message
 */
export function loadingMessage(message = 'common.loading') {
  const toastId = toast(i18n.t(message), {
    autoClose: false,
    position: toast.POSITION.BOTTOM_RIGHT,
    transition: Zoom,
  })
  return () => toast.dismiss(toastId)
}

/**
 * Converts an ISO date string to a formatted date. If the hour is set to "00",
 * it is changed to "12" to get around locale-based time-formatting issues
 */
export function formatDate(date: string) {
  const d = date.replace('T00:', 'T12:')
  return format(new Date(d), 'dd/MM/yyyy')
}
