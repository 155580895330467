import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

/**
 * Calls the backend to get a list of festivals
 */
const getFestivalsList = () => {
  return axios.get<ApiListResponse<Festival>>(`/festivals/`)
}

export default wrapApiCall(getFestivalsList, { showLoadingMessage: true })
