import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Save } from 'react-feather'
import { Box, Heading, Text } from 'grommet'
import { validateStartAndEndDate, validateURL } from 'utils/validation/common'
import { useLiteraryEventsList } from 'context/LiteraryEventsContext'
import Modal from 'components/Modal'
import Form from 'components/Form'
import Input from 'components/Input'
import DateInput from 'components/DateInput'
import Dropdown from 'components/Dropdown'
import TextArea from 'components/TextArea'
import { AUDIENCE_OPTIONS } from './constants'
import ImageFileInput from 'components/FileInput/ImageFileInput'

interface EditLiteraryEventProps {
  literaryEvent: LiteraryEvent
  closeModal: () => void
}

const EditLiteraryEvent: React.FC<EditLiteraryEventProps> = ({
  literaryEvent,
  closeModal,
}) => {
  const { t } = useTranslation()
  const { updateLiteraryEvent } = useLiteraryEventsList()
  const [loading, setLoading] = useState(false)

  // Form-field data
  const [name, setName] = useState(literaryEvent.name || '')
  const [startDate, setStartDate] = useState(literaryEvent.start_date || '')
  const [endDate, setEndDate] = useState(literaryEvent.end_date || '')
  const [targetAudience, setAudience] = useState(literaryEvent.target_audience)
  const [programming, setProgramming] = useState(literaryEvent.programming)
  const [url, setUrl] = useState(literaryEvent.url || '')
  const [place, setPlace] = useState(literaryEvent.place)
  const [moreInfo, setMoreInfo] = useState(literaryEvent.more_info)
  const [image, setImage] = useState<File>()

  const handleClose = () => {
    // Don't allow the user to close the modal while it's saving details
    if (loading) return
    closeModal()
  }

  const handleSubmit = async () => {
    const error =
      validateStartAndEndDate(startDate, endDate) ||
      validateURL(url, 'literaryEvent.url')

    if (error) {
      toast.error(error)
      return
    }

    const entityData: LiteraryEventUpdateData = {
      ...literaryEvent,
      name,
      start_date: startDate,
      end_date: endDate,
      programming,
      image: image || undefined,
      place,
      more_info: moreInfo,
      target_audience: targetAudience,
      url: url || undefined,
    }

    setLoading(true)
    const success = await updateLiteraryEvent(entityData)
    setLoading(false)

    if (success) {
      toast.success(t('literaryEvents.editLiteraryEvent.updated'))
      closeModal()
    }
  }

  return (
    <Modal onClick={handleClose}>
      <Box width="medium">
        <Heading level="3">
          {t('literaryEvents.editLiteraryEvent.title')}
        </Heading>

        <Form
          onCancel={handleClose}
          onSubmit={handleSubmit}
          submitButtonIcon={<Save size={16} />}
          submitButtonLabel={t('common.save')}
          disabled={loading}
        >
          <ImageFileInput
            file={image}
            onChange={setImage}
            help={
              literaryEvent.image && (
                <Text size="small">
                  {`${t('forms.fileInput.current')}: ${literaryEvent.image}`}
                </Text>
              )
            }
          />

          <Input
            label="common.name"
            value={name}
            onChange={setName}
            maxLength={50}
            required
          />

          <TextArea
            label="literaryEvent.more_info"
            value={moreInfo || ''}
            onChange={setMoreInfo}
            maxLength={300}
          />

          <Dropdown<LiteraryEventAudience>
            label="literaryEvent.target_audience"
            value={AUDIENCE_OPTIONS.find(
              (option) => option.value === targetAudience,
            )}
            options={AUDIENCE_OPTIONS}
            onChange={setAudience}
          />

          <Input
            label="literaryEvent.place"
            value={place}
            onChange={setPlace}
            maxLength={100}
            required
          />

          <DateInput
            label="literaryEvent.start_date"
            value={startDate}
            onChange={setStartDate}
            required
          />

          <DateInput
            label="literaryEvent.end_date"
            value={endDate}
            onChange={setEndDate}
            required
          />

          <TextArea
            label="literaryEvent.programming"
            value={programming}
            onChange={setProgramming}
            maxLength={300}
            required
          />

          <Input
            label="literaryEvent.url"
            value={url}
            onChange={setUrl}
            maxLength={220}
          />
        </Form>
      </Box>
    </Modal>
  )
}

export default EditLiteraryEvent
