import React from 'react'
import { Box } from 'grommet'
import {
  ChevronLeft,
  ChevronsLeft,
  ChevronRight,
  ChevronsRight,
} from 'react-feather'
import { getPageNumbers } from './utils'
import ArrowButton from './ArrowButton'
import { NumberButton } from './styled'

interface PaginationProps {
  page: number
  lastPage: number
  setPage: (_: number) => void
}

const Pagination: React.FC<PaginationProps> = ({ page, lastPage, setPage }) => {
  return (
    <Box align="center" pad="medium" gap="medium">
      <Box direction="row" align="center" justify="center" gap="small" wrap>
        <ArrowButton
          onClick={() => setPage(0)}
          icon={<ChevronsLeft size={20} />}
        />
        <ArrowButton
          onClick={() => {
            if (page <= 1) return
            setPage(page - 1)
          }}
          icon={<ChevronLeft size={20} />}
        />

        {getPageNumbers(page, lastPage).map((n) => (
          <NumberButton
            key={n}
            onClick={() => setPage(n)}
            active={n === page}
            type="button"
          >
            {n + 1}
          </NumberButton>
        ))}

        <ArrowButton
          onClick={() => {
            if (page >= lastPage) return
            setPage(page + 1)
          }}
          icon={<ChevronRight size={20} />}
        />
        <ArrowButton
          onClick={() => setPage(lastPage)}
          icon={<ChevronsRight size={20} />}
        />
      </Box>
    </Box>
  )
}

export default Pagination
