import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

/**
 * Calls the backend to get a list of authors
 */
const getAuthorsList = () => {
  return axios.get<ApiListResponse<Author>>(`/authors/`)
}

export default wrapApiCall(getAuthorsList, { showLoadingMessage: true })
