import React from 'react'
import { FormFieldProps } from 'grommet'
import FileInput from '.'
import { IMAGE_FILE_TYPES } from 'utils/constants'
import { validateImageFile } from 'utils/validation/common'
import { toast } from 'react-toastify'

interface ImageFileInputProps extends FormFieldProps {
  onChange: (_: File) => void
  file?: File | null
  icon?: React.ReactNode
  color?: string
  required?: boolean
}

const ImageFileInput: React.FC<ImageFileInputProps> = ({
  file,
  onChange,
  ...rest
}) => {
  return (
    <FileInput
      {...rest}
      file={file}
      fileTypes={IMAGE_FILE_TYPES}
      label="common.image"
      onChange={(e) => {
        const image = e.target.files && e.target.files[0]
        if (!image) return

        const imageError = validateImageFile(image)
        if (imageError) {
          toast.error(imageError)
          return
        }

        onChange(image)
      }}
    />
  )
}

export default ImageFileInput
