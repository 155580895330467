import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Home } from 'react-feather'
import PageHeading from 'components/PageHeading'
import EntitiesList from './EntitiesList'
import NewEntity from './NewEntity'

const EntitiesIndex: React.FC<RouteComponentProps> = () => {
  const [isNewEntityModalOpen, setNewEntityModal] = useState(false)

  return (
    <>
      <PageHeading
        title="entities.list.title"
        button={{
          icon: <Home color="white" size="20px" />,
          label: 'entities.newEntity.create',
          onClick: () => setNewEntityModal(true),
        }}
      />

      <EntitiesList />
      {isNewEntityModalOpen && (
        <NewEntity closeModal={() => setNewEntityModal(false)} />
      )}
    </>
  )
}

export default EntitiesIndex
