import 'formdata-polyfill'
import { differenceInDays } from 'date-fns'
import axios from 'axios'
import { BACKEND_URL } from 'utils/constants'

axios.defaults.baseURL = BACKEND_URL

/**
 * Sets the user's auth token in the `axios` config and in `localStorage`.
 * If no token is passed, the info is removed from both `axios` and `localStorage`.
 */
export const setAxiosToken = (t?: string) => {
  if (t) {
    localStorage.setItem('t', t)
  } else {
    localStorage.removeItem('e')
    localStorage.removeItem('t')
  }

  const token = t ? `Bearer ${t}` : undefined
  axios.defaults.headers.common['Authorization'] = token
}

/**
 * This function is called once when the application starts. It checks
 * `localStorage` for previous session data. If the previous token is
 * less than 30 days old, it is added to the `axios` config. Otherwise,
 * the token and token expiry information is removed from `localStorage`
 */
function axiosInit() {
  const lastLogin = localStorage.getItem('e')
  const storedToken = localStorage.getItem('t')

  if (
    storedToken &&
    lastLogin &&
    differenceInDays(new Date(lastLogin), new Date()) < 30
  ) {
    setAxiosToken(storedToken)
  } else {
    localStorage.removeItem('e')
    localStorage.removeItem('t')
  }
}

axiosInit()
