import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Save, AlertTriangle } from 'react-feather'
import { Box, Heading, Button } from 'grommet'
import { useUsersList } from 'context/UsersContext'
import Modal from 'components/Modal'
import Form from 'components/Form'
import Input from 'components/Input'
import Dropdown from 'components/Dropdown'
import { toast } from 'react-toastify'
import { useEntitiesList } from 'context/EntitiesContext'
import {
  validateEmail,
  validateEntityId,
  validateProfile,
} from 'utils/validation/users'
import { PROFILE_OPTIONS } from './constants'

interface EditUserProps {
  user: User
  closeModal: () => void
}

const EditUser: React.FC<EditUserProps> = ({ user, closeModal }) => {
  const { t } = useTranslation()
  const { updateUserData, deleteUser } = useUsersList()
  const { entitiesOptions } = useEntitiesList()

  const [userEntity, userProfile] = useMemo(() => {
    const entityOption = entitiesOptions.find(({ value }) => {
      return user.entity && user.entity.id === value.id
    })
    const isAdmin = user.is_superuser || !user.entity
    const profileOption = isAdmin ? 'admin' : 'entity'

    return [entityOption && entityOption.value, profileOption as ProfileType]
  }, [user, entitiesOptions])

  const [showDelete, setShowDelete] = useState(false)
  const [reason, setReason] = useState('')
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(user.name || '')
  const [email, setEmail] = useState(user.email || '')
  const [phone, setPhone] = useState(user.phone || '')
  const [entity, setEntity] = useState<Entity | undefined>(userEntity)
  const [profile, setProfile] = useState<ProfileType | undefined>(userProfile)

  const handleClose = () => {
    // Don't allow the user to close the modal while it's saving details
    if (loading) return
    closeModal()
  }

  const handleSubmit = async () => {
    const userEntity = entity
      ? {
          id: entity.id,
          name: entity.name,
          entity_type: entity.entity_type,
        }
      : null
    const userData: User = {
      ...user,
      name,
      email,
      phone: phone || null,
      entity: userEntity,
      is_superuser: !userEntity,
    }

    const error =
      validateEmail(email) ||
      validateProfile(profile) ||
      validateEntityId(profile, userEntity && userEntity.id)

    if (error) {
      toast.error(error)
      return
    }

    setLoading(true)

    const success = await updateUserData(userData)
    setLoading(false)

    if (success) closeModal()
  }

  const handleDelete = async () => {
    setLoading(true)
    const success = await deleteUser(user, reason)
    setLoading(false)
    if (success) closeModal()
  }

  return (
    <Modal onClick={handleClose}>
      <Box width="medium">
        <Heading level="3">{t('users.edit.title')}</Heading>

        <Form
          onSubmit={handleSubmit}
          onCancel={handleClose}
          submitButtonIcon={<Save size={16} />}
          submitButtonLabel={t('common.save')}
          disabled={loading}
        >
          <Input
            label="common.name"
            value={name}
            onChange={setName}
            required
            autoFocus
          />

          <Input
            label="common.email"
            value={email}
            onChange={setEmail}
            type="email"
            required
            trimValue
          />

          <Input
            label="common.phone"
            value={phone}
            onChange={(value) => setPhone(value.replace(/\D/g, ''))}
            type="tel"
            maxLength={10}
          />

          <Dropdown<ProfileType>
            label="common.profile"
            value={PROFILE_OPTIONS.find(({ value }) => value === profile)}
            options={PROFILE_OPTIONS}
            onChange={(value) => {
              if (entity) setEntity(undefined)
              setProfile(value)
            }}
          />

          {profile === 'entity' && (
            <Dropdown
              label="common.entity"
              value={
                entity &&
                entitiesOptions.find(({ value }) => value.id === entity.id)
              }
              options={entitiesOptions}
              onChange={setEntity}
              translateLabels={false}
            />
          )}
        </Form>

        <Box flex="grow" pad={{ vertical: 'medium' }}>
          <Button
            onClick={() => setShowDelete((v) => !v)}
            label={t('users.deactivateProfile.button')}
            icon={<AlertTriangle />}
            color="status-error"
            plain
          />
        </Box>

        {showDelete && (
          <Modal onClick={() => setShowDelete(false)}>
            <Form
              onSubmit={handleDelete}
              onCancel={() => setShowDelete(false)}
              submitButtonIcon={<AlertTriangle size={16} />}
              submitButtonLabel={t('users.deactivateProfile.confirm')}
              disabled={loading}
            >
              <Box width="medium">
                <Heading level="3">
                  {t('users.deactivateProfile.title')}
                </Heading>
                <Input
                  label="users.deactivateProfile.reason"
                  value={reason}
                  onChange={setReason}
                  maxLength={100}
                />
              </Box>
            </Form>
          </Modal>
        )}
      </Box>
    </Modal>
  )
}

export default EditUser
