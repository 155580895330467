import React from 'react'
import styled from 'styled-components'
import { FormField, TextInput } from 'grommet'
import { useTranslation } from 'react-i18next'
import { getThemeColor } from 'utils/helpers'

const StyledInput = styled(TextInput)`
  background-color: ${getThemeColor('light-1')};
  border-radius: 4px 4px 0 0;
`

interface CustomInputProps {
  id?: string
  label: string
  value?: number | string
  onChange: (_: string) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  placeholder?: string
  maxLength?: number
  type?: string
  autoFocus?: boolean
  disabled?: boolean
  required?: boolean
  translateLabel?: boolean
  trimValue?: boolean
}

function CustomInput({
  id,
  label,
  value,
  onChange,
  onBlur,
  placeholder = label,
  type,
  autoFocus,
  disabled,
  maxLength,
  required,
  translateLabel = true,
  trimValue,
}: CustomInputProps) {
  const { t } = useTranslation()

  let labelText = translateLabel ? t(label) : label
  if (required) labelText = `${labelText} *`

  return (
    <FormField key={label} label={labelText} htmlFor={id} onBlur={onBlur}>
      <StyledInput
        id={id}
        onChange={(e) => {
          const { value } = e.target
          onChange(trimValue || type === 'email' ? value.trim() : value)
        }}
        value={value || ''}
        placeholder={t(placeholder)}
        autoFocus={autoFocus}
        disabled={disabled}
        required={required}
        type={type}
        maxLength={maxLength}
      />
    </FormField>
  )
}

export default React.memo(CustomInput)
// export default CustomInput
