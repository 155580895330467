import {
  Users,
  Home,
  Star,
  PenTool,
  Settings,
  Calendar,
  Book,
  Info,
} from 'react-feather'

const NAV = [
  { to: '/users', text: 'nav.users', Icon: Users, adminOnly: true },
  { to: '/entities', text: 'nav.entities', Icon: Home, adminOnly: true },
  { to: '/festivals', text: 'nav.festivals', Icon: Star, adminOnly: true },
  { to: '/authors', text: 'nav.authors', Icon: PenTool, adminOnly: true },
  { to: '/info', text: 'nav.info', Icon: Info, adminOnly: true },
  { to: '/my-entity', text: 'nav.myEntity', Icon: Home, entityOnly: true },
  { to: '/events', text: 'nav.events', Icon: Calendar, entityOnly: true },
  { to: '/highlights', text: 'nav.highlights', Icon: Book, entityOnly: true },
  { to: '/settings', text: 'nav.settings', Icon: Settings },
]

export default NAV
