import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

/**
 * Calls the backend to get a list of events or one specific entity's
 * events, based on whether an entity ID is provided
 */
const getLiteraryEventsList = (id?: number) => {
  const url = id ? `/entities/${id}/events/` : '/events/'
  return axios.get<ApiListResponse<LiteraryEvent>>(url)
}

export default wrapApiCall(getLiteraryEventsList, { showLoadingMessage: true })
