import { createGlobalStyle } from 'styled-components'
import toastCss from 'components/Toast/css'

export default createGlobalStyle`
  body {
    position: relative;
    box-sizing: border-box;
    color: #222222;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
  }

  *,
  *:before,
  *:after,
  button,
  input,
  select {
    box-sizing: inherit;
    font-family: inherit;
  }

  * {
    transition: background-color 0.2s ease, color 0.2s ease;
  }

  a, button, *[role=button] {
    cursor: pointer;
  }

  *:disabled {
    cursor: not-allowed;
  }

  a, a:visited, a:active, a:link {
    color: ${({ theme }) => theme.global.colors.brandDark};
    text-decoration: none;
    font-weight: 600;
    transition: color 0.2s ease;

    &.highlight {
      background-image: linear-gradient(transparent 70%, ${({ theme }) =>
        theme.global.colors.brandFaded} 70%);
    }
  }

  button {
    border: 0;
    padding: 0;
    border-radius: 0;
  }

  blockquote {
    font-style: italic;
    border-left: 0.3rem solid ${({ theme }) => theme.global.colors.brand};
    padding: 1px 1rem;
    background-color: ${({ theme }) => theme.global.colors['light-2']};
    margin: 1rem auto;

    p {
      &::before {
        content: '“';
      }
      &::after {
        content: '”';
      }
    }
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  ${toastCss}
`
