import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Heading, ResponsiveContext } from 'grommet'
import { Menu, LogOut } from 'react-feather'
import ProfileContext from 'context/ProfileContext'
import Link from 'components/Link'

interface HeaderProps {
  toggleSidebar: () => void
}

const Header: React.FC<HeaderProps> = ({ toggleSidebar }) => {
  const { t } = useTranslation()
  const { logOut } = useContext(ProfileContext)
  const size = useContext(ResponsiveContext)

  return (
    <Box
      tag="header"
      direction="row"
      align="center"
      justify="between"
      background="brand"
      pad="xsmall"
      elevation="small"
      style={{ zIndex: 1 }}
    >
      <Box flex direction="row" align="center" gap="small">
        <Button icon={<Menu />} onClick={toggleSidebar} />
        <Link to="/">
          <Heading
            as="h1"
            level={size === 'small' ? '6' : '4'}
            margin="none"
            color="white"
          >
            {t(`header.title`)}
          </Heading>
        </Link>
      </Box>
      <Box direction="row" align="center" gap="xsmall">
        <Button
          title={t('header.logout')}
          a11yTitle={t('header.logout')}
          onClick={logOut}
          icon={<LogOut />}
        />
      </Box>
    </Box>
  )
}

export default Header
