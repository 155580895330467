import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

interface DeleteEventArgs {
  entityId: number
  eventId: number
}

/**
 * Calls the backend to delete an event based on the entity's ID and the event's ID
 */
const deleteLiteraryEventById = ({ eventId, entityId }: DeleteEventArgs) => {
  return axios.delete<undefined>(`/entities/${entityId}/events/${eventId}/`)
}

export default wrapApiCall(deleteLiteraryEventById)
