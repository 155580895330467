import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'
import { getEntityUrl } from './utils'

/**
 * Sends the new entity data to the backend and returns the created entity
 */
const createEntity = ({ entity_type: type, ...data }: NewEntityData) => {
  const { image, ...payload } = data
  return axios.post<Entity>(getEntityUrl(type), payload)
}

export default wrapApiCall(createEntity)
