import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

/**
 * Calls the backend to get a list of authors
 */
const getInfoList = () => {
  return axios.get<Info>(`/info/`)
}

export default wrapApiCall(getInfoList, { showLoadingMessage: true })
