import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'
import convertToFormData from 'api/convertToFormData'

interface NewHighlightArgs {
  highlight: HighlightFormData
  entityId: number
}

/**
 * Sends the new highlight data to the backend and returns the created highlight
 */
const createHighlight = ({ highlight, entityId }: NewHighlightArgs) => {
  const url = `/entities/${entityId}/highlights/`

  return axios.post<Highlight>(url, convertToFormData(highlight))
}

export default wrapApiCall(createHighlight)
