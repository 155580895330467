import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import _ from 'lodash'
import i18n from 'i18n'

/**
 * Safely handles getting error messages for failed API calls. Usually, errors
 * from the backend are objects, so they need to be converted to strings before
 * they can be displayed on the screen
 */
export function getErrorMessage(e: AxiosError): string {
  try {
    const errorData = _.get(e, 'response.data')

    if (typeof errorData === 'string') {
      /**
       * Sometimes the backend returns an HTML page for a 404, and we don't
       * want to show that on screen
       */
      if (/<!DOCTYPE html>/.test(errorData)) {
        return e.toString()
      }
      return errorData
    }

    if (!errorData) return e.toString()

    return Object.keys(errorData)
      .map((key) => {
        let errorValue: string | string[] = errorData[key]
        if (Array.isArray(errorValue)) {
          errorValue = errorValue[0]
        }

        return i18n.t(`error:${key}.${errorValue}`, `${key}: ${errorValue}`)
      })
      .join('.\n')
  } catch (err) {
    setTimeout(() => {
      toast.error(e.toString())
    }, 500)
    return i18n.t('error:generic')
  }
}
