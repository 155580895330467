import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

/**
 * Calls the backend to delete a festival based on ID
 */
const deleteFestivalById = (id: number) => {
  return axios.delete<undefined>(`/festivals/${id}/`)
}

export default wrapApiCall(deleteFestivalById)
