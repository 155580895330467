import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

/**
 * Get information about the user currently logged-in
 */
const getMyInfo = () => {
  return axios.get<User>(`/users/me`)
}

export default wrapApiCall(getMyInfo)
