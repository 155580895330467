import i18n from 'i18n'
import { EMAIL_REGEX } from '../constants'

/**
 * Checks that the user's email is valid
 */
export function validateEmail(email = '') {
  if (!EMAIL_REGEX.test(email)) {
    return i18n.t('auth.validation.emailInvalid')
  }

  return null
}

/**
 * Checks that the user has entered a password that:
 * - does not contain spaces
 * - is the right length
 * - only uses valid characters
 * - has letters, numbers and special characters
 */
export function validatePassword(password: string) {
  if (/\s/.test(password)) {
    return i18n.t('auth.validation.passwordHasSpaces')
  }

  if (password.length < 6) {
    return i18n.t('auth.validation.passwordWrongLength')
  }

  if (
    !(
      /[A-Z]/.test(password) && // Has at least one upper-case letter
      /[a-z]/.test(password) && // Has at least one lower-case letter
      /[0-9]/.test(password) && // Has at least one number
      // Has at least one special character
      /[#?!"£$€@%^&*~()[\]{}>?<`'|\\/+\-_]/.test(password)
    )
  ) {
    return i18n.t('auth.validation.passwordMustBeComplicated')
  }

  return null
}

/**
 * Checks the user has confirmed their password correctly
 */
export function validatePasswordConfirmation(
  password: string,
  confirmation: string,
) {
  return password === confirmation
    ? null
    : i18n.t('auth.validation.passwordsDontMatch')
}

/**
 * Checks that the user has selected a profile type. The profile field itself
 * isn't sent to the backend; the purpose of this validation is to make sure
 * that the user makes an explicit choice regarding whether the new user should
 * be an admin or not.
 */
export function validateProfile(profile?: ProfileType) {
  return profile ? null : i18n.t('users.validation.profileMissing')
}

/**
 * Checks that a user has been assigned an entity if required
 */
export function validateEntityId(
  profile?: ProfileType,
  entityId?: number | null,
) {
  return profile === 'entity' && typeof entityId !== 'number'
    ? i18n.t('users.validation.entityMissing')
    : null
}
