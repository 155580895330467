import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

/**
 * Gets an entity from the backend based on ID
 */
const getEntityById = (id: number) => {
  return axios.get<Entity>(`/entities/${id}/`)
}

export default wrapApiCall(getEntityById)
