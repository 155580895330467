import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

interface PasswordResetConfirmArgs {
  token: string
  password: string
}

/**
 * Sends the user's new password and the token they received in their
 * password-reset email to the backend in order to reset their password
 */
const passwordResetConfirm = ({
  token,
  password,
}: PasswordResetConfirmArgs) => {
  return axios.post('/auth/password_reset/confirm/', {
    token,
    password,
  })
}

export default wrapApiCall(passwordResetConfirm, { noAuth: true })
