import React, { useContext, useState } from 'react'
import { Box, ResponsiveContext } from 'grommet'
import Header from './Header'
import Sidebar from './Sidebar'

const BackofficeLayout: React.FC = ({ children }) => {
  const size = useContext(ResponsiveContext)
  const [showSidebar, setShowSidebar] = useState(size !== 'small')

  return (
    <Box fill>
      <Header toggleSidebar={() => setShowSidebar((isOpen) => !isOpen)} />
      <Box
        flex
        direction="row"
        background="light-1"
        overflow={{ horizontal: 'hidden' }}
      >
        <Sidebar
          closeSidebar={() => setShowSidebar(false)}
          showSidebar={showSidebar}
        />
        <Box flex margin={{ bottom: 'medium' }} pad="medium" overflow="auto">
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default BackofficeLayout
