import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Star } from 'react-feather'
import { FestivalsProvider } from 'context/FestivalsContext'
import PageHeading from 'components/PageHeading'
import FestivalsList from './FestivalsList'
import NewFestival from './NewFestival'

const FestivalsIndex: React.FC<RouteComponentProps> = () => {
  const [isNewFestivalModalOpen, setNewFestivalModal] = useState(false)

  return (
    <FestivalsProvider>
      <PageHeading
        title="festivals.list.title"
        button={{
          icon: <Star color="white" size="20px" />,
          label: 'festivals.newFestival.create',
          onClick: () => setNewFestivalModal(true),
        }}
      />

      <FestivalsList />
      {isNewFestivalModalOpen && (
        <NewFestival closeModal={() => setNewFestivalModal(false)} />
      )}
    </FestivalsProvider>
  )
}

export default FestivalsIndex
