import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'
import convertToFormData from 'api/convertToFormData'

/**
 * Sends the new author data to the backend and returns the created author
 */
const createAuthor = (author: NewAuthorData) => {
  return axios.post<Author>(`/authors/`, convertToFormData(author))
}

export default wrapApiCall(createAuthor)
