import React, { useCallback, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import getEntitiesList from 'api/entities/getEntitiesList'
import createEntity from 'api/entities/createEntity'
import deleteEntityByIdAndType from 'api/entities/deleteEntityByIdAndType'
import updateEntityById from 'api/entities/updateEntityById'
import upsertEntitySocialNetwork from 'api/entities/upsertEntitySocialNetwork'
import getEntityById from 'api/entities/getEntityById'
import { useProfileContext } from './ProfileContext'

interface EntitiesContextValue {
  myEntity?: Entity
  entities: Entity[]
  festivals: Festival[]
  getEntities: () => void
  addEntity: (entity: NewEntityData) => Promise<boolean>
  updateEntity: (entity: EntityUpdateData) => Promise<boolean>
  deleteEntity: (entity: Entity) => Promise<boolean>
  updateSocialMedia: (id: number, data: SocialMediaLinkData[]) => Promise<void>
  entitiesOptions: DropdownOption<Entity>[]
}

const EntitiesContext = React.createContext<EntitiesContextValue>({
  myEntity: undefined,
  entities: [],
  festivals: [],
  getEntities: () => {},
  addEntity: async (_: NewEntityData) => false,
  updateEntity: async (_: EntityUpdateData) => false,
  deleteEntity: async (_: Entity) => false,
  updateSocialMedia: async (_id: number, _data: SocialMediaLinkData[]) => {},
  entitiesOptions: [],
})

export const EntitiesProvider: React.FC = ({ children }) => {
  const { me, isAdmin } = useProfileContext()

  const [myEntity, setMyEntity] = useState<Entity>()
  const [entities, setEntities] = useState<Entity[]>([])
  const [entitiesOptions, setEntitiesOptions] = useState<
    DropdownOption<Entity>[]
  >([])
  const [festivals, setFestivals] = useState<Festival[]>([])

  const setAllEntities = (entityList: Entity[]) => {
    const options = entityList.map((entity) => ({
      label: entity.name,
      value: entity,
    }))
    setEntities(entityList)
    setEntitiesOptions(options)
  }

  const getMyEntityInfo = useCallback(async () => {
    // Remove entity info after logging out
    if (!me) {
      setMyEntity(undefined)
      return
    }

    // Get entity info after logging in
    const entityId = me.entity && me.entity.id
    if (!entityId) return
    const { data, error } = await getEntityById(entityId)

    if (error) {
      toast.error(error)
    } else if (data) {
      setMyEntity(data)
    }
  }, [me])

  const getEntities = useCallback(async () => {
    const { data, error } = await getEntitiesList()

    if (data) {
      const {
        festivals: f,
        bookstores,
        libraries,
        literary_houses: houses,
      } = data
      setFestivals(f)
      setAllEntities([...bookstores, ...libraries, ...houses])
      return
    }

    if (error) toast.error(error)
  }, [])

  const getEntity = async (id: number) => {
    const { data, error } = await getEntityById(id)

    if (error) {
      toast.error(error)
    } else if (data) {
      // Update the list straight away
      setEntities(entities.map((entity) => (entity.id === id ? data : entity)))

      // Get a fresh copy of the list from the backend anyway
      getEntities()
    }

    return data
  }

  const updateSocialMedia = async (
    entityId: number,
    socialData: SocialMediaLinkData[],
  ) => {
    const apiCalls = socialData.map((social) => {
      return upsertEntitySocialNetwork({ id: entityId, social })
    })
    await Promise.all(apiCalls)
  }

  const updateEntity = async (e: EntityUpdateData) => {
    const { id, entity_type: type, social, ...rest } = e

    // Update the entity
    const { error } = await updateEntityById({ id, type, data: rest })
    if (error) toast.error(error)

    // Update the entity's social networks
    await updateSocialMedia(id, social)

    const updatedEntity = await getEntity(id)
    return !!updatedEntity && !error
  }

  const addEntity = async (entity: NewEntityData) => {
    const { error, data } = await createEntity(entity)

    if (data) {
      await updateSocialMedia(data.id, entity.social)
      if (entity.image) await updateEntity({ ...data, image: entity.image })
      getEntity(data.id)
    }

    if (error) toast.error(error)
    return !!data && !error
  }

  const deleteEntity = async (e: Entity) => {
    const { error } = await deleteEntityByIdAndType(e)

    if (!error) {
      const newList = entities.filter((entity) => entity.id !== e.id)
      setAllEntities(newList)
    } else {
      toast.error(error)
    }

    return !error
  }

  useEffect(() => {
    if (isAdmin) getEntities()
  }, [getEntities, isAdmin])

  useEffect(() => {
    getMyEntityInfo()
  }, [getMyEntityInfo])

  return (
    <EntitiesContext.Provider
      value={{
        myEntity,
        addEntity,
        entities,
        festivals,
        getEntities,
        deleteEntity,
        updateEntity,
        entitiesOptions,
        updateSocialMedia,
      }}
    >
      {children}
    </EntitiesContext.Provider>
  )
}

export const useEntitiesList = () => useContext(EntitiesContext)

export default EntitiesContext
