import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

interface InviteResponse {
  message: string
}

/**
 * Calls the backend to send an invitation email to a new user
 */
const sendInvite = (user: UserInviteData) => {
  return axios.post<InviteResponse>(`/auth/signup`, user)
}

export default wrapApiCall(sendInvite)
