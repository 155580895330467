import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Box, Button, Text } from 'grommet'
import { formatDate } from 'utils/helpers'
import { useFestivalsList } from 'context/FestivalsContext'
import Link from 'components/Link'
import Modal from 'components/Modal'
import Table from 'components/Table'
import EditFestival from './EditFestival'

const FestivalsList = () => {
  const { t } = useTranslation()
  const { festivals, getFestivals, deleteFestival } = useFestivalsList()
  const [entityToEdit, setFestivalToEdit] = useState<Festival>()
  const [entityToDelete, setFestivalToDelete] = useState<Festival>()

  useEffect(() => {
    getFestivals()
  }, [getFestivals])

  const handleDeleteClick = async () => {
    if (!entityToDelete) return

    const success = await deleteFestival(entityToDelete)
    if (success) setFestivalToDelete(undefined)
  }

  return (
    <>
      <Table<Festival>
        actions={[
          { action: 'edit', onClick: setFestivalToEdit },
          { action: 'delete', onClick: setFestivalToDelete },
        ]}
        columns={[
          {
            label: 'common.name',
            propKey: 'name',
          },
          {
            label: 'festival.period',
            propKey: 'start_date',
          },
          {
            label: 'common.address',
            propKey: 'address',
          },
          {
            label: 'common.zone',
            propKey: 'zone',
          },
          {
            label: 'festival.programming',
            propKey: 'programming',
          },
        ]}
        getRowKey={({ id }) => `${id}`}
        items={festivals}
        itemRenderFunctions={{
          name: ({ name, description }) => {
            return (
              <>
                <Text size="small" weight={600}>
                  {name}
                </Text>
                <Text size="xsmall">
                  {description
                    ? _.truncate(description, { length: 50 })
                    : '---'}
                </Text>
              </>
            )
          },
          programming: ({ programming: url }) => {
            if (!url) return null

            return (
              <Link to={url}>
                <Text size="xsmall">{url}</Text>
              </Link>
            )
          },
          start_date: ({ start_date: startDate, end_date: endDate }) => {
            const [start, end] = [startDate, endDate].map((d) => formatDate(d))
            return (
              <>
                <Text size="xsmall">{`${t('common.start')}: ${start}`}</Text>
                <Text size="xsmall">{`${t('common.end')}: ${end}`}</Text>
              </>
            )
          },
          address: ({
            address,
            address_number: n,
            postal_code: postcode,
            district,
            county,
            zone,
          }) => {
            return (
              <>
                <Text size="xsmall">{`${address}, ${n}`}</Text>
                <Text size="xsmall">{`${postcode} ${county}`}</Text>
                <Text size="xsmall">{`${district} | ${zone}`}</Text>
              </>
            )
          },
        }}
        searchableColumns={['name']}
      />

      {entityToEdit && (
        <EditFestival
          festival={entityToEdit}
          closeModal={() => setFestivalToEdit(undefined)}
        />
      )}

      {entityToDelete && (
        <Modal onClick={() => setFestivalToDelete(undefined)}>
          <Box align="center" gap="1rem">
            <Text textAlign="center">{t('festivals.delete.confirm')}</Text>
            <Text textAlign="center" size="small" weight={600}>
              {entityToDelete.name}
            </Text>

            <Button
              onClick={handleDeleteClick}
              color="status-error"
              label={t('common.delete')}
              primary
            />
          </Box>
        </Modal>
      )}
    </>
  )
}

export default FestivalsList
