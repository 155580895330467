import 'typeface-open-sans'
import { darken, saturate, desaturate, lighten } from 'polished'

export const colorsByUserType: Record<EntityType | 'default', string> = {
  default: '#37474f',
  literary_house: '#a35a25',
  bookstore: '#0482c5',
  library: '#519b1a',
  festival: '#f56200',
  author: '#db0000',
}

/**
 * This function is used to create different tones of the main color
 * to be used in the backoffice. The default color is shown in the main
 * theme object below. After a user logs in, the color is selected based
 * on the type of entity the user is assigned to
 * @param color The color to be used as a base for the other colors
 */
export const createThemeColors = (
  color: string = colorsByUserType.default,
) => ({
  brand: color,
  brandDark: darken(0.18, color),
  brandLight: lighten(0.18, color),
  brandFaded: desaturate(0.5, lighten(0.5, color)),
  black: desaturate(0.5, darken(0.4, color)),
  focus: lighten(0.2, saturate(1, color)),
  'status-error': '#ce4658',
  'status-ok': '#2aaa53',
})

const theme = {
  global: {
    colors: createThemeColors(),
    font: {
      family: 'Open Sans',
      size: '16px',
      height: '23px',
    },
    hover: {
      color: 'brand',
    },
  },

  button: {
    border: {
      radius: '4px',
      width: '2px',
    },
    padding: {
      vertical: '5px',
    },
    extend: ({ colorValue, primary, theme }: ButtonStyleProps) => {
      return `
        color: ${
          primary ? theme.global.colors.white : theme.global.colors[colorValue]
        };
        font-weight: 600;
        transition: all 0.2s ease;
  
        :hover {
          box-shadow: none;
          filter: saturate(115%);
        }`
    },
  },

  formField: {
    label: {
      size: 'small',
    },
    help: {
      size: 'small',
      color: { dark: 'brandLight', light: 'brandLight' },
    },
    margin: {
      bottom: 'medium',
    },
  },

  layer: {
    background: 'transparent',
  },

  tab: {
    border: {
      active: {
        color: 'focus',
      },
    },
  },
}

export default theme

interface ButtonStyleProps {
  colorValue: string
  primary: boolean
  theme: any
}
