export const PROFILE_OPTIONS: DropdownOption<ProfileType>[] = [
  {
    label: 'users.profiles.entity',
    value: 'entity',
  },
  {
    label: 'users.profiles.admin',
    value: 'admin',
  },
]
