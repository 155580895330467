import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Form from 'components/Form'
import Input from 'components/Input'
import { useProfileContext } from 'context/ProfileContext'
import { Save } from 'react-feather'
import { Heading, Box } from 'grommet'
import { validateEmail } from 'utils/validation/users'

const EditProfile: React.FC<{ me: User }> = ({ me }) => {
  const { t } = useTranslation()
  const { updateProfile } = useProfileContext()

  const [name, setName] = useState(me.name || '')
  const [email, setEmail] = useState(me.email || '')
  const [phone, setPhone] = useState(me.phone || '')

  const handleSubmit = async () => {
    const info: Partial<ProfileInfo> = {
      name: name.trim(),
      email: email.trim(),
      phone: phone.trim() || null,
    }

    const infoError = validateEmail(email)
    if (infoError) {
      toast.error(infoError)
      return
    }

    const { error } = await updateProfile(info)
    if (error) toast.error(error)
  }

  return (
    <Box
      width="medium"
      pad="medium"
      elevation="small"
      round="xsmall"
      margin="2rem auto"
      background="white"
    >
      <Heading level="3">{t('settings.editProfile.title')}</Heading>
      <Form
        onSubmit={handleSubmit}
        submitButtonIcon={<Save />}
        submitButtonLabel={t('common.save')}
      >
        <Input label="common.name" value={name} onChange={setName} required />
        <Input
          label="common.email"
          value={email}
          onChange={setEmail}
          type="email"
          trimValue
          required
        />
        <Input
          label="common.phone"
          value={phone}
          onChange={setPhone}
          trimValue
        />
      </Form>
    </Box>
  )
}

export default EditProfile
