import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Calendar, Book } from 'react-feather'
import _ from 'lodash'
import { Box, Button, Text } from 'grommet'
import { useEntitiesList } from 'context/EntitiesContext'
import Link from 'components/Link'
import Modal from 'components/Modal'
import Table from 'components/Table'
import EditEntity from './EditEntity'

const EntitiesList = () => {
  const { t } = useTranslation()
  const { entities, getEntities, deleteEntity } = useEntitiesList()
  const [entityToEdit, setEntityToEdit] = useState<Entity>()
  const [entityToDelete, setEntityToDelete] = useState<Entity>()

  useEffect(() => {
    getEntities()
  }, [getEntities])

  const handleDeleteClick = async () => {
    if (!entityToDelete) return

    const success = await deleteEntity(entityToDelete)
    if (success) setEntityToDelete(undefined)
  }

  return (
    <>
      <Table<Entity>
        actions={[
          { action: 'edit', onClick: setEntityToEdit },
          { action: 'delete', onClick: setEntityToDelete },
        ]}
        columns={[
          {
            label: 'common.name',
            propKey: 'name',
          },
          {
            label: 'common.address',
            propKey: 'address',
          },
          {
            label: 'common.site_url',
            propKey: 'site_url',
          },
          {
            label: 'common.emptyString',
            // Don't actually want to render the description
            // Going to use it to show links to events and highlights
            propKey: 'description',
          },
        ]}
        getRowKey={({ id }) => `${id}`}
        items={entities}
        itemRenderFunctions={{
          name: ({ name, description }) => {
            return (
              <>
                <Text size="small" weight={600}>
                  {name}
                </Text>
                <Text size="xsmall">
                  {description
                    ? _.truncate(description, { length: 50 })
                    : '---'}
                </Text>
              </>
            )
          },
          site_url: ({ site_url: url }) => {
            if (!url) return null

            return (
              <Link to={url}>
                <Text size="xsmall">{url}</Text>
              </Link>
            )
          },
          address: ({
            address,
            address_number: n,
            postal_code: postcode,
            district,
            county,
            zone,
          }) => {
            return (
              <>
                <Text size="xsmall">{`${address}, ${n}`}</Text>
                <Text size="xsmall">{`${postcode} ${county}`}</Text>
                <Text size="xsmall">{`${district} | ${zone}`}</Text>
              </>
            )
          },
          description: ({ id }) => {
            return (
              <>
                <Link to={`/events/${id}`}>
                  <Box direction="row" align="center" gap="xsmall">
                    <Calendar size={14} />
                    <Text size="small">{t('nav.events')}</Text>
                  </Box>
                </Link>
                <Link to={`/highlights/${id}`}>
                  <Box direction="row" align="center" gap="xsmall">
                    <Book size={14} />
                    <Text size="small">{t('nav.highlights')}</Text>
                  </Box>
                </Link>
              </>
            )
          },
        }}
        searchableColumns={['name']}
      />

      {entityToEdit && (
        <EditEntity
          entity={entityToEdit}
          closeModal={() => setEntityToEdit(undefined)}
        />
      )}

      {entityToDelete && (
        <Modal onClick={() => setEntityToDelete(undefined)}>
          <Box align="center" gap="1rem">
            <Text textAlign="center">{t('entities.delete.confirm')}</Text>
            <Text textAlign="center" size="small" weight={600}>
              {entityToDelete.name}
            </Text>

            <Button
              onClick={handleDeleteClick}
              color="status-error"
              label={t('common.delete')}
              primary
            />
          </Box>
        </Modal>
      )}
    </>
  )
}

export default EntitiesList
