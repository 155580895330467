export const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production'

export const IS_LOCALHOST = process.env.REACT_APP_ENV === 'local'

export const BACKEND_URL = IS_PRODUCTION
  ? 'https://app3l.dglab.gov.pt/'
  : 'http://localhost:4545'

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const BASIC_PHONE_REGEX = /^([0-9()+-\s]){6,}$/

export const DATETIME_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/

export const IMAGE_FILE_TYPES = ['image/jpeg', 'image/png']

export const SOCIAL_PLATFORMS: SocialMedia[] = [
  'facebook',
  'twitter',
  'instagram',
  'youtube',
  'flickr',
]
