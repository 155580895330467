import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'
import updateEntityImage from '../entities/updateEntityImage'

/**
 * Sends updated festival data to the backend to be saved in the database.
 * Returns a fresh copy of the festival
 */
const updateFestival = async (festival: FestivalUpdateData) => {
  const { id, image, ...data } = festival
  const url = `/festivals/${id}/`

  if (image && typeof image !== 'string') {
    await updateEntityImage<Festival>({ id, type: 'festival', image })
  }

  return axios.patch<Festival>(url, data)
}

export default wrapApiCall(updateFestival)
