import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import sendInvite from 'api/auth/sendInvite'
import getUsersList from 'api/users/getUsersList'
import deleteUserById from 'api/users/deleteUserById'
import updateUser from 'api/users/updateUser'

interface UsersContextValue {
  deleteUser: (user: User, reason?: string) => Promise<boolean>
  getUsers: () => void
  sendUserInvite: (user: UserInviteData) => Promise<boolean>
  updateUserData: (user: User) => Promise<boolean>
  users: User[]
}

const UsersContext = React.createContext<UsersContextValue>({
  deleteUser: async (_: User) => false,
  getUsers: () => {},
  sendUserInvite: async (_: UserInviteData) => false,
  updateUserData: async (_: User) => false,
  users: [],
})

export const UsersProvider: React.FC = ({ children }) => {
  const { t } = useTranslation()
  const [users, setUsers] = useState<User[]>([])

  const getUsers = useCallback(async () => {
    const { data, error } = await getUsersList()

    if (data) {
      setUsers(data.results)
      return
    }

    if (error) toast.error(error)
  }, [])

  const sendUserInvite = async (u: UserInviteData) => {
    const { data, error } = await sendInvite(u)

    if (data) {
      toast.success(t('users.invites.inviteSent'))
      getUsers()
    } else if (error) {
      toast.error(error)
    }

    return !error
  }
  const updateUserData = async (u: User) => {
    const { data, error } = await updateUser(u)

    if (data) {
      toast.success(t('users.edit.success'))
      getUsers()
    } else if (error) {
      toast.error(error)
    }

    return !error
  }

  const deleteUser = async (u: User, reason?: string) => {
    const { error } = await deleteUserById({ user: u, reason })

    if (error) {
      toast.error(error)
    } else {
      toast.success(t('users.invites.deleted'))
      setUsers(users.filter((user) => user.id !== u.id))
    }

    return !error
  }

  useEffect(() => {
    getUsers()
  }, [getUsers])

  return (
    <UsersContext.Provider
      value={{
        deleteUser,
        getUsers,
        sendUserInvite,
        updateUserData,
        users,
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}

export const useUsersList = () => useContext(UsersContext)

export default UsersContext
