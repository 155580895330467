import React from 'react'
import { EntitiesProvider } from 'context/EntitiesContext'
import { useProfileContext } from 'context/ProfileContext'
import DashboardHome from 'containers/DashboardHome'
import ActivateUserPage from 'containers/auth/ActivateUserPage'
import LoginPage from 'containers/auth/LoginPage'
import RequestPasswordResetPage from 'containers/auth/RequestPasswordResetPage'
import ResetPasswordPage from 'containers/auth/ResetPasswordPage'
import Users from 'containers/users'
import Authors from 'containers/authors'
import Entities from 'containers/entities'
import Info from 'containers/info'
import MyEntity from 'containers/entities/MyEntity'
import LiteraryEvents from 'containers/events'
import Highlights from 'containers/highlights'
import Festivals from 'containers/festivals'
import Settings from 'containers/settings'
import FullHeightRouter from 'components/FullHeightRouter'
import Layout from 'components/Layout'
import ThemeByUserType from 'components/ThemeByUserType'

const Routes = () => {
  const { isAdmin, me } = useProfileContext()
  const entityId = me && me.entity && me.entity.id

  if (!me) {
    return (
      <FullHeightRouter>
        <LoginPage default />
        <RequestPasswordResetPage path="forgot-password" />
        <ActivateUserPage path="auth/activate/:uuid/:token" />
        <ResetPasswordPage path="auth/password_reset/:token" />
      </FullHeightRouter>
    )
  }

  return (
    <EntitiesProvider>
      <ThemeByUserType>
        <Layout>
          <FullHeightRouter>
            <DashboardHome default />
            <Authors path="/authors" />
            <Settings path="/settings" />

            {isAdmin && <Users path="/users" />}
            {isAdmin && <Entities path="/entities" />}
            {isAdmin && <Festivals path="/festivals" />}
            {isAdmin && <Info path="/info" />}
            {isAdmin && <LiteraryEvents path="/events/:id" />}
            {isAdmin && <Highlights path="/highlights/:id" />}

            {!isAdmin && <MyEntity path="/my-entity" />}
            {!isAdmin && entityId && (
              <LiteraryEvents path="/events" id={`${entityId}`} />
            )}
            {!isAdmin && entityId && (
              <Highlights path="/highlights" id={`${entityId}`} />
            )}
          </FullHeightRouter>
        </Layout>
      </ThemeByUserType>
    </EntitiesProvider>
  )
}

export default Routes
