import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FormField, MaskedInput } from 'grommet'
import { getThemeColor } from 'utils/helpers'

const Input = styled(MaskedInput)`
  background-color: ${getThemeColor('light-1')};
  border-radius: 4px 4px 0 0;
`

interface YearInputProps {
  onChange: (date: string) => void
  id?: string
  label: string
  value: string
  maxLength?: number
  autoFocus?: boolean
  disabled?: boolean
  required?: boolean
}

const isValidDate = (value: string) => {
  return /^([1-2]\d{3})$/.test(value)
}

const YearInput: React.FC<YearInputProps> = ({
  value,
  onChange,
  required = false,
  label,
  id = label,
}) => {
  const [date, setDate] = useState(() => value && value.split('-')[0])
  const { t } = useTranslation()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setDate(newValue)

    if (!isValidDate(newValue)) {
      onChange('')
      return
    }

    const date = `${newValue}-06-01`
    onChange(new Date(date).toISOString().replace('T00:', 'T12:'))
  }

  return (
    <FormField
      key={label}
      htmlFor={id}
      label={`${t(label)} ${required ? '*' : ''}`}
    >
      <Input
        id={id}
        mask={[
          {
            length: 4,
            regexp: /^[1-2]$|^1[0-9]$|^20$|^1[0-9][0-9]$|^20[0-9]$|^1[0-9][0-9][0-9]$|^20[0-9][0-9]$/,
            placeholder: t('forms.dateFormat.YYYY'),
          },
        ]}
        value={date}
        onChange={handleChange}
        required={required}
        inputMode="numeric"
      />
    </FormField>
  )
}

export default YearInput
