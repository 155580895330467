import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, Link } from '@reach/router'
import { toast } from 'react-toastify'
import { Box, Heading, Text } from 'grommet'
import { validateEmail } from 'utils/validation/users'
import { useProfileContext } from 'context/ProfileContext'
import LoggedOutLayout from 'components/LoggedOutLayout'
import Input from 'components/Input'
import Form from 'components/Form'

const LoginPage = (_: RouteComponentProps) => {
  const { t } = useTranslation()
  const { logIn } = useProfileContext()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => {
      toast.dismiss()
    }
  }, [])

  const handleSubmit = async () => {
    if (!email || !password) return

    const validationError = validateEmail(email)
    if (validationError) {
      toast.error(validationError)
      return
    }

    setLoading(true)
    toast(t('auth.loggingIn'), { autoClose: false })

    const { error } = await logIn({ email, password })
    toast.dismiss()
    setLoading(false)

    if (error) toast.error(error)
  }

  return (
    <LoggedOutLayout>
      <Heading level="2">{t(`auth.title.login`)}</Heading>

      <Form
        submitButtonLabel={t(`auth.login`)}
        onSubmit={handleSubmit}
        disabled={loading}
      >
        <Input
          value={email}
          id="email"
          label="common.email"
          onChange={setEmail}
          type="email"
          autoFocus
          trimValue
        />

        <Input
          value={password}
          id="password"
          label="auth.password"
          onChange={setPassword}
          type="password"
          trimValue
        />
      </Form>

      <Box gap="0.5rem" margin={{ top: 'medium' }} align="start">
        <Link to="/forgot-password">
          <Text size="small">{t(`auth.option.forgot`)}</Text>
        </Link>
      </Box>
    </LoggedOutLayout>
  )
}

export default LoginPage
