import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'
import { getEntityUrl } from './utils'

/**
 * Calls the backend to delete an entity based on `entity_type` and ID
 */
const deleteEntityByIdAndType = ({ id, entity_type: type }: Entity) => {
  const url = `${getEntityUrl(type)}${id}/`
  return axios.delete<undefined>(url)
}

export default wrapApiCall(deleteEntityByIdAndType)
