import React from 'react'
import { useTranslation } from 'react-i18next'
import { Edit, Trash2, AlertTriangle } from 'react-feather'
import styled from 'styled-components'
import { TableBody, TableRow, TableCell, Button, CheckBox } from 'grommet'
import { getThemeColor } from 'utils/helpers'

const StyledBody = styled(TableBody)`
  font-size: 0.9rem;

  tr:nth-child(even) {
    background-color: ${getThemeColor('light-2')};
  }

  tr:hover {
    background-color: ${getThemeColor('light-3')};
  }
`

function Body<P>({
  propKeys,
  items,
  itemRenderFunctions = {},
  actions = [],
  getRowKey,
  checkOption,
}: BodyProps<P>) {
  const { t } = useTranslation()

  const tableRows = items.map((item: P) => {
    const actionCells = actions.map(({ action, onClick }) => {
      const title = t(`common.${action}`)
      let Icon = AlertTriangle
      let color = 'status-warning'
      if (action === 'edit') {
        Icon = Edit
        color = 'neutral-3'
      } else if (action === 'delete') {
        Icon = Trash2
        color = 'status-error'
      }

      return (
        <TableCell key={action}>
          <Button
            a11yTitle={title}
            title={title}
            icon={<Icon size="20px" />}
            color={color}
            onClick={() => onClick(item)}
            plain
          />
        </TableCell>
      )
    })

    const tableRowCells = propKeys.map((prop) => {
      const renderFunc = itemRenderFunctions[prop]
      const value = renderFunc ? renderFunc(item) : item[prop]

      return <TableCell key={prop as string}>{value}</TableCell>
    })

    const checkCell = checkOption && (
      <TableCell key="check">
        <CheckBox
          onClick={() => checkOption.onClick(item)}
          checked={checkOption.isChecked(item)}
        />
      </TableCell>
    )

    return (
      <TableRow key={getRowKey(item)}>
        {[...actionCells, ...tableRowCells, checkCell]}
      </TableRow>
    )
  })

  return <StyledBody>{tableRows}</StyledBody>
}

export default Body
