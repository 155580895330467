import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'grommet'

const NoResultsText = () => {
  const { t } = useTranslation()

  return (
    <Text as="p" textAlign="center" color="dark-3" margin="2rem auto">
      {t('common.noResults')}
    </Text>
  )
}

export default NoResultsText
