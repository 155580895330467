import sortBy from 'lodash/sortBy'
import { GENRE_OPTIONS } from './constants'

const validGenreOptions = GENRE_OPTIONS.map((o) => o.value)
const isValidGenre = (genre: string) => validGenreOptions.includes(genre)

export const getGenreList = (genres: string) => {
  return sortBy(genres.split(',').filter(isValidGenre))
}

export const updateGenreList = (currentGenres: string, genre: string) => {
  const genres = getGenreList(currentGenres)
  const updatedList = genres.includes(genre)
    ? genres.filter((g) => g !== genre)
    : [...genres, genre]

  return sortBy(updatedList).filter(isValidGenre)
}
