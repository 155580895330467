import { validateDate } from './common'
import i18n from 'i18n'

/**
 * Checks that the author's date of birth is valid. If not, returns an error
 */
export const validateBirthDate = (date: string) => {
  return validateDate(date, 'validation.authors.invalidBirthDate')
}

/**
 * Checks that the author's date of death, if provided, is valid. If not,
 * returns an error
 */
export const validateDeathDate = (date: string) => {
  if (!date) return null
  return validateDate(date, 'validation.authors.invalidDeathDate')
}

/**
 * Checks that the author's genre data is not empty is valid. If the genre data
 * is empty, returns an error
 */
export const validateGenre = (genre?: string) => {
  return genre ? null : i18n.t('validation.authors.invalidGenre')
}
