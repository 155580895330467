import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import getAuthorsList from 'api/authors/getAuthorsList'
import createAuthor from 'api/authors/createAuthor'
import deleteAuthorById from 'api/authors/deleteAuthorById'
import updateEntityById from 'api/entities/updateEntityById'
import { useEntitiesList } from './EntitiesContext'

interface AuthorsContextValue {
  authors: Author[]
  getAuthors: () => void
  addAuthor: (_: NewAuthorData) => Promise<boolean>
  updateAuthor: (_: AuthorUpdateData) => Promise<boolean>
  deleteAuthor: (_: Author) => Promise<boolean>
}

const AuthorsContext = React.createContext<AuthorsContextValue>({
  authors: [],
  getAuthors: async () => {},
  addAuthor: async (_: NewAuthorData) => false,
  updateAuthor: async (_: AuthorUpdateData) => false,
  deleteAuthor: async (_: Author) => false,
})

export const AuthorsProvider: React.FC = ({ children }) => {
  const { t } = useTranslation()
  const { updateSocialMedia } = useEntitiesList()
  const [authors, setAuthors] = useState<Author[]>([])

  const getAuthors = useCallback(async () => {
    const { data, error } = await getAuthorsList()

    if (data) {
      setAuthors(data.results)
      return
    }

    if (error) toast.error(error)
  }, [])

  const addAuthor = async (a: NewAuthorData) => {
    const { data, error } = await createAuthor(a)

    if (data) {
      await updateSocialMedia(data.id, a.social)
      getAuthors()
    }
    if (error) toast.error(error)
    return !error
  }

  const deleteAuthor = async (a: Author) => {
    const { error } = await deleteAuthorById(a.id)

    if (!error) {
      toast.success(t('authors.delete.success'))
      setAuthors(authors.filter((author) => author.id !== a.id))
    } else {
      toast.error(error)
    }

    return !error
  }

  const updateAuthor = async (a: AuthorUpdateData) => {
    const { social, id, ...updateData } = a
    await updateSocialMedia(id, social)
    const { data, error } = await updateEntityById<Author>({
      id,
      type: 'author',
      data: updateData,
    })

    if (data) {
      setAuthors(authors.map((author) => (author.id === a.id ? data : author)))
    } else if (error) {
      toast.error(error)
    }

    return !error
  }

  useEffect(() => {
    getAuthors()
  }, [getAuthors])

  return (
    <AuthorsContext.Provider
      value={{ addAuthor, getAuthors, deleteAuthor, updateAuthor, authors }}
    >
      {children}
    </AuthorsContext.Provider>
  )
}

export const useAuthorsList = () => useContext(AuthorsContext)

export default AuthorsContext
