import React, { useCallback, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import getHighlightsList from 'api/highlights/getHighlightsList'
import createHighlight from 'api/highlights/createHighlight'
import deleteHighlightData from 'api/highlights/deleteHighlight'
import updateHighlightData from 'api/highlights/updateHighlight'
import { useEntitiesList } from './EntitiesContext'

interface HighlightsContextValue {
  highlights: Highlight[]
  getHighlights: () => void
  addHighlight: (highlight: HighlightFormData) => Promise<boolean>
  updateHighlight: (highlight: HighlightUpdateData) => Promise<boolean>
  deleteHighlight: (highlight: Highlight) => Promise<boolean>
  entityId: number
}

const HighlightsContext = React.createContext<HighlightsContextValue>({
  highlights: [],
  getHighlights: () => {},
  addHighlight: async (_: HighlightFormData) => false,
  updateHighlight: async (_: HighlightUpdateData) => false,
  deleteHighlight: async (_: Highlight) => false,
  entityId: 0,
})

type ProviderComponent = React.FC<{ entityId: number }>

export const HighlightsProvider: ProviderComponent = ({
  children,
  entityId: eId,
}) => {
  const { myEntity } = useEntitiesList()
  const [highlights, setHighlights] = useState<Highlight[]>([])
  const [entityId] = useState(myEntity ? myEntity.id : eId)

  const getHighlights = useCallback(async () => {
    const { data, error } = await getHighlightsList(entityId)

    if (data) {
      setHighlights(data.results)
      return
    }

    if (error) toast.error(error)
  }, [entityId])

  const updateHighlight = async (h: HighlightUpdateData) => {
    const { data: success, error } = await updateHighlightData({
      entityId,
      highlight: h,
    })

    if (error) toast.error(error)
    if (success) {
      const newList = highlights.map((highlight) =>
        highlight.id === success.id ? success : highlight,
      )
      setHighlights(newList)
    }

    return !!success && !error
  }

  const addHighlight = async (h: HighlightFormData) => {
    const { error, data } = await createHighlight({ entityId, highlight: h })

    if (data) {
      const newList = [data, ...highlights]
      setHighlights(newList)
    }

    if (error) toast.error(error)
    return !!data && !error
  }

  const deleteHighlight = async (h: Highlight) => {
    const { error } = await deleteHighlightData({ entityId, highlight: h })

    if (!error) {
      const newList = highlights.filter((highlight) => highlight.id !== h.id)
      setHighlights(newList)
    } else {
      toast.error(error)
    }

    return !error
  }

  return (
    <HighlightsContext.Provider
      value={{
        addHighlight,
        highlights,
        getHighlights,
        deleteHighlight,
        updateHighlight,
        entityId,
      }}
    >
      {children}
    </HighlightsContext.Provider>
  )
}

export const useHighlightsList = () => useContext(HighlightsContext)

export default HighlightsContext
