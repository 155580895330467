import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

/**
 * Gets a festival from the backend based on ID
 */
const getFestivalById = (id: number) => {
  return axios.get<Festival>(`/festivals/${id}/`)
}

export default wrapApiCall(getFestivalById)
