import React, { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Save } from 'react-feather'
import { Box, Heading } from 'grommet'
import { useAuthorsList } from 'context/AuthorsContext'
import Modal from 'components/Modal'
import Form from 'components/Form'
import Input from 'components/Input'
import TextArea from 'components/TextArea'
import YearInput from 'components/YearInput'
import ImageFileInput from 'components/FileInput/ImageFileInput'
import LocationDropdowns from 'components/LocationDropdowns'
import MultiSelect from 'components/MultiSelect'
import SocialMediaFields from 'components/SocialMediaFields'
import {
  validateCoordinates,
  validateSocialMediaURLs,
} from 'utils/validation/common'
import {
  validateBirthDate,
  validateDeathDate,
  validateGenre,
} from 'utils/validation/authors'
import { GENRE_OPTIONS } from './constants'
import { getGenreList, updateGenreList } from './utils'

interface NewAuthorProps {
  closeModal: () => void
}

const NewAuthor: React.FC<NewAuthorProps> = ({ closeModal }) => {
  const { t } = useTranslation()
  const { addAuthor } = useAuthorsList()
  const [loading, setLoading] = useState(false)

  // Form-field data
  const [name, setName] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [birthPlace, setBirthPlace] = useState('')
  const [deathDate, setDeathDate] = useState('')
  const [deathPlace, setDeathPlace] = useState('')
  const [genre, setGenre] = useState('')
  const [description, setDescription] = useState('')
  const [work1, setWork1] = useState('')
  const [work2, setWork2] = useState('')
  const [work3, setWork3] = useState('')
  const [moreInfo, setMoreInfo] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [image, setImage] = useState<File | null>(null)
  const [county, setCounty] = useState('')
  const [zone, setZone] = useState('')
  const [district, setDistrict] = useState('')
  const [social, setSocial] = useState<SocialMediaLinkData[]>([])

  const selectedGenres = useMemo(() => getGenreList(genre), [genre])

  const handleGenreChange = useCallback(
    (value: string) => setGenre(updateGenreList(genre, value).join(',')),
    [genre],
  )

  const handleClose = () => {
    // Don't allow the user to close the modal while it's saving details
    if (loading) return
    closeModal()
  }

  const handleSubmit = async () => {
    const error =
      validateBirthDate(birthDate) ||
      validateDeathDate(deathDate) ||
      validateCoordinates(latitude, longitude) ||
      validateGenre(genre)

    const urlErrors = validateSocialMediaURLs(social)

    const anyErrors = error || urlErrors.length
    if (anyErrors) {
      if (error) toast.error(error)
      urlErrors.forEach((msg) => toast.error(msg))
      return
    }

    const authorData: NewAuthorData = {
      name,
      birth_date: birthDate,
      birth_place: birthPlace,
      death_date: deathDate || undefined,
      death_place: deathPlace || undefined,
      genre: selectedGenres.join(','),
      description: description || undefined,
      work1: work1,
      work2: work2 || undefined,
      work3: work3 || undefined,
      more_info: moreInfo,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      image: image || undefined,
      district,
      county,
      zone,
      social,
    }

    setLoading(true)
    const success = await addAuthor(authorData)
    setLoading(false)

    if (!success) return
    toast.success(t('authors.newAuthor.created'))
    closeModal()
  }

  return (
    <Modal onClick={handleClose}>
      <Box width="medium">
        <Heading level="3">{t('authors.newAuthor.title')}</Heading>

        <Form
          onCancel={handleClose}
          onSubmit={handleSubmit}
          submitButtonIcon={<Save size={16} />}
          submitButtonLabel={t('common.save')}
          disabled={loading}
        >
          <Input
            label="common.name"
            value={name}
            onChange={setName}
            required
            autoFocus
          />

          <YearInput
            label="author.birth_date"
            value={birthDate}
            onChange={setBirthDate}
            required
          />

          <Input
            label="author.birth_place"
            value={birthPlace}
            onChange={setBirthPlace}
            required
          />

          <YearInput
            label="author.death_date"
            value={deathDate}
            onChange={setDeathDate}
          />
          <Input
            label="author.death_place"
            value={deathPlace}
            onChange={setDeathPlace}
          />

          <TextArea
            label="author.description"
            value={description}
            onChange={setDescription}
          />

          <MultiSelect<string>
            label="author.genre"
            options={GENRE_OPTIONS}
            onChange={handleGenreChange}
            selected={selectedGenres}
            required
          />

          <Input
            label="author.work1"
            value={work1}
            onChange={setWork1}
            required
          />

          <Input label="author.work2" value={work2} onChange={setWork2} />

          <Input label="author.work3" value={work3} onChange={setWork3} />

          <Input
            label="author.more_info"
            value={moreInfo}
            onChange={setMoreInfo}
          />

          <LocationDropdowns
            zone={zone}
            district={district}
            county={county}
            onChange={(data) => {
              setZone(data.zone || '')
              setDistrict(data.district || '')
              setCounty(data.county || '')
            }}
          />

          <Input
            label="common.latitude"
            value={latitude}
            onChange={setLatitude}
            required
            type="tel"
            trimValue
          />
          <Input
            label="common.longitude"
            value={longitude}
            onChange={setLongitude}
            required
            type="tel"
            trimValue
          />

          <ImageFileInput file={image} onChange={setImage} />

          <SocialMediaFields linkData={social} onChange={setSocial} />
        </Form>
      </Box>
    </Modal>
  )
}

export default NewAuthor
