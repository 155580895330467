import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableHeader, TableRow, TableCell } from 'grommet'

function Header<P>({
  actionTypes = [],
  checkOption,
  columns,
}: TableHeaderProps<P>) {
  const { t } = useTranslation()

  const actionCells = actionTypes.map((action) => (
    <TableCell key={action} scope="col" />
  ))
  const cells = columns.map((column) => (
    <TableCell key={column} scope="col">
      <strong>{t(column)}</strong>
    </TableCell>
  ))

  return (
    <TableHeader>
      <TableRow>
        {actionCells}
        {cells}
        {checkOption && checkOption.header && (
          <TableCell scope="col">{checkOption.header}</TableCell>
        )}
      </TableRow>
    </TableHeader>
  )
}

export default Header
