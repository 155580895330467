import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from '@reach/router'
import { Box, Text, Grid } from 'grommet'
import NAV from 'components/Layout/nav'
import { useProfileContext } from 'context/ProfileContext'
import Link from 'components/Link'

const DashboardHome = (_: RouteComponentProps) => {
  const { t } = useTranslation()
  const { isAdmin } = useProfileContext()

  return (
    <Box width="large" margin="auto">
      <Grid columns="275px" gap="medium">
        {NAV.map(({ Icon, adminOnly, text, to, entityOnly }) => {
          if (adminOnly && !isAdmin) return null
          if (isAdmin && entityOnly) return null

          return (
            <Link to={to} key={to}>
              <Box
                direction="row"
                elevation="medium"
                background="white"
                round="xsmall"
                overflow="hidden"
                align="center"
              >
                <Box pad="medium" background="brand" justify="center">
                  <Icon size={50} color="white" />
                </Box>
                <Box pad="medium">
                  <Text weight={600}>{t(text)}</Text>
                </Box>
              </Box>
            </Link>
          )
        })}
      </Grid>
    </Box>
  )
}

export default DashboardHome
