import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'
import convertToFormData from 'api/convertToFormData'

/**
 * Sends the new festival data to the backend and returns the created festival
 */
const createFestival = (festival: NewFestivalData) => {
  return axios.post<Festival>(`/festivals/`, convertToFormData(festival))
}

export default wrapApiCall(createFestival)
