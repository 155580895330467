import React from 'react'
import _ from 'lodash'
import Input from './Input'
import { SOCIAL_PLATFORMS } from 'utils/constants'

interface SocialMediaFieldsProps {
  onChange: (_: SocialMediaLinkData[]) => void
  linkData: SocialMediaLinkData[]
}

const SocialMediaFields: React.FC<SocialMediaFieldsProps> = ({
  onChange,
  linkData,
}) => {
  return (
    <>
      {SOCIAL_PLATFORMS.map((platform) => {
        const platformInfo = linkData.find(({ name }) => name === platform)
        const value = platformInfo ? platformInfo.url : ''

        return (
          <Input
            key={platform}
            label={`social.${platform}`}
            placeholder={`social.${platform}Placeholder`}
            value={value}
            onChange={(url) => {
              const data = platformInfo
                ? [{ ...platformInfo, url }, ...linkData]
                : [{ name: platform, url }, ...linkData]
              const newLinks = _.uniqBy(data, 'name')

              onChange(newLinks)
            }}
            maxLength={220}
            trimValue
          />
        )
      })}
    </>
  )
}

export default SocialMediaFields
