import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

interface PasswordResetArgs {
  email: string
}

/**
 * Sends a user's email address to the backend to request the password
 * to be reset. If successful, the user will receive an email
 */
const passwordReset = ({ email }: PasswordResetArgs) => {
  return axios.post('/auth/password_reset/', { email })
}

export default wrapApiCall(passwordReset, { noAuth: true })
