import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

interface DeleteHighlightArgs {
  highlight: Highlight
  entityId: number
}

/**
 * Calls the backend to delete a highlight based on the entity's ID and
 * the highlight's ID
 */
const deleteHighlight = ({ highlight, entityId }: DeleteHighlightArgs) => {
  const { id } = highlight
  return axios.delete<undefined>(`/entities/${entityId}/highlights/${id}/`)
}

export default wrapApiCall(deleteHighlight)
