import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { useProfileContext } from 'context/ProfileContext'
import EditProfile from './EditProfile'
import DeactivateProfile from './DeactivateProfile'

const Settings: React.FC<RouteComponentProps> = () => {
  const { me } = useProfileContext()

  return (
    <>
      {me && <EditProfile me={me} />}
      <DeactivateProfile />
    </>
  )
}

export default Settings
