import React from 'react'
import { useTranslation } from 'react-i18next'
import { UploadCloud } from 'react-feather'
import _ from 'lodash'
import { FormField, Box, Text, FormFieldProps } from 'grommet'
import { Label, Input } from './styled'

export interface FileInputProps extends FormFieldProps {
  file?: File | null
  fileTypes: string[]
  icon?: React.ReactNode
  label: string
  color?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
}

const FileInput = ({
  label,
  onChange,
  file,
  fileTypes,
  icon = <UploadCloud size={30} />,
  color,
  required,
  help,
}: FileInputProps) => {
  const { t } = useTranslation()

  const fileName = file && _.truncate(file.name)
  const labelText = fileName || t('forms.fileInput.noFileSelected')
  const inputId = `input-${label}`

  return (
    <FormField label={`${t(label)} ${required ? '*' : ''}`} help={help}>
      <Label id={label} htmlFor={inputId} fileExists={!!fileName} color={color}>
        <Input
          id={inputId}
          onChange={onChange}
          type="file"
          accept={fileTypes.join(', ')}
          required={required}
        />
        <Box
          direction="row"
          align="center"
          justify="between"
          gap="small"
          pad="small"
        >
          <Text size="small" truncate>
            {labelText}
          </Text>
          <Box>{icon}</Box>
        </Box>
      </Label>
    </FormField>
  )
}

export default FileInput
