import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Heading, ResponsiveContext } from 'grommet'

interface PageHeadingProps {
  title: string
  button?: {
    icon: JSX.Element
    color?: string
    label: string
    onClick: () => void
  }
  translateTitle?: boolean
}

const PageHeading: React.FC<PageHeadingProps> = ({
  title,
  button,
  translateTitle = true,
}) => {
  const size = useContext(ResponsiveContext)
  const { t } = useTranslation()

  return (
    <Box
      direction="row"
      justify="between"
      align="center"
      gap="small"
      margin={{ bottom: 'medium' }}
      wrap="reverse"
    >
      <Heading level="2">{translateTitle ? t(title) : title}</Heading>
      {button && (
        <Button
          color={button.color || 'brand'}
          icon={button.icon}
          label={size === 'small' ? undefined : t(button.label)}
          margin={{ left: 'auto' }}
          onClick={button.onClick}
          primary
        />
      )}
    </Box>
  )
}

export default PageHeading
