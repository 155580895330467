import React, { useCallback, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { setAxiosToken } from 'config/axios'
import getMyInfo from 'api/users/getMyInfo'
import updateUser from 'api/users/updateUser'
import deleteUserById from 'api/users/deleteUserById'
import login from 'api/auth/login'

interface LoginArgs {
  email: string
  password: string
}

interface ProfileContextValue {
  me?: User
  isAdmin: boolean
  logIn: (_: LoginArgs) => Promise<{ error?: string }>
  logOut: () => void
  deactivateProfile: (_: string) => Promise<{ error?: string }>
  updateProfile: (_: Partial<ProfileInfo>) => Promise<{ error?: string }>
}

const ProfileContext = React.createContext<ProfileContextValue>({
  me: undefined,
  isAdmin: false,
  logIn: async () => ({}),
  logOut: () => {},
  deactivateProfile: async () => ({}),
  updateProfile: async () => ({}),
})

export const ProfileProvider: React.FC = ({ children }) => {
  const { t } = useTranslation()

  const [isAdmin, setAdmin] = useState(false)
  const [me, setMe] = useState<User>()

  const setMyInfo = useCallback(async () => {
    const { data: userInfo, error } = await getMyInfo()

    if (userInfo) {
      setMe(userInfo)
      setAdmin(userInfo.is_superuser)
    }

    return { error }
  }, [])

  const logOut = () => {
    setAxiosToken(undefined)
    setAdmin(false)
    setMe(undefined)
  }

  const logIn = async (loginArgs: LoginArgs) => {
    const { data, error: loginError } = await login(loginArgs)

    if (!data || loginError) {
      return { error: loginError || t('auth.loginFail') }
    }

    setAxiosToken(data.token)

    // Track login date
    localStorage.setItem('e', new Date().toUTCString())

    return setMyInfo()
  }

  const updateProfile = async (info: Partial<ProfileInfo>) => {
    if (!me) return {}
    const { data, error } = await updateUser({ ...info, id: me.id })

    if (data) {
      setMe(data)
      setAdmin(data.is_superuser)
      toast.success(t('common.saved'))
    }

    return { error }
  }

  const deactivateProfile = async (reason?: string) => {
    if (!me) return {}
    const { error } = await deleteUserById({ user: me, reason })

    if (!error) {
      toast.success(t('users.accountDeactivated'))
      logOut()
    }

    return { error }
  }

  // If token is stored and the user isn't resetting their password
  // or activating their account, get the user info
  useEffect(() => {
    const hasToken = !!localStorage.getItem('t')
    const canLogin = !/\/auth\//.test(window.location.pathname)

    if (hasToken && canLogin) {
      setMyInfo()
    } else {
      setAxiosToken(undefined)
    }
  }, [setMyInfo])

  return (
    <ProfileContext.Provider
      value={{
        me,
        isAdmin,
        logIn,
        logOut,
        updateProfile,
        deactivateProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}

export const useProfileContext = () => useContext(ProfileContext)

export default ProfileContext
