import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { UserPlus } from 'react-feather'
import { UsersProvider } from 'context/UsersContext'
import PageHeading from 'components/PageHeading'
import InvitesList from './InvitesList'
import NewUser from './NewUser'

const UsersIndex: React.FC<RouteComponentProps> = () => {
  const [isNewUserModalOpen, setNewUserModal] = useState(false)

  return (
    <UsersProvider>
      <PageHeading
        title="users.userList.title"
        button={{
          icon: <UserPlus color="white" size="20px" />,
          label: 'users.newUser.create',
          onClick: () => setNewUserModal(true),
        }}
      />

      <InvitesList />
      {isNewUserModalOpen && (
        <NewUser closeModal={() => setNewUserModal(false)} />
      )}
    </UsersProvider>
  )
}

export default UsersIndex
