import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import styled from 'styled-components'
import { FormField, Select } from 'grommet'
import { getThemeColor } from 'utils/helpers'
import { ChevronDown } from 'react-feather'

const StyledDropdown = styled(Select)`
  background-color: ${getThemeColor('light-1')};
  border-radius: 4px 4px 0 0;
`

interface DropdownProps<T> {
  id?: string
  label: string
  help?: string
  placeholder?: string
  value?: DropdownOption<T>
  onChange: (value: T) => void
  options: DropdownOption<T>[]
  valueKey?: string
  valueLabel?: string
  disabled?: boolean
  required?: boolean
  translateLabels?: boolean
}

function Dropdown<T>({
  id,
  label,
  value,
  onChange,
  options,
  required,
  translateLabels = true,
  disabled,
  placeholder = label,
  help,
}: DropdownProps<T>) {
  const { t } = useTranslation()
  const [stateOptions, setOptions] = useState(options)

  useEffect(() => {
    setOptions(options)
  }, [options])

  return (
    <FormField
      key={label}
      htmlFor={id}
      label={`${t(label)} ${required ? '*' : ''}`}
      required={required}
      help={help && t(help)}
    >
      <StyledDropdown
        id={id}
        placeholder={t(placeholder)}
        value={value || ''}
        onChange={({ value }) => {
          onChange(value.value)
          setOptions(options)
        }}
        onSearch={(searchText) => {
          const regexp = new RegExp(searchText, 'i')
          const matches = options.filter((o) => {
            const labelText = translateLabels ? t(o.label) : o.label
            return _.deburr(labelText).match(regexp)
          })

          setOptions(matches)
        }}
        options={stateOptions}
        valueKey="value"
        labelKey={(option) =>
          option && (translateLabels ? t(option.label) : option.label)
        }
        emptySearchMessage={t('common.noResults')}
        icon={<ChevronDown />}
        disabled={disabled}
      />
    </FormField>
  )
}

export default Dropdown
