import React from 'react'
import styled from 'styled-components'
import { FormField, TextArea } from 'grommet'
import { useTranslation } from 'react-i18next'
import { getThemeColor } from 'utils/helpers'

const StyledTextArea = styled(TextArea)`
  background-color: ${getThemeColor('light-1')};
  border-radius: 4px 4px 0 0;
`

interface CustomTextAreaProps {
  id?: string
  label: string
  value: string
  onChange: (_: string) => void
  maxLength?: number
  autoFocus?: boolean
  disabled?: boolean
  required?: boolean
}

function CustomTextArea({
  id,
  label,
  value,
  onChange,
  autoFocus,
  disabled,
  maxLength,
  required,
}: CustomTextAreaProps) {
  const { t } = useTranslation()

  return (
    <FormField
      key={label}
      label={`${t(label)} ${required ? '*' : ''}`}
      htmlFor={id}
    >
      <StyledTextArea
        id={id}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          onChange(e.target.value)
        }
        value={value || ''}
        autoFocus={autoFocus}
        disabled={disabled}
        maxLength={maxLength}
        required={required}
        resize="vertical"
      />
    </FormField>
  )
}

export default CustomTextArea
