import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { X as Close } from 'react-feather'
import {
  Box,
  Button,
  Collapsible,
  Layer,
  ResponsiveContext,
  Text,
} from 'grommet'
import { useProfileContext } from 'context/ProfileContext'
import { Link } from './styled'
import NAV from './nav'

interface SidebarProps {
  closeSidebar: () => void
  showSidebar: boolean
}

const Sidebar: React.FC<SidebarProps> = ({ closeSidebar, showSidebar }) => {
  const { t } = useTranslation()
  const size = useContext(ResponsiveContext)
  const { isAdmin } = useProfileContext()

  const links = NAV.map(({ to, text, Icon, adminOnly, entityOnly }) => {
    if (adminOnly && !isAdmin) return null
    if (entityOnly && isAdmin) return null

    return (
      <Link
        key={to}
        to={to}
        onClick={() => {
          if (size === 'small') closeSidebar()
        }}
      >
        <Icon size="18px" />
        <Text margin={{ left: 'small' }}>{t(text)}</Text>
      </Link>
    )
  })

  if (!showSidebar || size !== 'small') {
    return (
      <Collapsible direction="horizontal" open={showSidebar}>
        <Box
          flex
          pad="medium"
          background="light-2"
          elevation="xsmall"
          width="250px"
        >
          {links}
        </Box>
      </Collapsible>
    )
  }

  return (
    <Layer>
      <Box
        background="brand"
        color="white"
        tag="header"
        justify="end"
        align="center"
        direction="row"
      >
        <Button icon={<Close />} onClick={closeSidebar} />
      </Box>
      <Box
        fill
        background="brand"
        color="white"
        align="center"
        justify="center"
      >
        <Box>{links}</Box>
      </Box>
    </Layer>
  )
}

export default Sidebar
