import React, { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Save } from 'react-feather'
import { Box, Heading, Text } from 'grommet'
import { useAuthorsList } from 'context/AuthorsContext'
import Modal from 'components/Modal'
import Form from 'components/Form'
import Input from 'components/Input'
import TextArea from 'components/TextArea'
import YearInput from 'components/YearInput'
import MultiSelect from 'components/MultiSelect'
import SocialMediaFields from 'components/SocialMediaFields'
import ImageFileInput from 'components/FileInput/ImageFileInput'
import LocationDropdowns from 'components/LocationDropdowns'
import {
  validateCoordinates,
  validateSocialMediaURLs,
} from 'utils/validation/common'
import {
  validateBirthDate,
  validateDeathDate,
  validateGenre,
} from 'utils/validation/authors'
import { GENRE_OPTIONS } from './constants'
import { getGenreList, updateGenreList } from './utils'

interface EditAuthorProps {
  author: Author
  closeModal: () => void
}

const EditAuthor: React.FC<EditAuthorProps> = ({ author, closeModal }) => {
  const { t } = useTranslation()
  const { updateAuthor } = useAuthorsList()
  const [loading, setLoading] = useState(false)

  // Form-field data
  const [name, setName] = useState(author.name || '')
  const [birthDate, setBirthDate] = useState(author.birth_date || '')
  const [birthPlace, setBirthPlace] = useState(author.birth_place || '')
  const [deathDate, setDeathDate] = useState(author.death_date || '')
  const [deathPlace, setDeathPlace] = useState(author.death_place || '')
  const [genre, setGenre] = useState(author.genre)
  const [description, setDescription] = useState(author.description || '')
  const [work1, setWork1] = useState(author.work1 || '')
  const [work2, setWork2] = useState(author.work2 || '')
  const [work3, setWork3] = useState(author.work3 || '')
  const [moreInfo, setMoreInfo] = useState(author.more_info || '')
  const [latitude, setLatitude] = useState(`${author.latitude}` || '')
  const [longitude, setLongitude] = useState(`${author.longitude}` || '')
  const [image, setImage] = useState<File | null>(null)
  const [county, setCounty] = useState(`${author.county}` || '')
  const [zone, setZone] = useState(`${author.zone}` || '')
  const [district, setDistrict] = useState(`${author.district}` || '')
  const [social, setSocial] = useState<SocialMediaLinkData[]>(
    author.social || [],
  )

  const selectedGenres = useMemo(() => getGenreList(genre), [genre])

  const handleGenreChange = useCallback(
    (value: string) => setGenre(updateGenreList(genre, value).join(',')),
    [genre],
  )

  const handleClose = () => {
    // Don't allow the user to close the modal while it's saving details
    if (loading) return
    closeModal()
  }

  const handleSubmit = async () => {
    const error =
      validateBirthDate(birthDate) ||
      validateDeathDate(deathDate) ||
      validateCoordinates(latitude, longitude) ||
      validateGenre(genre)

    const urlErrors = validateSocialMediaURLs(social)

    const anyErrors = error || urlErrors.length
    if (anyErrors) {
      if (error) toast.error(error)
      urlErrors.forEach((msg) => toast.error(msg))
      return
    }

    const authorData: AuthorUpdateData = {
      ...author,
      name,
      birth_date: birthDate,
      birth_place: birthPlace,
      death_date: deathDate || undefined,
      death_place: deathPlace || undefined,
      genre: selectedGenres.join(','),
      description: description || undefined,
      work1,
      work2: work2 || undefined,
      work3: work3 || undefined,
      more_info: moreInfo,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      image: image || undefined,
      zone,
      district,
      county,
      social,
    }

    setLoading(true)

    const success = await updateAuthor(authorData)
    setLoading(false)

    if (!success) return
    toast.success(t('common.saved'))
    closeModal()
  }

  return (
    <Modal onClick={handleClose}>
      <Box width="medium">
        <Heading level="3">{t('authors.editAuthor.title')}</Heading>

        <Form
          onCancel={handleClose}
          onSubmit={handleSubmit}
          submitButtonIcon={<Save size={16} />}
          submitButtonLabel={t('common.save')}
          disabled={loading}
        >
          <Input
            label="common.name"
            value={name}
            onChange={setName}
            required
            autoFocus
          />

          <YearInput
            label="author.birth_date"
            value={birthDate}
            onChange={setBirthDate}
            required
          />
          <Input
            label="author.birth_place"
            value={birthPlace}
            onChange={setBirthPlace}
            required
          />

          <YearInput
            label="author.death_date"
            value={deathDate}
            onChange={setDeathDate}
          />
          <Input
            label="author.death_place"
            value={deathPlace}
            onChange={setDeathPlace}
          />

          <TextArea
            label="author.description"
            value={description}
            onChange={setDescription}
          />

          <MultiSelect<string>
            label="author.genre"
            options={GENRE_OPTIONS}
            onChange={handleGenreChange}
            selected={selectedGenres}
            required
          />

          <Input
            label="author.work1"
            value={work1}
            onChange={setWork1}
            required
          />
          <Input label="author.work2" value={work2} onChange={setWork2} />
          <Input label="author.work3" value={work3} onChange={setWork3} />

          <Input
            label="author.more_info"
            value={moreInfo}
            onChange={setMoreInfo}
          />

          <LocationDropdowns
            zone={zone}
            district={district}
            county={county}
            onChange={(data) => {
              setZone(data.zone || '')
              setDistrict(data.district || '')
              setCounty(data.county || '')
            }}
          />

          <Input
            label="common.latitude"
            value={latitude}
            onChange={setLatitude}
            required
            type="tel"
            trimValue
          />

          <Input
            label="common.longitude"
            value={longitude}
            onChange={setLongitude}
            required
            type="tel"
            trimValue
          />

          <ImageFileInput
            help={
              author.image && (
                <Text size="small">
                  {`${t('forms.fileInput.current')}: ${author.image}`}
                </Text>
              )
            }
            file={image}
            onChange={setImage}
          />

          <SocialMediaFields linkData={social} onChange={setSocial} />
        </Form>
      </Box>
    </Modal>
  )
}

export default EditAuthor
