/**
 * Converts any object to `FormData`
 */
function convertToFormData(object: Record<string, any>) {
  const body = new FormData()

  Object.keys(object).forEach((key) => {
    const value = object[key]
    if (value === undefined) return

    body.set(key, object[key])
  })

  return body
}

export default convertToFormData
