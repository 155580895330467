import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Save } from 'react-feather'
import { Box, Heading, Text } from 'grommet'
import {
  validateCoordinates,
  validateURLs,
  validateContactDetails,
  validateSocialMediaURLs,
} from 'utils/validation/common'
import { useEntitiesList } from 'context/EntitiesContext'
import Modal from 'components/Modal'
import Form from 'components/Form'
import Input from 'components/Input'
import TextArea from 'components/TextArea'
import Dropdown from 'components/Dropdown'
import { ENTITY_TYPE_OPTIONS } from './constants'
import LocationDropdowns from 'components/LocationDropdowns'
import SocialMediaFields from 'components/SocialMediaFields'
import ImageFileInput from 'components/FileInput/ImageFileInput'

interface EditEntityProps {
  entity: Entity
  closeModal: () => void
}

const EditEntity: React.FC<EditEntityProps> = ({ entity, closeModal }) => {
  const { t } = useTranslation()
  const { updateEntity } = useEntitiesList()
  const [loading, setLoading] = useState(false)

  // Form-field data
  const [name, setName] = useState(entity.name || '')
  const [district, setDistrict] = useState(entity.district || '')
  const [county, setCounty] = useState(entity.county || '')
  const [zone, setZone] = useState(entity.zone || '')
  const [latitude, setLatitude] = useState(`${entity.latitude}` || '')
  const [longitude, setLongitude] = useState(`${entity.longitude}` || '')
  const [email, setEmail] = useState(entity.email || '')
  const [phone, setPhone] = useState(entity.phone || '')
  const [siteUrl, setSiteUrl] = useState(entity.site_url || '')
  const [catalogueUrl, setCatalogueUrl] = useState(entity.catalogue_url || '')
  const [schedule, setSchedule] = useState(entity.schedule || '')
  const [description, setDescription] = useState(entity.description || '')
  const [address, setAddress] = useState(entity.address || '')
  const [addressNumber, setAddressNumber] = useState(
    entity.address_number || '',
  )
  const [postalCode, setPostalCode] = useState(entity.postal_code || '')
  const [entityType, setEntityType] = useState<EntityType>(entity.entity_type)
  const [image, setImage] = useState<File>()
  const [social, setSocial] = useState<SocialMediaLinkData[]>(
    entity.social || [],
  )

  const handleClose = () => {
    // Don't allow the user to close the modal while it's saving details
    if (loading) return
    closeModal()
  }

  const handleSubmit = async () => {
    const entityData: EntityUpdateData = {
      ...entity,
      name,
      description: description || undefined,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      district,
      county,
      zone,
      email,
      phone,
      site_url: siteUrl,
      catalogue_url: catalogueUrl,
      schedule,
      address,
      address_number: addressNumber,
      postal_code: postalCode,
      entity_type: entityType,
      image: image || undefined,
      social,
    }

    const error =
      validateCoordinates(latitude, longitude) ||
      validateContactDetails(email, phone)

    const urlData = [
      { url: siteUrl, field: `common.site_url` },
      { url: catalogueUrl, field: `entity.catalogue_url` },
    ]
    const urlErrors = [
      ...validateURLs(urlData),
      ...validateSocialMediaURLs(social),
    ]

    const anyErrors = error || urlErrors.length
    if (anyErrors) {
      if (error) toast.error(error)
      urlErrors.forEach((msg) => toast.error(msg))
      return
    }

    setLoading(true)
    const success = await updateEntity(entityData)
    setLoading(false)

    if (success) {
      toast.success(t('entities.editEntity.updated'))
      closeModal()
    }
  }

  return (
    <Modal onClick={handleClose}>
      <Box width="medium">
        <Heading level="3">{t('entities.editEntity.title')}</Heading>

        <Form
          onCancel={handleClose}
          onSubmit={handleSubmit}
          submitButtonIcon={<Save size={16} />}
          submitButtonLabel={t('common.save')}
          disabled={loading}
        >
          <Input
            label="common.name"
            value={name}
            onChange={setName}
            required
            autoFocus
          />

          <TextArea
            label="common.description"
            value={description}
            onChange={setDescription}
          />

          <Dropdown<EntityType>
            label="entity.entity_type"
            value={ENTITY_TYPE_OPTIONS.find((e) => e.value === entityType)}
            options={ENTITY_TYPE_OPTIONS}
            onChange={setEntityType}
            required
          />

          <Input
            label="common.email"
            value={email}
            onChange={setEmail}
            type="email"
            trimValue
          />

          <Input label="common.phone" value={phone} onChange={setPhone} />

          <Input
            label="common.site_url"
            value={siteUrl}
            onChange={setSiteUrl}
            required
          />

          <Input
            label="entity.catalogue_url"
            value={catalogueUrl}
            onChange={setCatalogueUrl}
          />

          <Input
            label="common.address"
            value={address}
            onChange={setAddress}
            required
          />

          <Input
            label="common.address_number"
            value={addressNumber}
            onChange={setAddressNumber}
          />

          <Input
            label="common.postal_code"
            value={postalCode}
            onChange={setPostalCode}
            required
          />

          <LocationDropdowns
            zone={zone}
            district={district}
            county={county}
            onChange={(data) => {
              setZone(data.zone || '')
              setDistrict(data.district || '')
              setCounty(data.county || '')
            }}
          />

          <TextArea
            label="common.schedule"
            value={schedule}
            onChange={setSchedule}
          />

          <Input
            label="common.latitude"
            value={latitude}
            onChange={setLatitude}
            required
            trimValue
            type="tel"
          />

          <Input
            label="common.longitude"
            value={longitude}
            onChange={setLongitude}
            required
            trimValue
            type="tel"
          />

          <ImageFileInput
            file={image}
            onChange={setImage}
            help={
              entity.image && (
                <Text size="small">
                  {`${t('forms.fileInput.current')}: ${entity.image}`}
                </Text>
              )
            }
          />

          <SocialMediaFields linkData={social} onChange={setSocial} />
        </Form>
      </Box>
    </Modal>
  )
}

export default EditEntity
