import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Text } from 'grommet'
import _ from 'lodash'
import { useAuthorsList } from 'context/AuthorsContext'
import Table from 'components/Table'
import Link from 'components/Link'
import Modal from 'components/Modal'
import EditAuthor from './EditAuthor'

const AuthorsList = () => {
  const { t } = useTranslation()
  const { authors, deleteAuthor } = useAuthorsList()
  const [authorToEdit, setAuthorToEdit] = useState<Author>()
  const [authorToDelete, setAuthorToDelete] = useState<Author>()

  const handleDeleteClick = async () => {
    if (!authorToDelete) return

    const success = await deleteAuthor(authorToDelete)
    if (success) setAuthorToDelete(undefined)
  }

  return (
    <>
      <Table<Author>
        actions={[
          { action: 'edit', onClick: setAuthorToEdit },
          { action: 'delete', onClick: setAuthorToDelete },
        ]}
        columns={[
          {
            label: 'common.name',
            propKey: 'name',
          },
          {
            label: 'authors.table.description',
            propKey: 'description',
          },
          {
            label: 'authors.table.birth_date',
            propKey: 'birth_date',
          },
          {
            label: 'authors.table.death_date',
            propKey: 'death_date',
          },
          {
            label: 'authors.table.work',
            propKey: 'work1',
          },
          {
            label: 'authors.table.more_info',
            propKey: 'more_info',
          },
        ]}
        getRowKey={({ id }) => `${id}`}
        items={authors}
        itemRenderFunctions={{
          birth_date: ({ birth_date: date, birth_place: place }) => {
            if (!date) return '---'
            return (
              <>
                <Text size="small" weight={600}>
                  {date.split('-')[0]}
                </Text>
                <Text size="small">{place}</Text>
              </>
            )
          },
          death_date: ({ death_date: date, death_place: place }) => {
            if (!date) return '---'
            return (
              <>
                <Text size="small" weight={600}>
                  {date.split('-')[0]}
                </Text>
                <Text size="small">{place}</Text>
              </>
            )
          },
          description: ({ description }) => {
            return (
              <Text size="xsmall">
                {description ? _.truncate(description, { length: 50 }) : '---'}
              </Text>
            )
          },
          more_info: ({ more_info: url }) => {
            if (!url) return '---'
            return (
              <Link to={url}>
                <Text size="xsmall">{decodeURIComponent(url)}</Text>
              </Link>
            )
          },
          work1: ({ work1, work2, work3 }) => {
            const works = [work1, work2, work3].filter(Boolean)

            return works.map((work, index) => (
              <Text key={work} as="em" size="small">
                {`${index + 1}. ${work}`}
              </Text>
            ))
          },
        }}
        searchableColumns={['name']}
      />

      {authorToEdit && (
        <EditAuthor
          author={authorToEdit}
          closeModal={() => setAuthorToEdit(undefined)}
        />
      )}

      {authorToDelete && (
        <Modal onClick={() => setAuthorToDelete(undefined)}>
          <Box align="center" gap="1rem">
            <Text textAlign="center">{t('authors.delete.confirm')}</Text>
            <Text textAlign="center" size="small" weight={600}>
              {authorToDelete.name}
            </Text>

            <Button
              onClick={handleDeleteClick}
              color="status-error"
              label={t('common.delete')}
              primary
            />
          </Box>
        </Modal>
      )}
    </>
  )
}

export default AuthorsList
