import { isBefore } from 'date-fns'
import i18n from 'i18n'
import { DATETIME_REGEX, IMAGE_FILE_TYPES } from 'utils/constants'

/**
 * Checks that the latitude and longitude coordinates provided are
 * valid numbers. If not, returns an error
 */
export function validateCoordinates(lat: string, lng: string) {
  if (Number.isNaN(parseFloat(lat))) {
    return i18n.t('validation.common.latitude.NaN')
  }

  if (Number.isNaN(parseFloat(lng))) {
    return i18n.t('validation.common.longitude.NaN')
  }

  return null
}

/**
 * Checks that a date is valid by testing it with `DATETIME_REGEX`. An error
 * message can be specified; otherwise, it falls back to a generic "invalid date"
 * message
 */
export function validateDate(
  date: string,
  errorMsg = 'validation.common.date.invalidDate',
) {
  return DATETIME_REGEX.test(date) ? null : i18n.t(errorMsg)
}

/**
 * Checks that the start and end dates (e.g. for a festival or event) are valid.
 * If not, returns an error
 */
export function validateStartAndEndDate(start: string, end: string) {
  return (
    validateDate(start, 'validation.common.date.invalidStart') ||
    validateDate(end, 'validation.common.date.invalidEnd') ||
    (isBefore(new Date(end.split('T')[0]), new Date(start.split('T')[0]))
      ? i18n.t('validation.common.date.invalidOrder')
      : null)
  )
}

/**
 * Checks that an image file is the correct file type and large enough.
 * If not, returns an error
 */
export function validateImageFile(file: File) {
  if (!IMAGE_FILE_TYPES.includes(file.type)) {
    return i18n.t(`validation.common.image.wrongFileType`)
  }

  if (file.size < 10000) {
    return i18n.t(`validation.common.image.tooSmall`)
  }

  return null
}

/**
 * Checks that a url is valid. The translation key for the field should be passed
 * as the second argument so that it can be included in the error message if required
 */
export const validateURL = (url: string, field: string) => {
  const urlRegex = /^https?:\/\//

  return !url || urlRegex.test(url)
    ? null
    : i18n.t('validation.common.url.invalidURL', { field: i18n.t(field) })
}

/**
 * A shorthand function for validating several URLs at once
 */
export function validateURLs(urlData: { url: string; field: string }[]) {
  return urlData
    .map(({ url, field }) => validateURL(url, field))
    .filter(Boolean)
}

/**
 * A shorthand function for validating several social media URLs at once
 */
export function validateSocialMediaURLs(social: SocialMediaLinkData[]) {
  const socialURLData = social.map(({ name, url }) => ({
    url,
    field: `social.${name}`,
  }))
  return validateURLs(socialURLData)
}

/**
 * Makes sure that the user provides at least an email address or a phone number
 * when these options are available. If not, returns an error
 */
export function validateContactDetails(email?: string, phone?: string | null) {
  return email || phone
    ? null
    : i18n.t('validation.common.mustHaveEmailOrPhone')
}
