import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useHighlightsList } from 'context/HighlightsContext'
import HighlightForm from './HighlightForm'

interface EditHighlightProps {
  highlight: Highlight
  closeModal: () => void
}

const EditHighlight: React.FC<EditHighlightProps> = ({
  highlight,
  closeModal,
}) => {
  const { t } = useTranslation()
  const { entityId, updateHighlight } = useHighlightsList()

  const handleSubmit = async (highlightData: HighlightFormData) => {
    const success = await updateHighlight({ ...highlight, ...highlightData })

    if (success) {
      toast.success(t('highlights.editHighlight.updated'))
      closeModal()
    }
  }

  return (
    <HighlightForm
      entityId={entityId}
      highlight={highlight}
      onSubmit={handleSubmit}
      closeModal={closeModal}
    />
  )
}

export default EditHighlight
