import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Text } from 'grommet'
import { useHighlightsList } from 'context/HighlightsContext'
import Link from 'components/Link'
import Modal from 'components/Modal'
import Table from 'components/Table'
import EditHighlight from './EditHighlight'
import { toast } from 'react-toastify'

const HighlightsList = () => {
  const { t } = useTranslation()
  const { highlights, getHighlights, deleteHighlight } = useHighlightsList()
  const [highlightToEdit, setHighlightToEdit] = useState<Highlight>()
  const [highlightToDelete, setHighlightToDelete] = useState<Highlight>()

  useEffect(() => {
    getHighlights()
  }, [getHighlights])

  const handleDeleteClick = async () => {
    if (!highlightToDelete) return

    const success = await deleteHighlight(highlightToDelete)
    if (success) {
      toast.success(t('highlights.delete.success'))
      setHighlightToDelete(undefined)
    }
  }

  return (
    <>
      <Table<Highlight>
        actions={[
          { action: 'edit', onClick: setHighlightToEdit },
          { action: 'delete', onClick: setHighlightToDelete },
        ]}
        columns={[
          {
            label: 'common.name',
            propKey: 'name',
          },
          {
            label: 'common.url',
            propKey: 'url',
          },
        ]}
        getRowKey={({ id }) => `${id}`}
        items={highlights}
        itemRenderFunctions={{
          url: ({ url }) => {
            if (!url) return null

            return (
              <Link to={url}>
                <Text size="xsmall">{url}</Text>
              </Link>
            )
          },
        }}
        searchableColumns={['name']}
      />

      {highlightToEdit && (
        <EditHighlight
          highlight={highlightToEdit}
          closeModal={() => setHighlightToEdit(undefined)}
        />
      )}

      {highlightToDelete && (
        <Modal onClick={() => setHighlightToDelete(undefined)}>
          <Box align="center" gap="1rem">
            <Text textAlign="center">{t('highlights.delete.confirm')}</Text>
            <Text textAlign="center" size="small" weight={600}>
              {highlightToDelete.name}
            </Text>

            <Button
              onClick={handleDeleteClick}
              color="status-error"
              label={t('common.delete')}
              primary
            />
          </Box>
        </Modal>
      )}
    </>
  )
}

export default HighlightsList
