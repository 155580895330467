import { AxiosResponse } from 'axios'
import { getErrorMessage } from './getErrorMessage'
import { loadingMessage } from 'utils/helpers'
import { setAxiosToken } from 'config/axios'

interface ApiCallOptions {
  showLoadingMessage?: boolean
  noAuth?: boolean
}

/**
 * Wraps an API call with a try/catch to remove the need to write lots of
 * try/catches elsewhere. The function returns an object that contains
 * either `data` (the API response) or `error` (the error from the backend)
 */
function wrapApiCall<Args = void, Response = undefined>(
  apiCall: (args: Args) => Promise<AxiosResponse<Response>>,
  options: ApiCallOptions = {},
) {
  return async (args: Args) => {
    const result: {
      data?: Response
      error?: string
    } = {}

    const dismissMessage = options.showLoadingMessage
      ? loadingMessage()
      : undefined

    if (options.noAuth) {
      // Remove the authentication token before making the call
      setAxiosToken()
    }

    try {
      const response = await apiCall(args)
      result.data = response.data
    } catch (e) {
      result.error = getErrorMessage(e)
    }

    if (dismissMessage) dismissMessage()

    return result
  }
}

export default wrapApiCall
