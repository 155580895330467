import React from 'react'
import { Box, Layer } from 'grommet'

interface ModalProps {
  onClick: () => void
  children: React.ReactNode
}

const Modal = ({ onClick, children }: ModalProps) => {
  return (
    <Layer onEsc={onClick} onClickOutside={onClick} responsive={false}>
      <Box
        pad="medium"
        elevation="small"
        background="white"
        overflow="auto"
        round="xsmall"
      >
        {children}
      </Box>
    </Layer>
  )
}

export default Modal
