import React from 'react'
import { Tabs, Tab as GrTab, TabProps } from 'grommet'

// Typescript complains about using JSX as the title, even though it's valid,
// so handling it here once so it doesn't have to be done repeatedly
const Tab: React.FC<TabProps> = ({ title, ...rest }) => (
  <GrTab {...rest} title={title as any} />
)

export { Tabs, Tab }
