import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'
import { getEntityUrl } from './utils'

interface UpdateEntityImageArgs {
  id: number
  type: EntityType
  image: File
}

/**
 * Sends a new image for an entity to the backend. Since the image needs to be
 * sent as `FormData`, this was separated into its own API call
 */
function updateEntityImage<T = Entity>({
  id,
  type,
  image,
}: UpdateEntityImageArgs) {
  const url = `${getEntityUrl(type)}${id}/`

  const body = new FormData()
  body.set('image', image)

  return axios.patch<T>(url, body)
}

export default wrapApiCall(updateEntityImage)
