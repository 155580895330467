import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'
import convertToFormData from 'api/convertToFormData'

/**
 * Sends updated info data to the backend. Since the image needs to be
 * sent as `FormData`, the payload is converted to `FormData` first
 */
const updateInfoData = (info: InfoUpdateData) => {
  return axios.patch<Info>(`/info/1/`, convertToFormData(info))
}

export default wrapApiCall(updateInfoData)
