import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from '@reach/router'
import { toast } from 'react-toastify'
import { Save } from 'react-feather'
import { Box, Heading, Text } from 'grommet'
import {
  validateCoordinates,
  validateURLs,
  validateContactDetails,
  validateSocialMediaURLs,
} from 'utils/validation/common'
import { validateEmail } from 'utils/validation/users'
import { useEntitiesList } from 'context/EntitiesContext'
import Form from 'components/Form'
import Input from 'components/Input'
import TextArea from 'components/TextArea'
import LocationDropdowns from 'components/LocationDropdowns'
import SocialMediaFields from 'components/SocialMediaFields'
import ImageFileInput from 'components/FileInput/ImageFileInput'

const MyEntity: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation()
  const { myEntity, updateEntity } = useEntitiesList()

  const [loading, setLoading] = useState(false)
  const [entity, setEntity] = useState({ ...(myEntity as Entity) })
  const [image, setImage] = useState<File>()

  useEffect(() => {
    if (!myEntity) return
    setEntity(myEntity)
  }, [myEntity])

  const handleChange = useCallback((change: Partial<Entity>) => {
    setEntity((e) => ({ ...e, ...change }))
  }, [])

  if (!entity) return null
  if (!myEntity) return null

  const handleSubmit = async () => {
    const { latitude, longitude } = entity
    const error =
      validateCoordinates(`${latitude}`, `${longitude}`) ||
      validateEmail(entity.email) ||
      validateContactDetails(entity.email, entity.phone)

    const urlData = [
      { url: entity.site_url, field: `common.site_url` },
      { url: entity.catalogue_url, field: `entity.catalogue_url` },
    ]
    const urlErrors = [
      ...validateURLs(urlData),
      ...validateSocialMediaURLs(entity.social),
    ]

    const anyErrors = error || urlErrors.length
    if (anyErrors) {
      if (error) toast.error(error)
      urlErrors.forEach((msg) => toast.error(msg))
      return
    }

    const entityData: EntityUpdateData = {
      ...myEntity,
      ...entity,
      social: entity.social || [],
      image: image || undefined,
    }

    setLoading(true)
    const success = await updateEntity(entityData)
    setLoading(false)

    if (success) {
      toast.success(t('entities.myEntity.updated'))
    }
  }

  return (
    <>
      <Box
        elevation="small"
        width="medium"
        margin="2rem auto"
        pad="medium"
        round="xsmall"
        background="white"
      >
        <Box>
          <Heading level="3">{myEntity.name}</Heading>
        </Box>
        <Form
          onSubmit={handleSubmit}
          submitButtonIcon={<Save size={16} />}
          submitButtonLabel={t('common.save')}
          disabled={loading}
        >
          <Input
            label="common.name"
            value={entity.name}
            onChange={(value) => handleChange({ name: value })}
            required
            autoFocus
          />

          <TextArea
            label="common.description"
            value={entity.description || ''}
            onChange={(value) => handleChange({ description: value })}
          />

          <Input
            label="common.email"
            value={entity.email}
            onChange={(value) => handleChange({ email: value })}
          />

          <Input
            label="common.phone"
            value={entity.phone}
            onChange={(value) => handleChange({ phone: value })}
          />

          <Input
            label="common.site_url"
            value={entity.site_url}
            onChange={(value) => handleChange({ site_url: value })}
            required
          />

          <Input
            label="entity.catalogue_url"
            value={entity.catalogue_url}
            onChange={(value) => handleChange({ catalogue_url: value })}
          />

          <Input
            label="common.address"
            value={entity.address}
            onChange={(value) => handleChange({ address: value })}
            required
          />

          <Input
            label="common.address_number"
            value={entity.address_number}
            onChange={(value) => handleChange({ address_number: value })}
            required
          />

          <Input
            label="common.postal_code"
            value={entity.postal_code}
            onChange={(value) => handleChange({ postal_code: value })}
            required
          />

          <LocationDropdowns
            zone={entity.zone}
            district={entity.district}
            county={entity.county}
            onChange={handleChange}
          />

          <TextArea
            label="common.schedule"
            value={entity.schedule}
            onChange={(value) => handleChange({ schedule: value })}
            required
          />

          <Input
            label="common.latitude"
            value={entity.latitude}
            onChange={(value) =>
              handleChange({
                latitude: parseFloat(value.replace(/[^0-9.-]/g, '')),
              })
            }
            required
            type="tel"
          />

          <Input
            label="common.longitude"
            value={entity.longitude}
            onChange={(value) =>
              handleChange({
                longitude: parseFloat(value.replace(/[^0-9.-]/g, '')),
              })
            }
            required
            type="tel"
          />

          <ImageFileInput
            file={image}
            onChange={setImage}
            help={
              myEntity &&
              myEntity.image && (
                <Text size="small">
                  {`${t('forms.fileInput.current')}: ${myEntity.image}`}
                </Text>
              )
            }
          />

          <SocialMediaFields
            linkData={entity.social || []}
            onChange={(social) => handleChange({ social })}
          />
        </Form>
      </Box>
    </>
  )
}

export default MyEntity
