import React, { useCallback, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import getInfosList from 'api/info/getInfoList'
import updateInfoData from 'api/info/updateInfo'

interface InfoContextValue {
  infos: Info[]
  getInfos: () => void
  updateInfo: (_: InfoUpdateData) => Promise<boolean>
}

const InfoContext = React.createContext<InfoContextValue>({
  infos: [],
  getInfos: async () => {},
  updateInfo: async (_: InfoUpdateData) => false,
})

export const InfoProvider: React.FC = ({ children }) => {
  const [infos, setInfo] = useState<Info[]>([])

  const getInfos = useCallback(async () => {
    const { data, error } = await getInfosList()

    if (data) {
      setInfo([data])
      return
    }

    if (error) toast.error(error)
  }, [])

  const updateInfo = async (a: InfoUpdateData) => {
    const { data } = await updateInfoData(a)

    if (data) {
      setInfo([data])
    }

    return true
  }

  useEffect(() => {
    getInfos()
  }, [getInfos])

  return (
    <InfoContext.Provider value={{ getInfos, updateInfo, infos }}>
      {children}
    </InfoContext.Provider>
  )
}

export const useInfoList = () => useContext(InfoContext)

export default InfoContext
