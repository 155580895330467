import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

interface UpdateHighlightArgs {
  highlight: HighlightUpdateData
  entityId: number
}

/**
 * Sends updated highlight data to the backend to be saved in the database.
 * Returns a fresh copy of the highlight
 */
const updateHighlight = async ({
  entityId,
  highlight,
}: UpdateHighlightArgs) => {
  const { image, id, ...data } = highlight
  const url = `/entities/${entityId}/highlights/${id}/`

  if (image && typeof image !== 'string') {
    const body = new FormData()
    body.set('image', image)

    await axios.patch(url, body)
  }

  return axios.patch<Highlight>(url, data)
}

export default wrapApiCall(updateHighlight)
