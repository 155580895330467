import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, Box } from 'grommet'

interface FormProps {
  onSubmit: () => void
  submitButtonIcon?: JSX.Element
  submitButtonLabel?: string
  onCancel?: () => void
  cancelButtonLabel?: string
  disabled?: boolean
}

const CustomForm: React.FC<FormProps> = ({
  children,
  onSubmit,
  onCancel,
  submitButtonIcon,
  submitButtonLabel,
  cancelButtonLabel,
  disabled,
}) => {
  const { t } = useTranslation()
  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <fieldset disabled={disabled}>{children}</fieldset>

      <Box
        justify="between"
        direction="row"
        wrap="reverse"
        alignContent="end"
        pad={{ vertical: 'small' }}
      >
        {onCancel && (
          <Button
            margin={{ bottom: 'small' }}
            onClick={onCancel}
            label={cancelButtonLabel || t('common.cancel')}
            disabled={disabled}
          />
        )}
        <Button
          icon={submitButtonIcon}
          label={submitButtonLabel || t('common.submit')}
          margin={{ bottom: 'small' }}
          fill={!onCancel}
          disabled={disabled}
          type="submit"
          primary
        />
      </Box>
    </Form>
  )
}

export default CustomForm
