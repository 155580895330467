import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'
import convertToFormData from 'api/convertToFormData'

interface NewEventArgs {
  event: NewLiteraryEventData
  entityId: number
}

/**
 * Sends the new event data to the backend and returns the created event
 */
const createLiteraryEvent = ({ event, entityId }: NewEventArgs) => {
  const url = `/entities/${entityId}/events/`
  return axios.post<LiteraryEvent>(url, convertToFormData(event))
}

export default wrapApiCall(createLiteraryEvent)
