import React, { useCallback, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import getLiteraryEventsList from 'api/literaryEvents/getLiteraryEventsList'
import createLiteraryEvent from 'api/literaryEvents/createLiteraryEvent'
import deleteLiteraryEventById from 'api/literaryEvents/deleteLiteraryEventById'
import updateLiteraryEventData from 'api/literaryEvents/updateLiteraryEvent'

interface LiteraryEventsContextValue {
  literaryEvents: LiteraryEvent[]
  getLiteraryEvents: () => void
  addLiteraryEvent: (literaryEvent: NewLiteraryEventData) => Promise<boolean>
  updateLiteraryEvent: (
    literaryEvent: LiteraryEventUpdateData,
  ) => Promise<boolean>
  deleteLiteraryEvent: (literaryEvent: LiteraryEvent) => Promise<boolean>
  literaryEventsOptions: DropdownOption<LiteraryEvent>[]
}

const LiteraryEventsContext = React.createContext<LiteraryEventsContextValue>({
  literaryEvents: [],
  getLiteraryEvents: () => {},
  addLiteraryEvent: async (_: NewLiteraryEventData) => false,
  updateLiteraryEvent: async (_: LiteraryEventUpdateData) => false,
  deleteLiteraryEvent: async (_: LiteraryEvent) => false,
  literaryEventsOptions: [],
})

type ProviderComponent = React.FC<{ entityId: number }>

export const LiteraryEventsProvider: ProviderComponent = ({
  children,
  entityId,
}) => {
  const [literaryEvents, setLiteraryEvents] = useState<LiteraryEvent[]>([])
  const [literaryEventsOptions, setLiteraryEventsOptions] = useState<
    DropdownOption<LiteraryEvent>[]
  >([])

  const setAllLiteraryEvents = (literaryEventList: LiteraryEvent[]) => {
    const options = literaryEventList.map((literaryEvent) => ({
      label: literaryEvent.name,
      value: literaryEvent,
    }))
    setLiteraryEvents(literaryEventList)
    setLiteraryEventsOptions(options)
  }

  const getLiteraryEvents = useCallback(async () => {
    const { data, error } = await getLiteraryEventsList(entityId)

    if (data) {
      setAllLiteraryEvents(data.results)
      return
    }

    if (error) toast.error(error)
  }, [entityId])

  const updateLiteraryEvent = async (event: LiteraryEventUpdateData) => {
    const { data: success, error } = await updateLiteraryEventData({
      event,
      entityId,
    })

    if (error) toast.error(error)
    if (success) {
      const newList = literaryEvents.map((literaryEvent) =>
        literaryEvent.id === success.id ? success : literaryEvent,
      )
      setAllLiteraryEvents(newList)
    }

    return !!success && !error
  }

  const addLiteraryEvent = async (event: NewLiteraryEventData) => {
    const { error, data } = await createLiteraryEvent({ event, entityId })

    if (data) {
      const newList = [data, ...literaryEvents]
      setAllLiteraryEvents(newList)
    }

    if (error) toast.error(error)
    return !!data && !error
  }

  const deleteLiteraryEvent = async ({ id }: LiteraryEvent) => {
    const { error } = await deleteLiteraryEventById({ entityId, eventId: id })

    if (!error) {
      const newList = literaryEvents.filter((event) => event.id !== id)
      setAllLiteraryEvents(newList)
    } else {
      toast.error(error)
    }

    return !error
  }

  return (
    <LiteraryEventsContext.Provider
      value={{
        addLiteraryEvent,
        literaryEvents,
        getLiteraryEvents,
        deleteLiteraryEvent,
        updateLiteraryEvent,
        literaryEventsOptions,
      }}
    >
      {children}
    </LiteraryEventsContext.Provider>
  )
}

export const useLiteraryEventsList = () => useContext(LiteraryEventsContext)

export default LiteraryEventsContext
