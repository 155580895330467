import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

/**
 * Calls the backend to delete an author based on ID
 */
const deleteAuthorById = (id: number) => {
  return axios.delete<undefined>(`/authors/${id}/`)
}

export default wrapApiCall(deleteAuthorById)
