export const getEntityUrl = (type: EntityType) => {
  switch (type) {
    case 'literary_house':
      return '/literary_houses/'

    case 'bookstore':
      return '/bookstores/'

    case 'library':
      return '/libraries/'

    case 'festival':
      return '/festivals/'

    case 'author':
      return '/authors/'

    default:
      return '/entities/'
  }
}
