import styled from 'styled-components'
import LinkComp from 'components/Link'

export const Link = styled(LinkComp)`
  display: flex;
  align-items: center;

  color: inherit !important;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  margin-bottom: 1rem;
`
