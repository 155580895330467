import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

/**
 * Calls the backend to get a list of highlights or one specific entity's
 * highlights, based on whether an entity ID is provided
 */
const getHighlightsList = (entityId?: number) => {
  let url = `/highlights/`
  if (entityId) url = `/entities/${entityId}${url}`

  return axios.get<ApiListResponse<Highlight>>(url)
}

export default wrapApiCall(getHighlightsList)
