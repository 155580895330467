import axios from 'axios'
import wrapApiCall from 'api/wrapApiCall'

interface DeleteUserArgs {
  user: User
  reason?: string
}

/**
 * Calls the backend to delete a user based on the user's ID.
 * Can also send a reason to explain why
 */
const deleteUserById = ({ user, reason }: DeleteUserArgs) => {
  return axios.delete<undefined>(`/users/${user.id}/`, { params: { reason } })
}

export default wrapApiCall(deleteUserById)
